import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";

import POBDisplayComponent from "./POBDisplay.component";

type Props = {
    siteId: string
}
const POBDisplay: FC<Props> = ({ siteId }) => {

  const [isPeopleListOpen, setPeopleListOpen] = useState(false);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);

  const { geoJson, sitePOBDetails } = useSelector((state: RootState) => state.site);

  const {
    site: {
      getPOBDetails,
      CLEAR_ADAPTER_DETAILS
    }
  } = useDispatch<Dispatch>();

  const handlePeopleModalClose = useCallback(() => {
    setPeopleListOpen(false);
  }, []);

  const handlePeopleModalOpen= useCallback(() => {
    if (sitePOBDetails && sitePOBDetails?.totalPob > 0){
      setPeopleListOpen(true);
    }
  }, [sitePOBDetails]);

  useEffect(()=> {
    const executePOBDetails = async () => {
      await getPOBDetails({ category: 'SITE', siteId });
    };

    const startPOBPolling = () => {
      executePOBDetails();
      intervalRef.current = setInterval(executePOBDetails, 10000);
    };

    const stopPOBPolling = () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);  
      }
    };

    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          startPOBPolling();  
        } else {
          stopPOBPolling();  
        } 
      }); 
    };

    observerRef.current = new IntersectionObserver(observerCallback, {
      threshold: 0.1  });
    
    if (elementRef.current) {
      observerRef.current.observe(elementRef.current); 
    }

    return () => {
      stopPOBPolling();
      CLEAR_ADAPTER_DETAILS();
      if (observerRef.current && elementRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observerRef.current.unobserve(elementRef.current);
      }
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoJson]);

  return (
    <POBDisplayComponent 
      sitePOBDetails={ sitePOBDetails } 
      elementRef={ elementRef } 
      isPeopleListOpen={ isPeopleListOpen }
      onPeopleModalClose={ handlePeopleModalClose }
      onPeopleModalOpen={ handlePeopleModalOpen }
    />
  );
};

export default POBDisplay;
