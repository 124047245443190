// ! DO NOT WRITE COMPONENTS LIKE THIS
// ! THIS IS JUST DEVTOOLS
// ! KEEPING RENDER LOGIC IN CONTAINER IS BAD PRACTICE

import { Button, Flex, Input } from "@mantine/core";
import React, { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { REGULAR_TOKEN_KEY, TENANT_TOKEN_KEY } from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import { Dispatch, RootState } from "@/core/store";
import { useAuthContext } from "@/tenant-context/core/context/Auth.context";

const DeveloperToolsContainer: FC = () => {
  const {
    bigMap: {
      setMapUrl
    }
  } = useDispatch<Dispatch>();

  const {
    setTenantToken: setTenantTokenContext,
    setAccessToken: setAccessTokenContext
  } = useAuthContext();

  const mapUrl = useSelector((state: RootState) => state.bigMap.mapStyleUrl);
  const [mapUrlInputValue, setMapUrlInputValue] = useState<string>(mapUrl);

  // TODO remove in prod
  const [tenantToken, setTenantToken] = useState<string>(browserDatabase.getItem(TENANT_TOKEN_KEY) || '');
  const [regularToken, setRegularToken] = useState<string>(browserDatabase.getItem(REGULAR_TOKEN_KEY) || '');

  const handleMapUrlInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = ev;

    setMapUrlInputValue(value);
  }, []);

  const handleTenantTokenInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = ev;

    setTenantTokenContext(value);
    setTenantToken(value);
  }, [ setTenantTokenContext ]);

  const handleRegularTokenInput = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { value } } = ev;

    setAccessTokenContext(value);
    setRegularToken(value);
  }, [ setAccessTokenContext ]);

  const handleTenantTokenSubmit = useCallback(() => {
    browserDatabase.setItem(TENANT_TOKEN_KEY, tenantToken);

    window.location.reload();
  }, [tenantToken]);

  const handleRegularTokenSubmit = useCallback(() => {
    browserDatabase.setItem(REGULAR_TOKEN_KEY, regularToken);

    window.location.reload();
  }, [regularToken]);

  const handleMapUrlSubmit = useCallback(() => {
    setMapUrl(mapUrlInputValue);
  }, [mapUrlInputValue, setMapUrl]);

  return (
    <Flex direction={ 'column' } gap={ 'sm' }>
      <Input.Wrapper
        label="Map URL"
        required
      >
        <Flex gap={ 'sm' }>
          <Input
            type="text"
            defaultValue={ mapUrl }
            onInput={ handleMapUrlInput }
            style={ { flexGrow: 1 } }
          />

          <Button
            onClick={ handleMapUrlSubmit }
          >
            Submit
          </Button>
        </Flex>
      </Input.Wrapper>

      <Input.Wrapper
        label="Tenant Token"
        required
      >
        <Flex gap={ 'sm' }>
          <Input
            type="text"
            defaultValue={ tenantToken }
            onInput={ handleTenantTokenInput }
            style={ { flexGrow: 1 } }
          />

          <Button
            onClick={ handleTenantTokenSubmit }
          >
            Submit
          </Button>
        </Flex>
      </Input.Wrapper>

      <Input.Wrapper
        label="Regular Token"
        required
      >
        <Flex gap={ 'sm' }>
          <Input
            type="text"
            defaultValue={ regularToken }
            onInput={ handleRegularTokenInput }
            style={ { flexGrow: 1 } }
          />

          <Button
            onClick={ handleRegularTokenSubmit }
          >
            Submit
          </Button>
        </Flex>
      </Input.Wrapper>
    </Flex>
  );
};

export default DeveloperToolsContainer;
