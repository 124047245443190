/* eslint-disable import/extensions */
import { INDOORS_VISIBILITY_MIN_ZOOM } from "@f/map-gl-indoor/IndoorLayer";
import { bbox } from "@turf/turf";
import { Point } from "geojson";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { Dispatch, RootState } from "@/core/store";
import { WORLD_MAX_BOUNDS } from "@/tenant-context/common/util/constants";
import { SITE_INFO_CONTROL_DRAWER_ID } from "@/tenant-context/control-site/controls/SiteInfoControl/SiteInfoControl.component";
import { Address } from "@/tenant-context/visualisation-site/types/site.types";
import {
  ALL_COUNTRIES,
  ALL_COUNTRIES_ISO_CODE
} from "@/tenant-context/widget-overview/components/CountryInfoBox/CountryInfoBox.container";
import { getMusterLocations } from "@/tenant-context/widget-overview/util/locations";

import { WidgetSite } from "../../types/site.types";
import SiteInfoBoxComponent from "./SiteInfoBox.component";

export const ALL_SITES_CODE = 'all';

const SiteInfoBox: FC = () => {
  const { current: map } = useMap();

  const [siteSearchValue, setSiteSearchValue] = useState('');

  const siteFeatureCollection = useSelector(
    (state: RootState) => state.siteLocations.geoData
  );
  const selectedCountry = useSelector(
    (state: RootState) => state.commonData.selectedCountry
  );

  const selectedSite = useSelector(
    (state: RootState) => state.commonData.selectedSite
  );

  const shouldRefreshSitesFlag = useSelector(
    (state: RootState) => state.commonData?.shouldRefreshSitesFlag
  );

  const locationsPeopleCount = useSelector((state: RootState) => state.sitePopup.locationsPeopleCount);
  const allCountries = useSelector((state: RootState) => state.commonData.allCountries);

  const siteGeoJson = useSelector((state: RootState) => state.site?.geoJson);
  const {
    site: {
      setSiteGeoJson
    }
  } = useDispatch<Dispatch>();

  const {
    commonData: {
      SET_SELECTED_SITE,
      SET_SELECTED_COUNTRY,
      SET_SHOULD_REFRESH_SITES_FLAG
    },
    userProfile: {
      getSiteContactProfileData
    },
    drawer: {
      openRightDrawer,
      closeRightDrawer
    }
  } = useDispatch<Dispatch>();

  const [params] = useSearchParams(); 
  const navigatedSite = (params.get('site') || '') as string;

  const siteList: Array<WidgetSite> = useMemo(
    () => {
      const musterLocations = getMusterLocations(locationsPeopleCount);

      return siteFeatureCollection.features
        .filter((site) => {
          if (selectedCountry && selectedCountry.id !== ALL_COUNTRIES) {
            return (site.properties.address as Address)?.country === selectedCountry?.isoCountryCode;
          }

          return site;

        }).filter(site => site.properties.criticalLocation === true)
        .map(
          (site) => {
            const { properties, geometry } = site;
            const { coordinates } = geometry as Point;
            return {
              name: properties?.name,
              location: properties?.countryName,
              lngLat: [coordinates[0], coordinates[1]],
              id: properties?.id,
              code: properties?.code,
              musterCount: properties?.code ? musterLocations[properties?.code] : 0,
              countryName: properties?.countryName,
              address: properties?.address,
              description: properties?.description,
              siteContactId: properties?.siteContactId,
              isoCountryCode: properties?.isoCountryCode,
              imageUrl: properties?.imageUrl,
              locationType: properties?.locationType,
              subCategory: properties?.subCategory
            };
          }
        );
    },
    [selectedCountry, siteFeatureCollection, locationsPeopleCount]
  );

  const allSitesOptionData: WidgetSite = useMemo(() => {
    return {
      name: `All (${(siteList?.length ?? 0).toString()})`,
      location: '',
      lngLat: [0, 0],
      id: 'all',
      code: ALL_SITES_CODE,
      musterCount: locationsPeopleCount?.musters,
      isoCountryCode: ALL_SITES_CODE

    };
  }, [siteList, locationsPeopleCount]);

  const mappedSiteLists: Array<WidgetSite> = useMemo(() => {
    return [
      allSitesOptionData,
      ...siteList
    ];
  }, [siteList, allSitesOptionData]);

  const filteredSites = useMemo(
    () => mappedSiteLists.filter((site) => site.name.toLowerCase().includes(siteSearchValue.toLowerCase()))
    , [mappedSiteLists, siteSearchValue]
  );

  const [isExpanded, setIsExpanded] = useState(false);

  const onSiteSelected = useCallback((clickedOnSite: WidgetSite) => {
    closeRightDrawer();
    // ToDo - For now this is zooming into the given point, should change to a geo boundary when data available
    const { lngLat, id, code } = clickedOnSite;
    if (id) {
      setSiteGeoJson(id);
    }

    if (selectedCountry && code === ALL_SITES_CODE) {

      const { geoPoint: { lon, lat } } = selectedCountry;
      map?.flyTo({
        center: {
          lng: lon,
          lat
        },
        zoom: selectedCountry.id === ALL_COUNTRIES ? 0 : 5
      });
    }
    if ((!selectedCountry || selectedCountry?.name === ALL_COUNTRIES) && code === ALL_SITES_CODE) {
      map?.fitBounds(WORLD_MAX_BOUNDS);
    }
    if (selectedCountry && code !== ALL_SITES_CODE) {
      if (map && map?.getZoom() > 14) {
        openRightDrawer(SITE_INFO_CONTROL_DRAWER_ID);
      }
      if (siteGeoJson) {
        const bbox_ = bbox(siteGeoJson) as [number, number, number, number];
        map?.fitBounds(bbox_, { padding: 200 });
      } else {
        map?.flyTo({
          center: lngLat,
          zoom: INDOORS_VISIBILITY_MIN_ZOOM
        });
        openRightDrawer(SITE_INFO_CONTROL_DRAWER_ID);
      }
    } else {
      return;
    }
  }, [closeRightDrawer, map, openRightDrawer, selectedCountry, setSiteGeoJson, siteGeoJson]);

  const handleSiteChange = useCallback(async (site: WidgetSite) => {
    if (
      site && site.code !== ALL_SITES_CODE
      &&
      selectedCountry && selectedCountry.isoCountryCode === ALL_COUNTRIES_ISO_CODE
    ) {
      const countryOfTheSite = allCountries.find((country) => country.isoCountryCode === site.isoCountryCode);
      if (countryOfTheSite) {
        await SET_SELECTED_COUNTRY(countryOfTheSite);
      }
    }
    setIsExpanded(false);
    onSiteSelected(site);
    SET_SELECTED_SITE(site);
  }, [onSiteSelected, SET_SELECTED_SITE, SET_SELECTED_COUNTRY, selectedCountry, allCountries]);

  const handleInfoBoxClick = useCallback(
    () => {
      setIsExpanded(!isExpanded);
    },
    [isExpanded]
  );

  // Reset selected site if siteList is empty
  useMemo(() => {
    if (mappedSiteLists.length === 0) {
      SET_SELECTED_SITE(allSitesOptionData);
    }
  }, [mappedSiteLists, SET_SELECTED_SITE, allSitesOptionData]);

  useMemo(() => {
    if (selectedCountry && selectedCountry.id !== ALL_COUNTRIES) {
      const { geoPoint: { lon, lat } } = selectedCountry;
      const site: WidgetSite = {
        name: 'All',
        location: '',
        lngLat: [lon, lat]
      };

      SET_SELECTED_SITE(site);
    } else {
      SET_SELECTED_SITE(allSitesOptionData);
      SET_SELECTED_SITE(allSitesOptionData);
    }

  }, [SET_SELECTED_SITE, allSitesOptionData, selectedCountry]);

  const musterCount = useMemo(() => {
    if (
      selectedCountry && selectedCountry.id !== ALL_COUNTRIES
      && selectedSite && selectedSite.code === ALL_SITES_CODE
    ) {
      return siteList.reduce((acc, site) => site?.musterCount ? acc + site?.musterCount : acc, 0);
    } else {
      return selectedSite?.musterCount ?? 0;
    }

  }, [selectedCountry, selectedSite, siteList]);

  useEffect(() => {
    if (selectedSite?.siteContactId) {
      getSiteContactProfileData(selectedSite?.siteContactId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite]);

  useEffect(() => {
    SET_SELECTED_SITE(allSitesOptionData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSitesOptionData]);

  useEffect(() => {
    if (!shouldRefreshSitesFlag) {
      return;
    }

    if (selectedCountry?.id === ALL_COUNTRIES) {
      SET_SELECTED_SITE(allSitesOptionData);
    }
    SET_SHOULD_REFRESH_SITES_FLAG(false);
  }, [SET_SELECTED_SITE, SET_SHOULD_REFRESH_SITES_FLAG, allSitesOptionData, selectedCountry, shouldRefreshSitesFlag]);


  //used to handle site selection from site Id in URL
  useEffect(()=>{
    if(siteList.length > 0 && navigatedSite){
      const siteObj = siteList.find(site => site.id === navigatedSite);
      if(siteObj){
        handleSiteChange(siteObj);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteList, navigatedSite]);

  return (
    <SiteInfoBoxComponent
      selectedSite={ selectedSite }
      siteList={ filteredSites }
      isExpanded={ isExpanded }
      oSiteSelected={ handleSiteChange }
      onInfoBoxClicked={ handleInfoBoxClick }
      musterCount={ musterCount }
      setSiteSearchValue={ setSiteSearchValue }
    />
  );

};

export default SiteInfoBox;
