import { Box, Flex, Rating, Stack, Text } from '@mantine/core';
import { FC, ReactNode } from 'react';

import {
  useDetailedCountryReportStyles
} from '@/tenant-context/control-reports/components/DetailedCountryReport/DetailedCountryReport.styles';

type Props = {
  title: string;
  reportData?: {
    [key: string]: {
      title: string;
      text: string;
      level?: string;
    };
  };
  getSubsectionIcon: (key: string) => ReactNode;
  isLevelShownAsRating?: boolean;
}

const ReportSectionComponent: FC<Props> = ({
  title,
  reportData,
  getSubsectionIcon,
  isLevelShownAsRating
}) => {
  const { classes } = useDetailedCountryReportStyles({});

  if (!reportData) {
    return null;
  }

  return (
    <Stack spacing="md">
      <Text className={ classes.reportSectionHeading }>{ title }</Text>
      <Flex gap="lg" wrap="wrap">
        { Object.keys(reportData).map((key, index) => (
          <Stack key={ index } spacing="md">
            <Flex align="center" gap={ 8 }>
              { getSubsectionIcon(key) && <Box className={ classes.titleIcon }>{ getSubsectionIcon(key) }</Box> }
              <Text className={ classes.subSectionHeading }>{ reportData[key].title }</Text>
              { isLevelShownAsRating && reportData[key].level && <Rating
                className={ '' }
                readOnly
                defaultValue={ reportData[key].level === 'POOR' ? 1 :
                  reportData[key].level === 'MEDIOCRE' ? 2 :
                    reportData[key].level === 'GOOD' ? 3 : 0 } /> }
            </Flex>
            <div
              className={ classes.content }
              dangerouslySetInnerHTML={ { __html: reportData[key].text } }
            />
          </Stack>
        )) }
      </Flex>
    </Stack>
  );
};

export default ReportSectionComponent;
