import { Divider, Title } from "@mantine/core";
import { FC } from "react";

import { ReactComponent as NextIcon } from "@/common/icons/chevron-right.svg";
import { ReactComponent as NoSortIcon } from "@/common/icons/circle-sort.svg";
import { ReactComponent as SortAcendingIcon } from "@/common/icons/circle-sort-ascending.svg";
import { ReactComponent as SortDesendingIcon } from "@/common/icons/circle-sort-descending.svg";
import {
  useBuildingsAndFloorsStyles
} from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/BuildingsAndFloorsTab.styles";
import AddFloorForm
  from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/components/AddFloorForm";
import BuildingInfo
  from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/components/BuildingInfo";
import FloorCell
  from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/components/FloorCell";
import {
  Building, FloorListItem
} from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

type Props = {
  handleGoBackButtonClick: () => void
  floors: FloorListItem[]
  isAddFloorFormOpened: boolean
  activeBuilding?: Building
  handleSetFloorSort: () => void
  activeFloorSort: 'asc' | 'desc' | ''
}

const renderSortIcon = (sort: 'asc' | 'desc' | '') => {
  switch(sort) {
  case 'asc':
    return <SortAcendingIcon />;
  case 'desc':
    return <SortDesendingIcon />;
  default:
    return <NoSortIcon />;

  }
};
const FloorListTableComponent: FC<Props> = ({
  handleGoBackButtonClick,
  floors,
  isAddFloorFormOpened,
  activeBuilding,
  handleSetFloorSort,
  activeFloorSort
}) => {
  const { classes } = useBuildingsAndFloorsStyles();
  return (
    <div className={ classes.floorWrapper }>
      <div className={ classes.backButtonContainer }>
        <button type={ 'button' } onClick={ handleGoBackButtonClick } className={ classes.goBackButton }>
          Buildings
        </button>
        <NextIcon/>
        <div className={ classes.goBackButtonText }>{ activeBuilding?.name }</div>
      </div>
      <BuildingInfo activeBuilding={ activeBuilding }/>
      <Divider className={ classes.divider }/>
      { isAddFloorFormOpened && <AddFloorForm/> }
      <Title size={ 'h4' }>Floors</Title>
      <div className={ classes.floorTitleContainer }>
        <div className={ classes.floorTitle }>
          FLOOR NAME
          <button onClick={ handleSetFloorSort } className={ classes.sortIconContainer }>
            { renderSortIcon(activeFloorSort) }
          </button>
        </div>
        <div className={ classes.floorTitle }>FLOOR CODE</div>
        <div className={ classes.floorTitle }>NO OF ZONES</div>
        <div className={ classes.floorTitle }>ACTION</div>
      </div>
      { floors.map((floor) => <FloorCell key={ floor.floorTid } floor={ floor }/>) }
    </div>
  );
};

export default FloorListTableComponent;
