export function getRiskLevelValueByRiskLevel(riskLevel?: string) {
  switch (riskLevel?.toLowerCase()) {
  case 'low':
    return 25;
  case 'medium':
    return 50;
  case 'high':
    return 75;
  case 'extreme':
    return 100;
  default:
    return 0;
  }
}

export function getRiskColorByRiskLevel(riskLevel?: string) {
  switch (riskLevel?.toLowerCase()) {
  case 'low':
    return '#24CA45' as string;
  case 'medium':
    return '#E79A01' as string;
  case 'high':
    return '#FF6300' as string;
  case 'extreme':
    return '#FF0004' as string;
  default:
    return 'gray' as string;
  }
}

export function kebabCaseToTitleCase(kebabCase?: string) {
  return kebabCase?.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function snakeCaseToTitleCase(snakeCase?: string) {
  return snakeCase?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

export function uppercaseKebabToCapitalizedWords(input: string): string {
  return input
    .toLowerCase()
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
