import { FC, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import GlobalErrorBoundary from '@/common/components/GlobalErrorBoundary';
import { RouterConfig } from '@/core/components/Router/Router.config';
import LoaderScreen from '@/core/components/SSOIntegration/LoaderScreen';
import RouterRoutes from '@/tenant-context/core/components/RouterRoutes/RouterRoutes.component';
import NavigationSidebar from '@/tenant-context/navigation/components/NavigationSidebar';

import '@/core/components/Router/Router.scss';


const Router: FC = () => {
  
  const isPublicURL = RouterConfig.publicURLs().includes(window.location.pathname);

  return (
    <BrowserRouter>
      <div className="Router-NavigationWrapper">
        { !isPublicURL &&  <div className='Router-Nav'>
          <NavigationSidebar />
        </div> }
        <div style={ { marginLeft: isPublicURL ? 0 : 65 } } className="Router-PageWrapper">
          <GlobalErrorBoundary>
            <Suspense fallback={ <LoaderScreen isLoading={ true } /> }>
              <RouterRoutes />
            </Suspense>
          </GlobalErrorBoundary>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default Router;
