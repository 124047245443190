import { DefaultProps } from "@mantine/core";
import { FC, useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import { BigMapContext } from "@/tenant-context/core/context/BigMap.context";
import {
  MAP_STYLES_URLS,
  MapStyle
} from "@/tenant-context/core/store/big-map/big-map.model";

import MapStyleToggleList from "./MapStyleToggleList.component";

type Props = {
  sx?: DefaultProps["sx"];
};

const MapStyleToggleListContainer: FC<Props> = ({ sx }) => {
  const {
    bigMap: { setMapStyle }
  } = useDispatch<Dispatch>();

  const { refreshImages } = useContext(BigMapContext);

  const handleChangeMapStyle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (Object.keys(MAP_STYLES_URLS).includes(e.target.value)) {
        setMapStyle(e.target.value as MapStyle);
        refreshImages();
      }
    },
    [setMapStyle]
  );

  const currentMapUrl = useSelector(
    (state: RootState) => state.bigMap.mapStyleUrl
  );
  const currentMapStyle = useMemo<MapStyle>(
    () =>
      (Object.keys(MAP_STYLES_URLS).find(
        (key) => MAP_STYLES_URLS[key as MapStyle] === currentMapUrl
      ) as MapStyle) || "default",
    [currentMapUrl]
  );

  return <MapStyleToggleList sx={ sx } value={ currentMapStyle } onChangeMapStyle={ handleChangeMapStyle } />;
};

export default MapStyleToggleListContainer;
