import { Box, Divider, Flex, Stack, Switch, Text, Tooltip } from "@mantine/core";
import React, { FC, useCallback } from "react";

import ButtonControl from "@/common/components/ButtonControl";
import DrawerControl from "@/common/components/DrawerControl";
import { DrawerControlButtonRenderer } from "@/common/components/DrawerControl/DrawerControl.component";
import { ReactComponent as LayerToggleIcon } from '@/common/icons/layer-toggle.svg';
import { TestableComponent } from '@/common/types/testable-component';
import { ContextualLayersOptions, ContextualLayerSwitchType } from "@/core/store/data-options/data-options.state";
import {
  useBasemapLayerToggleControlStyles
} from '@/tenant-context/core/controls/BasemapLayerToggleControl/BasemapLayerToggleControl.styles';
import { isWeatherMapEnabled } from "@/visualisation-weather/components/AerisInteractiveMap.component";

import { MapStyleToggleList } from "./components";

type Props = {
  contextualLayersOptions: ContextualLayersOptions;
  onChangeContextualLayerSwitch: React.ChangeEventHandler<HTMLInputElement>;
  isRiskConnectorsPermissionAvailable?: boolean;
  isTimeTravelActive?: boolean;
} & TestableComponent;

const BasemapLayerToggleControl: FC<Props> = ({
  contextualLayersOptions,
  onChangeContextualLayerSwitch,
  dataTestId = "right-menu-layers",
  isRiskConnectorsPermissionAvailable = false,
  isTimeTravelActive = false
}) => {
  // const mantineTheme = useMantineTheme();
  const renderTestDrawerButton: DrawerControlButtonRenderer = useCallback(
    ({ onClick }) => (
      <ButtonControl label="Layers" onClick={ onClick } data-testid={ dataTestId }>
        <LayerToggleIcon/>
      </ButtonControl>
    ),
    [dataTestId]
  );

  const { classes } = useBasemapLayerToggleControlStyles();

  return (
    <DrawerControl
      id={ "basemap-layer-control" }
      title={ "Basemap" }
      renderButton={ renderTestDrawerButton }
    >
      <MapStyleToggleList/>

      <Flex my={ 32 } direction={ "column" } gap={ 8 }>
        <Text fz={ 26 } fw={ "bold" }>
          Contextual Data
        </Text>
        <div className={ classes.titleUnderLine }/>
      </Flex>

      <Stack spacing={ 8 }>
        { isTimeTravelActive && (
          <Text size="md" className={ classes.textNeutral }>
            Cannot access when not in live mode
          </Text>
        ) }
        <div className={ classes.listItem }>
          <Switch
            name={ ContextualLayerSwitchType.CountryRiskLevel }
            disabled={ !isRiskConnectorsPermissionAvailable || isTimeTravelActive }
            onChange={ onChangeContextualLayerSwitch }
            checked={ contextualLayersOptions.CountryRiskLevel }
          />
          <Tooltip
            hidden={ isRiskConnectorsPermissionAvailable }
            withinPortal
            label={ "Permissions are needed" }
          >
            <span className={ classes.listItemText }>
              Riskline Country Risk Level
            </span>
          </Tooltip>
        </div>
        <Divider
          className={ classes.divider }
          // eslint-disable-next-line react/jsx-no-bind
          sx={ (theme) => ({
            // eslint-disable-next-line no-magic-numbers
            borderTopColor: theme.colors.neutral[6]
          }) }
        />
        <div className={ classes.listItem }>
          <Switch
            name={ ContextualLayerSwitchType.Traffic }
            disabled={ isTimeTravelActive }
            onChange={ onChangeContextualLayerSwitch }
            checked={ contextualLayersOptions.Traffic }
          />
          <span className={ classes.listItemText }>Traffic</span>
        </div>
        <Divider
          className={ classes.divider }
          // eslint-disable-next-line react/jsx-no-bind
          sx={ (theme) => ({
            // eslint-disable-next-line no-magic-numbers
            borderTopColor: theme.colors.neutral[6]
          }) }
        />

        { /*<div className={ classes.listItem }>*/ }
        { /*  <Switch*/ }
        { /*    name={ ContextualLayerSwitchType.Weather }*/ }
        { /*    disabled={ isTimeTravelActive }*/ }
        { /*    onChange={ onChangeContextualLayerSwitch }*/ }
        { /*    checked={ contextualLayersOptions.Weather }*/ }
        { /*  />*/ }
        { /*  <span className={ classes.listItemText }>*/ }
        { /*    Weather*/ }
        { /*  </span>*/ }
        { /*</div>*/ }

        { isWeatherMapEnabled && <Box className={ classes.listItem } sx={ { minHeight: 'unset' } }>
          <Switch
            name={ ContextualLayerSwitchType.Radar }
            disabled={ isTimeTravelActive }
            onChange={ onChangeContextualLayerSwitch }
            checked={ contextualLayersOptions.Radar }
          />
          <span className={ classes.listItemText }>
            Radar (Precipitation)
          </span>
        </Box> }

        <Divider
          className={ classes.divider }
          // eslint-disable-next-line react/jsx-no-bind
          sx={ (theme) => ({
            // eslint-disable-next-line no-magic-numbers
            borderTopColor: theme.colors.neutral[6]
          }) }
        />

        { isWeatherMapEnabled && <Box className={ classes.listItem } sx={ { minHeight: 'unset' } }>
          <Switch
            name={ ContextualLayerSwitchType.Wind }
            disabled={ isTimeTravelActive }
            onChange={ onChangeContextualLayerSwitch }
            checked={ contextualLayersOptions.Wind }
          />
          <span className={ classes.listItemText }>
            Wind (Speed & Direction)
          </span>
        </Box> }

        <Divider
          className={ classes.divider }
          // eslint-disable-next-line react/jsx-no-bind
          sx={ (theme) => ({
            // eslint-disable-next-line no-magic-numbers
            borderTopColor: theme.colors.neutral[6]
          }) }
        />

        { isWeatherMapEnabled && <Box className={ classes.listItem } sx={ { minHeight: 'unset' } }>
          <Switch
            name={ ContextualLayerSwitchType.Cyclones }
            disabled={ isTimeTravelActive }
            onChange={ onChangeContextualLayerSwitch }
            checked={ contextualLayersOptions.Cyclones }
          />
          <span className={ classes.listItemText }>
            Tropical Cyclones
          </span>
        </Box> }
      </Stack>
    </DrawerControl>
  );
};

export default BasemapLayerToggleControl;
