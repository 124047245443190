import { forwardRef, ForwardRefRenderFunction, RefObject, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as OfflineIcon } from '@/common/icons/cloud-offline.svg';
import { ReactComponent as OnlineIcon } from '@/common/icons/cloud-online.svg';
import { ReactComponent as ImagePlaceholderIcon } from "@/common/icons/image-solid.svg";
import { Dispatch, RootState } from "@/core/store";
import { ILocation, LocationAddress } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";
import MasterTab from "@/tenant-context/visualisation-site/components/MusterTab";
import { SiteLocationSnapshotProperties, SitePeopleCount } from "@/tenant-context/visualisation-site/types/site.types";

import { useSiteLocationPopupStyles } from "../SiteLocationPopup/SiteLocationPopup.styles";


type Props = {
  popupContent: SiteLocationSnapshotProperties | ILocation,
  locationAddress?: LocationAddress | undefined
  ref?: RefObject<HTMLDivElement>
}

const CriticalLocationPopup: ForwardRefRenderFunction<HTMLDivElement, Props> = ({
  popupContent, locationAddress
}, ref) => {
  const locationsPeopleCount = useSelector((state: RootState) => state.sitePopup.locationsPeopleCount);

  const {
    siteLocations: {
      processSiteConnectivity
    }
  } = useDispatch<Dispatch>();

  const activeSite = useMemo(() => {
    // eslint-disable-next-line fp/no-let
    let activeSiteData: SitePeopleCount = {
      code: '',
      isMusterActive: false,
      musterCount: 0,
      total: 0,
      visitorsNext24Hrs: 0,
      visitorsOnSite: 0,
      countryName: '',
      address: '',
      description: ''
    };

    locationsPeopleCount.countries.forEach(
      (country) => {
        return country.sites.forEach((site) => {
          if (site.code === popupContent?.code) {
            activeSiteData = site;
          } else {
            return true;
          }
        });
      }
    );

    return activeSiteData;
  }, [locationsPeopleCount, popupContent]);
  const { classes: popupClasses } = useSiteLocationPopupStyles();

  const siteAddressString = useMemo(() => {
    if (!locationAddress) {
      return "";
    }

    const { firstLine, secondLine, thirdLine, city, country } = locationAddress as LocationAddress;
    const address = `${firstLine || ''} ${secondLine || ''} ${thirdLine || ''}`.trim() + `${(city || country) ? `, ${city || ''} ${country || ''}` : ''}`;
    return address.trim();
  }, [locationAddress]);

  const handleSiteStatus = useCallback((locationId: string) => {
    const siteStatus = processSiteConnectivity(locationId);
    if(siteStatus.status === 'Online'){
      return (<div className={ popupClasses.siteStatusContainer }><OnlineIcon /> { siteStatus.status }</div>);
    } else {
      return (<div className={ popupClasses.siteStatusContainer }><OfflineIcon />{ `${siteStatus.status} ${siteStatus.duration}` }</div>);
    }
  }, [processSiteConnectivity, popupClasses.siteStatusContainer]);

  const musterCount = activeSite.musterCount ?? 0;
  const isMuster = activeSite.isMusterActive ?? false;

  return (
    <div ref={ ref } className={ popupClasses.popupContainer }>
      <div className={ popupClasses.siteDescriptionContainer }>
        <div className={ popupClasses.popUpImgContainer }>
          { (popupContent as ILocation).imageUrl ? <img src={ (popupContent as ILocation).imageUrl as string } alt="site" /> : <div className={ popupClasses.imgPlaceHolderContainer }>
            <ImagePlaceholderIcon />
          </div> }
        </div>
        <div className={ popupClasses.siteDescription }>
          <div className={ popupClasses.siteName }>
            { popupContent.name }
          </div>
          <div className={ popupClasses.siteLocation }>
            { (popupContent as SiteLocationSnapshotProperties).category === 'PoiCategory' ? siteAddressString : popupContent.address }
          </div>
          <div className={ popupClasses.siteCategory }>
            { (popupContent as ILocation).locationType || '' }
          </div>
          { (popupContent as SiteLocationSnapshotProperties).subCategory === 'Last Mile Site' ?
            handleSiteStatus((popupContent as SiteLocationSnapshotProperties).id) : null }
        </div>
      </div>
      { isMuster && <MasterTab siteCode={ popupContent?.code } musterCount={ musterCount }/> }
    </div>
  );
};

export default forwardRef(CriticalLocationPopup);
