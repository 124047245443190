import { FeatureCollection } from "geojson";

import { PaginatedResult } from "@/common/types/reference-data";
import { callApi } from "@/common/util/api";
import { LocationCategoryTypes } from "@/tenant-context/control-integrations-mapping/types/IntegrationMapping.types";
import { ConnectorsLegacyCheck,POBDisplayResponse,POBPeopleListRequest,POBPeopleListResponse,SiteLocation } from "@/tenant-context/control-site/types/site";

export const getSiteGeoJson = (
  siteId: string
) => callApi<SiteLocation[]>(
  `location-management-service/v1/categories/Areas/sub-categories/Country/locations/${siteId}/geoJson`,
  {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    },
    withTenantId: true
  }
);

export const getSiteConnectors = () => callApi<ConnectorsLegacyCheck>(
  `/connector-core-service/v1/connectors`,
  {
    method: 'get'
  }
);

export const getSitePOBDetails = (
  category: LocationCategoryTypes, 
  siteId: string, 
  jsonRequest: FeatureCollection
) =>
  callApi<POBDisplayResponse>(
    `location-management-service/v2/${category}/${siteId}/people-on-building`,
    {
      method: "post",
      withTenantId: true,
      body: JSON.stringify(jsonRequest)
    }
  );

export const getPOBPeopleList = (searchType: LocationCategoryTypes, siteId: string, reqObj: POBPeopleListRequest) =>
  callApi<PaginatedResult<POBPeopleListResponse>>(
    `location-management-service/v2/${searchType}/${siteId}/people-on-building-list`,
    {
      method: 'post',
      withTenantId: true,
      body: JSON.stringify(reqObj)
    }
  );

