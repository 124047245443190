import { IndoorMapGeoJSON } from "@f/map-gl-indoor";
import { Feature, Polygon } from "@turf/turf";
import { EventData, MapLayerEventType } from "mapbox-gl";
import { FC, useMemo } from "react";
import Map, { MapLayerMouseEvent, NavigationControl } from 'react-map-gl';

import OnMapNotificationBox from "@/tenant-context/common/components/OnMapNotificationBox";
import { INITIAL_MAP_VIEW, WORLD_MAX_BOUNDS } from '@/tenant-context/common/util/constants';
import MapboxDrawControl from "@/tenant-context/control-draw/controls/MapboxDrawControl/MapboxDrawControl.component";
import DraggableMarkerLayer
  from '@/tenant-context/control-location-configuration/components/AddLocations/LocationDetailsTab/DraggableMarkerLayer';
import LocationMarker from "@/tenant-context/control-location-configuration/layers/LocationMarker";
import { EnteredLocationDetails } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";
import GeofenceLayerComponent
  from "@/tenant-context/visualisation-geofences/layers/GeofenceLayer/GeofenceLayer.component";

import { LocationTab } from "../../../../context/LocationInfo.context";
import { useAddLocationsStyles } from "../../AddLocations.styles";

const {
  REACT_APP_MAPBOX_ACCESS_TOKEN
} = process.env as {
  REACT_APP_MAPBOX_ACCESS_TOKEN?: string
};

type Props = {
  handleClickOnMap: (event: MapLayerMouseEvent) => void
  onDrawingUpdate: (evt: MapLayerEventType & EventData) => void;
  onDrawingDelete: (evt: MapLayerEventType & EventData) => void;
  onDrawingSelected: (evt: MapLayerEventType & EventData) => void;
  onDrawControlCreated?: (mapboxDraw: MapboxDraw) => void;
  activeTab?: string;
  activeGeofence?: Feature<Polygon> | IndoorMapGeoJSON
  enteredLocationDetails: EnteredLocationDetails
  isLocationExists: boolean
}

const LocationDetailsMapComponent: FC<Props> = ({
  handleClickOnMap,
  onDrawingUpdate,
  onDrawingDelete,
  onDrawingSelected,
  onDrawControlCreated,
  activeTab,
  activeGeofence,
  enteredLocationDetails,
  isLocationExists
}) => {
  const { classes } = useAddLocationsStyles();
  const isMarkerShown = useMemo(()=>{
    return (
      activeTab === LocationTab.LocationDetails ||
      activeTab === LocationTab.AdditionalDetails ||
      activeTab === LocationTab.Buildings_Floors ||
      activeTab === LocationTab.Zones
    );
  }, [activeTab]);

  const isDrawingComponentShown = useMemo(()=>{
    return (
      activeTab === LocationTab.SitePlan ||
      activeTab === LocationTab.Zones
    );
  }, [activeTab]);

  return (
    <div className={ classes.mapContainer }>
      <Map
        id="AddLocationMap"
        mapboxAccessToken={ REACT_APP_MAPBOX_ACCESS_TOKEN }
        minZoom={ 2 }
        initialViewState={ INITIAL_MAP_VIEW }
        mapStyle='mapbox://styles/smehmood/cl4feh10w000114pyksvp6mu9'
        renderWorldCopies={ false }
        maxBounds={ WORLD_MAX_BOUNDS }
        projection="mercator"
        fog={ {} }
        onClick={ handleClickOnMap }
      >
        { (activeTab === LocationTab.Buildings_Floors ) && (
          <GeofenceLayerComponent
            currentGeofence={ activeGeofence }
          />
        ) }

        { isDrawingComponentShown && (
          <MapboxDrawControl
            onCreate={ onDrawingUpdate }
            onUpdate={ onDrawingUpdate }
            onDelete={ onDrawingDelete }
            onDrawControlCreated={ onDrawControlCreated }
            onSelectionChanged={ onDrawingSelected }
          />
        ) }

        <GeofenceLayerComponent
          currentGeofence={ activeGeofence }
        />

        { isMarkerShown &&
          <DraggableMarkerLayer
            activeTab={ activeTab as LocationTab }
          /> }

        { (activeTab === LocationTab.SitePlan) && (<LocationMarker marker={ {
          lon: +enteredLocationDetails.lon,
          lat: +enteredLocationDetails.lat,
          title: enteredLocationDetails.description
        } }/>
        ) }

        <OnMapNotificationBox notification={ isLocationExists ?
          "Drag the marker to the desired location on the map to update its position.":
          'Click anywhere on the map to add a location pin!' }/>

        <NavigationControl
          position="bottom-right"
          showCompass={ false }
          style={ { order: 5 } }
        />
      </Map>
    </div>
  );
};

export default LocationDetailsMapComponent;
