import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonControl from "@/common/components/ButtonControl";
import DrawerControl from "@/common/components/DrawerControl";
import { DrawerControlButtonRenderer } from "@/common/components/DrawerControl/DrawerControl.component";
import { ReactComponent as TriangleIcon } from "@/common/icons/traingle.svg";
import { TestableComponent } from "@/common/types/testable-component";
import { Dispatch, RootState } from "@/core/store";
import NumberHighlightedTextComponent from '@/tenant-context/common/components/NumberHighlightedText/NumberHighlightedText.component';
import ARC from "@/tenant-context/control-action-response-center/components/ARC";
import MassComms from "@/tenant-context/control-mass-comms/components/MassComms";
import BSOCEventsSettingsContainer from "@/tenant-context/control-risk-config/components/BSOCEventsSettings/BSOCEventsSettings.container";
import DataMinrEventsSettings
  from "@/tenant-context/control-risk-config/components/DataMinrEventsSettings";
import MaxSecurityEventsSettings from '@/tenant-context/control-risk-config/components/MaxSecurityEventsSettings';
import RiskAlertDetails from "@/tenant-context/control-risk-config/components/RiskAlertDetails";
import RiskEventsControlFooter from '@/tenant-context/control-risk-config/components/RiskEventsControlFooter';
import RiskEventsProviderSelection from "@/tenant-context/control-risk-config/components/RiskEventsProviderSelection/RiskEventsProviderSelection.container";
import RiskLineEventsSettingsContainer
  from "@/tenant-context/control-risk-config/components/RiskLineEventsSettings/RiskLineEventsSettings.container";
import { useRiskEventsControlStyles } from "@/tenant-context/control-risk-config/controls/RiskEventsControl/RiskEventsControl.styles";
import { RiskProviderItem } from "@/tenant-context/control-risk-config/types/risk";
import { RiskAlertEvent } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts';

type Props = {
  onRiskProviderSelect: (riskProviderId?: string) => void,
  selectedRiskProvider?: string,
  riskProviders: RiskProviderItem[],
  isImpactCircleEnabled:boolean,
  isArcOpen: boolean,
  onDrawerClose: () => void,
  isMassCommsOpen: boolean,
  riskAlert?: RiskAlertEvent,
  onRequestToToggleArc: () => void,
  isAnotherOverlayModuleOpen: boolean
} & TestableComponent

const RiskEventsControl: FC<Props> = ({
  onRiskProviderSelect,
  selectedRiskProvider,
  riskProviders,
  isImpactCircleEnabled,
  isArcOpen,
  onDrawerClose,
  isMassCommsOpen,
  dataTestId = "right-menu-risk-events",
  riskAlert,
  onRequestToToggleArc,
  isAnotherOverlayModuleOpen
}) => {
  const [ drawerTitle, setDrawerTitle ] = useState<ReactNode>();

  const riskAlertsGeoData = useSelector((state: RootState) => state.riskAlerts.currentRiskAlert);
  
  const { isTimeTravelActive } = useSelector(
    (state: RootState) => state.rankingSettings
  );

  const { classes } = useRiskEventsControlStyles();

  const {
    riskAlerts: {
      CLEAR_CURRENT_RISK_ALERT
    }
  } = useDispatch<Dispatch>();

  const isSubDrawer = !!selectedRiskProvider && !isImpactCircleEnabled;

  const renderTestDrawerButton: DrawerControlButtonRenderer = useCallback(({ onClick }) => (
    <ButtonControl label="Threat Data" onClick={ onClick }
      data-testid={ dataTestId }
      disabled={ isTimeTravelActive }
      tooltip={ 'Cannot access when not in live mode' }
    >
      <TriangleIcon style={ { width: '20px' } }/>
    </ButtonControl>
  ), [dataTestId, isTimeTravelActive]);

  const handleDrawerClose = useCallback((riskProviderId?: string) => {
    // Mapping an event wierd coming from the drawer back button to undefined
    const providerId = typeof riskProviderId === 'string' ? riskProviderId : undefined;
    setDrawerTitle(undefined);
    onRiskProviderSelect(providerId);
    CLEAR_CURRENT_RISK_ALERT();
    onDrawerClose();
  }, [CLEAR_CURRENT_RISK_ALERT, onDrawerClose, onRiskProviderSelect]);

  // TODO REFACTOR THIS
  const title = useMemo((): string | JSX.Element | null => {
    if (!isImpactCircleEnabled && isSubDrawer) {
      return 'Risk Events';
    }

    if (!isArcOpen && !isMassCommsOpen && drawerTitle) {
      return (
        <div className={ classes.riskAlertHeading }>
          { drawerTitle }
        </div>
      );
    }

    return null;
  }, [isImpactCircleEnabled, isSubDrawer, isArcOpen, isMassCommsOpen, drawerTitle, classes.riskAlertHeading]);

  const getAgeComponent = useCallback((ageString: string) => {
    if (!ageString) {
      return null;
    }

    return <NumberHighlightedTextComponent
      numberFw={ 'bold' }
      numberFs={ 'sm' }
      alphaFs={ 12 }
      text={ ageString }
    />;
  }, []);

  const handleTitleChange = useCallback((dTitle: string) => {
    setDrawerTitle(getAgeComponent(dTitle));
  }, [getAgeComponent]);

  const getRiskSettingsComponent = useCallback(() => {
    if (selectedRiskProvider === 'bsoc') {
      return <BSOCEventsSettingsContainer/>;
    }

    if (selectedRiskProvider === 'dataminr') {
      return <DataMinrEventsSettings />;
    }

    if (selectedRiskProvider === 'max-security') {
      return <MaxSecurityEventsSettings />;
    }

    return <RiskLineEventsSettingsContainer/>;
  }, [ selectedRiskProvider ]);

  return (
    <DrawerControl
      id={ 'risk-events' }
      header={ ((isImpactCircleEnabled || riskAlertsGeoData) && !isArcOpen && !isMassCommsOpen) && title }
      title={ !isSubDrawer ? "Threat Data" : undefined }
      backButtonText={ (selectedRiskProvider && !isImpactCircleEnabled) && title }
      size={ (isArcOpen || isMassCommsOpen) ? 'full' : 'lg' }
      isSubDrawer={ isSubDrawer }
      isStyledTitle={ !!drawerTitle }
      isWithTitleBorder={ !drawerTitle }
      renderButton={ (!isArcOpen && !isMassCommsOpen) ?  renderTestDrawerButton : undefined }
      onBackButtonClick={ handleDrawerClose }
      onCloseClick={ handleDrawerClose }
      footer={ <RiskEventsControlFooter
        isImpactCircleEnabled={ isImpactCircleEnabled }
        isArcOpen={ isArcOpen }
        isMassCommsOpen={ isMassCommsOpen }
        onRequestToToggleArc={ onRequestToToggleArc }
        isAnotherOverlayModuleOpen={ isAnotherOverlayModuleOpen }
        riskAlert={ riskAlert }
        selectedRiskProvider={ selectedRiskProvider }
      /> }
      explainer={ (!selectedRiskProvider && !isImpactCircleEnabled && !riskAlertsGeoData) ? (
        riskProviders?.length > 0
          ? ("Select and deselect the risk events/feed data you wish to visualise on the map.")
          : ("You can add and visualise data on this map from multiple Risk Providers. Go to your admin area to learn how.")
      ) : undefined }
    >
      { !selectedRiskProvider && !isImpactCircleEnabled && !riskAlertsGeoData && (
        <RiskEventsProviderSelection
          onRiskProviderSelection={ handleDrawerClose }
          riskProviders={ riskProviders }
        />
      ) }

      { selectedRiskProvider && !isImpactCircleEnabled && getRiskSettingsComponent() }

      { ((isImpactCircleEnabled || riskAlertsGeoData) && !isArcOpen && !isMassCommsOpen) && ( // CLEAN
        <RiskAlertDetails
          onTimesAgoCalculated={ handleTitleChange }
        />
      ) }

      { (isImpactCircleEnabled && isArcOpen) && (
        <ARC />
      ) }

      { (isImpactCircleEnabled && isMassCommsOpen) && (
        <MassComms />
      ) }
    </DrawerControl>
  );
};

export default RiskEventsControl;
