/* eslint-disable react/jsx-props-no-spreading */
import {
  ActionIcon,
  Button,
  Chip,
  Col,
  Divider,
  FileInput,
  Grid,
  Select,
  Switch,
  Textarea,
  TextInput
} from "@mantine/core";
import { FeatureCollection, Point } from "geojson";
import { FC, useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import { ReactComponent as CloseIcon } from "@/common/icons/close.svg";
import { ReactComponent as PlusIcon } from "@/common/icons/plus.svg";
import { MapboxGeocodingProperties } from "@/tenant-context/common/types/mapbox-geocoding";
import { UploadLocationImageConfig } from "@/tenant-context/control-location-configuration/config/location-configuration.config";
import {
  LocationDetailsFormType
} from "@/tenant-context/control-location-configuration/context/LocationManagement.context";
import { EnteredLocationDetails, GeoTimeZone } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";
import MapSearch from "@/tenant-context/widget-overview/components/MapSearch";

import LocationCategory from "../../../LocationList/LocationCategory/LocationCategory.container";
import { useAddLocationsStyles } from "../../AddLocations.styles";

type Props = {
  allCountries: Array<{ value: string, label: string }>,
  locationTypes: Array<{ value: string, label: string }>,
  enteredLocationDetails: EnteredLocationDetails,
  currentSearchLocation: FeatureCollection<Point> | undefined,
  timeZones: Array<GeoTimeZone>
  currentAlias: string,
  isAuthorizedToEdit: boolean,
  locationDetailsForm: UseFormReturn<LocationDetailsFormType>,
  onChangeFormInputs: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void,
  onChangeCountrySelect: (event: string | null) => void,
  onChangeTimeZone: (event: string | null) => void,
  onChangeCategories: (event: string | null) => void,
  onChangeLocationTypes: (event: string | null) => void,
  onRemoveLocationImage: () => void,
  onLatitudeChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onLongitudeChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onCriticalLocationChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeSitePicUpload: (file: File | null) => void,
  onAddLocationAliasChange: () => void,
  onCurrentAliasChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onRemoveLocationAlias: (index: number) => void,
  uploadedImagePreview: string | undefined,
  imageUploadInputRef: React.MutableRefObject<HTMLButtonElement | null>,
  sitePicture: File | null
}

const LocationDetailsFormComponent: FC<Props> = ({
  onChangeFormInputs,
  allCountries,
  enteredLocationDetails,
  onChangeCountrySelect,
  onChangeTimeZone,
  onLatitudeChange,
  onLongitudeChange,
  onCriticalLocationChange,
  timeZones,
  onChangeCategories,
  onChangeLocationTypes,
  locationTypes,
  onChangeSitePicUpload,
  onAddLocationAliasChange,
  onCurrentAliasChange,
  currentAlias,
  onRemoveLocationAlias,
  isAuthorizedToEdit,
  onRemoveLocationImage,
  currentSearchLocation,
  locationDetailsForm,
  uploadedImagePreview,
  imageUploadInputRef,
  sitePicture
}) => {

  const { classes, cx } = useAddLocationsStyles();

  const [countryValue, setCountryValue] = useState<string>();
  const [timezone, setTimezone] = useState<string>();

  const { register, setValue, reset: resetLocationForm, resetField } = locationDetailsForm;

  const isNewLocation = !enteredLocationDetails.tid;

  const resetAddressFields = useCallback(() => {
    resetField('lat');
    resetField('lon');
    resetField('isoCountryCode');
    resetField('timezones');
    resetField('address01');
    resetField('address02');
    resetField('city');
    resetField('countyRegion');
    resetField('postalCode');
  }, [resetField]);

  // Filling location details for existing location
  useEffect(()=>{
    if (!isNewLocation) {
      const {
        id= '',
        code= '',
        description= '',
        countyRegion= '',
        timezones,
        isCriticalLocation= false
      } = enteredLocationDetails;

      setValue("isCriticalLocation", !!isCriticalLocation);
      setValue("code", code);
      setValue("id", id);
      setValue("description", description);
      setValue("countyRegion", countyRegion);
      if (timezones) {
        setTimezone(timezones.timeZone);
      }
    }

    setValue("lat", Number(enteredLocationDetails.lat).toFixed(6));
    setValue("lon", Number(enteredLocationDetails.lon).toFixed(6));
  }, [enteredLocationDetails, isNewLocation, setValue]);

  // Filling location details for new location came through location search
  useEffect(() => {
    if (currentSearchLocation) {
      resetAddressFields();
      const { features } = currentSearchLocation;

      if (features.length) {
        const [feature] = features;
        const { geometry, properties } = feature;

        const { coordinates } = geometry;
        // Timezone is setted in the map search modal
        const { name,  address, context, timezone: timeZone } =
          properties as MapboxGeocodingProperties & { timezone: GeoTimeZone };
        const { country , place, postcode } = context;

        const resolvedAddress = address ? address : name;

        setValue("lat", coordinates[1].toFixed(6));
        setValue("lon", coordinates[0].toFixed(6));

        if (country?.country_code) {
          setCountryValue(country.country_code);
        }

        if (place?.name) {
          setValue("city", place.name);
        }

        if (resolvedAddress) {
          setValue("address01", resolvedAddress);
        }

        if (postcode?.name) {
          setValue("postalCode", postcode.name);
        }

        if (timeZone) {
          setTimezone(timeZone.timeZone);
        }
      }
    }
  }, [currentSearchLocation, resetAddressFields, setValue]);

  useEffect(()=>{
    const { address01, address02, postalCode, city, isoCountryCode } = enteredLocationDetails;
    setValue("address01", address01);
    setValue("address02", address02);
    setValue("postalCode", postalCode);
    setValue("city", city);
    setCountryValue(isoCountryCode);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enteredLocationDetails.address01,
    enteredLocationDetails.address02,
    enteredLocationDetails.isoCountryCode,
    enteredLocationDetails.postalCode
  ]);

  // Resetting location form on destroy
  useEffect(() => {

    return () => {
      resetLocationForm();
    };
  }, [resetLocationForm]);

  return (
    <div className={ classes.formMainContainer }>
      <div className={ classes.formItemContainer }>
        <div className={ classes.formHeaderWrapper }>
          <div className={ classes.formTitle }>Provide essential location details here</div>
          <div className={ classes.formValidationText }>
            <div>Critical Location</div>
            <Switch checked={ !!enteredLocationDetails.isCriticalLocation }
              size="sm" disabled={ !isAuthorizedToEdit }
              { ...register('isCriticalLocation') }
              onChange={ onCriticalLocationChange } /></div>
        </div>
        <div className={ classes.formBody }>
          <form>
            <div className={ classes.formSet }>
              <div className={ classes.formItem }>
                <div className={ classes.formItemHeader }>Location Type *</div>
                <Select disabled={ !isAuthorizedToEdit } value={ enteredLocationDetails.locationType }
                  searchable data={ locationTypes } onChange={ onChangeLocationTypes }/>
              </div>
              <div className={ classes.formItem + " " + classes.locationCatWrap }>
                <LocationCategory
                  disabled={ !isAuthorizedToEdit }
                  handleCategoryChange = { onChangeCategories }
                  inputLabel = { "Location Category *" }
                  value = { enteredLocationDetails.subCategoryName }
                  isForm
                />
              </div>
            </div>
            <div className={ classes.formSet }>
              { !isNewLocation && <div className={ classes.formItem }>
                <div className={ classes.formItemHeader }>Location Code</div>
                <TextInput disabled={ true } maxLength={ 16 } { ...register('code') }
                  onChange={ onChangeFormInputs }/>
              </div> }
              <div className={ isNewLocation ? classes.formItemFullWidth : classes.formItem }>
                <div className={ classes.formItemHeader }>Location Name *</div>
                <TextInput disabled={ !isAuthorizedToEdit } maxLength={ 128 } { ...register('id') } onChange={ onChangeFormInputs } />
              </div>
              <div className={ classes.formItemFullWidth }>
                <div className={ classes.formItemHeader }>Additional Location Names</div>
                <Grid gutter={ "md" }>
                  <Col span={ 8 }>
                    <TextInput disabled={ !isAuthorizedToEdit }
                      value={ currentAlias } maxLength={ 128 } onChange={ onCurrentAliasChange } />
                  </Col>
                  <Col span={ 4 }>
                    <Button disabled={ !isAuthorizedToEdit }
                      onClick={ isAuthorizedToEdit ? onAddLocationAliasChange : undefined }
                      fullWidth
                      rightIcon={ <PlusIcon /> }
                    >
                      Add Names
                    </Button>
                  </Col>
                </Grid>
                <Chip.Group multiple>
                  { enteredLocationDetails.alias.map((item, index) => <Chip key={ "alias-" + index }
                    defaultChecked
                    checked={ false }
                    className={ classes.chip }
                  >
                    <ActionIcon disabled={ !isAuthorizedToEdit }
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => isAuthorizedToEdit ? onRemoveLocationAlias(index) : undefined }>
                      <CloseIcon />
                    </ActionIcon>

                    <span>
                      { item }
                    </span>
                  </Chip>) }
                </Chip.Group>
              </div>
              <div className={ classes.formItemFullWidth }>
                <div className={ classes.formItemHeader }>
                  Description
                  <span className={ classes.formItemSubLabel }>&nbsp;(optional)</span>
                </div>
                <Textarea disabled={ !isAuthorizedToEdit } maxLength={ 1024 } { ...register('description') } onChange={ onChangeFormInputs } />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className={ classes.formItemContainer }>
        <div className={ classes.formHeaderWrapper }>
          <div className={ classes.formTitle }>Provide address details for this location</div>
        </div>
        <div className={ classes.formBody }>
          <form>
            <div className={ classes.formSet }>

              { /*Address Search*/ }
              <div className={ classes.formItemFullWidth }>
                <div className={ classes.formItemHeader }>Search for a place name or type an address</div>
                <MapSearch isFullWidth/>
              </div>

              <div className={ cx(classes.formItemFullWidth, classes.formDivider) }>
                <div style={ { width: '39%' } }>
                  <Divider orientation="horizontal"/>
                </div>
                <div>or add manually</div>
                <div style={ { width: '39%' } }>
                  <Divider orientation="horizontal"/>
                </div>
              </div>

              { /*Address Input*/ }
              <div className={ classes.formItemFullWidth }>
                <div className={ classes.formItemHeader }>Address Line 1 *</div>
                <TextInput disabled={ !isAuthorizedToEdit } { ...register('address01', { required: true }) }
                  onChange={ onChangeFormInputs }
                  maxLength={ 256 }/>
              </div>
              <div className={ classes.formItemFullWidth }>
                <div className={ classes.formItemHeader }>
                  Address Line 2
                  <span className={ classes.formItemSubLabel }>&nbsp;(optional)</span>
                </div>
                <TextInput disabled={ !isAuthorizedToEdit } { ...register('address02') } onChange={ onChangeFormInputs }
                  maxLength={ 256 }/>
              </div>
              <div className={ classes.formItem }>
                <div className={ classes.formItemHeader }>
                  Postal Code / Zip
                  <span className={ classes.formItemSubLabel }>&nbsp;(optional)</span>
                </div>
                <TextInput disabled={ !isAuthorizedToEdit } { ...register('postalCode') } onChange={ onChangeFormInputs }
                  maxLength={ 16 }/>
              </div>
              <div className={ classes.formItem }>
                <div className={ classes.formItemHeader }>
                  County / Region
                  <span className={ classes.formItemSubLabel }>&nbsp;(optional)</span>
                </div>
                <TextInput disabled={ !isAuthorizedToEdit } { ...register('countyRegion') } onChange={ onChangeFormInputs }
                  maxLength={ 128 }/>
              </div>
              <div className={ classes.formItem }>
                <div className={ classes.formItemHeader }>City</div>
                <TextInput disabled={ !isAuthorizedToEdit } { ...register('city') } onChange={ onChangeFormInputs }
                  maxLength={ 128 }/>
              </div>
              <div className={ classes.formItem }>
                <div className={ classes.formItemHeader }>Country *</div>
                <Select disabled={ !isAuthorizedToEdit } value={ countryValue }
                  searchable data={ allCountries } { ...register('isoCountryCode', { required: true }) }
                  onChange={ onChangeCountrySelect }/>
              </div>
              <div className={ classes.formItem }>
                <div className={ classes.formItemHeader }>Latitude *</div>
                <TextInput disabled={ !isAuthorizedToEdit }
                  type="number" step="any" title="Please enter a valid value between 90 and -90" { ...register('lat', addErrorMessages('Latitude', {
                    required: true,
                    max: 90,
                    min: -90
                  })) } onBlur={ onLatitudeChange } maxLength={ 16 }/>
              </div>
              <div className={ classes.formItem }>
                <div className={ classes.formItemHeader }>Longitude *</div>
                <TextInput disabled={ !isAuthorizedToEdit }
                  type="number" step="any" title="Please enter a valid value between 180 and -180" { ...register('lon', addErrorMessages('Longitude', {
                    required: true,
                    max: 180,
                    min: -180
                  })) } onBlur={ onLongitudeChange } maxLength={ 16 }/>
              </div>
              <div className={ classes.formItemFullWidth }>
                <div className={ classes.formItemHeader }>Time zone</div>
                <Select
                  disabled={ !isAuthorizedToEdit } { ...register('timezones') } value={ timezone }
                  searchable data={ timeZones } onChange={ onChangeTimeZone }/>
              </div>
              { (enteredLocationDetails.imageUrl || uploadedImagePreview) && (
                <div className={ classes.formItemFullWidth }>
                  <div className={ classes.formItemHeader }>Uploaded Image</div>
                  <div className={ classes.locationImageWrap }>
                    <img className={ classes.locationImageDisplay } alt="location-uploaded-img"
                      src={ uploadedImagePreview ?? enteredLocationDetails.imageUrl }/>
                    <Button onClick={ onRemoveLocationImage } variant="outline">Remove Image</Button>
                  </div>

                </div>
              ) }
              <div className={ classes.formItemFullWidth }>
                <div className={ classes.formItemHeader }>Location Image</div>
                <Grid gutter={ "md" }>
                  <Col span={ 7 }>
                    <FileInput
                      ref={ imageUploadInputRef }
                      value={ sitePicture }
                      className={ classes.fileInput }
                      accept={ UploadLocationImageConfig.ALLOWED_FILE_FORMATS.join(", ") }
                      disabled={ !isAuthorizedToEdit }
                      onChange={ onChangeSitePicUpload }/>
                    <div className={ classes.formItemHeader }>
                      Maximum file size is 1MB. Supported file types are { " " }
                      { UploadLocationImageConfig.HUMAN_READABLE_ALLOWED_FILE_FORMATS }.
                    </div>
                  </Col>

                  <Col span={ 5 }>
                    <Button
                      /* eslint-disable-next-line react/jsx-no-bind */
                      onClick={ () => imageUploadInputRef.current?.click() }
                      rightIcon={ <PlusIcon/> }
                      fullWidth
                    >
                      Upload Location Image
                    </Button>
                  </Col>
                </Grid>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LocationDetailsFormComponent;
