import GeoJsonHelper from "@f/map-gl-indoor/GeojsonHelper";
import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { openConfirmationModal } from "@/common/components/Modal/ConfirmationModal";
import useUnauthorizedModal from "@/common/hooks/useUnauthorizedModal";
import { Dispatch, RootState } from "@/core/store";
import FloorCellComponent
  from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/components/FloorCell/FloorCell.component";
import {
  LocationInfoContext
} from "@/tenant-context/control-location-configuration/context/LocationInfo.context";
import { FloorListItem } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

type Props = {
  floor: FloorListItem
}
const FloorCell: FC<Props> = ({ floor }) => {

  const {
    manageLocations: {
      SET_ACTIVE_FLOOR,
      SET_ACTIVE_FLOOR_PLAN,
      deleteFloor,
      getFloor

    }
  } = useDispatch<Dispatch>();

  const { isAuthorizedToEdit } = useContext(LocationInfoContext);

  const { AddLocationMap: map } = useMap();

  const [isExpanded, setIsExpanded] = useState(false);
  const activeFloorPlan = useSelector((state: RootState) => state.manageLocations?.activeFloorPlan);
  const activeFloor = useSelector((state: RootState) => state.manageLocations?.activeFloor);

  const handleUnauthorizedAction = useUnauthorizedModal();

  const handleDeleteFloorWithZonesAction = useUnauthorizedModal(
    'Warning',
    { customMessage: 'You can\'t delete floor with zones. Please delete zones first' }
  );

  const handleDeleteFloor = useCallback((e: React.MouseEvent, floorListItem?: FloorListItem) => {
    e.stopPropagation();
    e.preventDefault();
    if (!isAuthorizedToEdit) {
      handleUnauthorizedAction();
      return;
    }
    if (floorListItem?.zones && +floorListItem?.zones > 0) {
      handleDeleteFloorWithZonesAction();
      return;
    }
    openConfirmationModal({
      text: "Are you sure you want to remove this floor?",
      onConfirm: async () => await deleteFloor(floorListItem?.floorTid ?? ''),
      title: "Confirm Removal"
    });
  }, [deleteFloor, handleDeleteFloorWithZonesAction, handleUnauthorizedAction, isAuthorizedToEdit]);

  const handleFloorClick = useCallback(() => {
    if (!isExpanded) {
      getFloor(floor?.floorTid);
      setIsExpanded(true);
    } else {
      SET_ACTIVE_FLOOR(undefined);
      setIsExpanded(false);
    }
  }, [SET_ACTIVE_FLOOR, floor?.floorTid, getFloor, isExpanded]);

  useEffect(() => {
    if (activeFloor?.geoJson?.geoJson) {
      SET_ACTIVE_FLOOR_PLAN(JSON.parse(activeFloor.geoJson.geoJson));
    } else {
      SET_ACTIVE_FLOOR_PLAN(undefined);
    }
  }, [SET_ACTIVE_FLOOR_PLAN, activeFloor]);

  useEffect(() => {
    if (activeFloorPlan) {
      const { bounds } = GeoJsonHelper.extractLevelsRangeAndBounds(activeFloorPlan);
      if (map && bounds) {
        map?.fitBounds(bounds as [number, number, number, number], { padding: 100 });
      }
    }
  }, [activeFloorPlan, map]);

  return (
    <FloorCellComponent
      isExpanded={ isExpanded }
      setIsExpanded={ setIsExpanded }
      floor={ floor }
      handleDeleteFloor={ handleDeleteFloor }
      handleFloorClick={ handleFloorClick }
    />
  );
};

export default FloorCell;
