/* eslint-disable no-param-reassign */
import { Point } from "geojson";
import mapboxgl from "mapbox-gl";
import { createContext, FC, RefObject, useCallback,useEffect, useRef, useState } from "react";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import useContextValue from "@/common/hooks/useContextValue";
import usePermission from "@/common/hooks/usePermission";
import ensureDeserialized from "@/common/util/ensure-deserialized";
import { Dispatch, RootState } from "@/core/store";
import useMapPopup from "@/tenant-context/common/hooks/useMapPopup";
import { AssetRankingEvent, AssetRankingSummary } from "@/tenant-context/common/types/asset-ranking";
import { PopupCoordinates } from "@/tenant-context/common/types/popup.types";
import { getClickedOnFeatures, getClickedOnFeatures as getFeaturesInteractedWith } from "@/tenant-context/common/util/map-click";
import { MiniProfileDatails } from "@/tenant-context/control-profile/types/mini-profile";
import { IndoorPersonAssetSummary, IndoorPersonLocationProperties } from "@/tenant-context/plugin-indoors/types/indoor-map.types";
import { PeoplePolicies } from "@/tenant-context/visualisation-people/configs/People.policies";
import { PersonCurrentTravelInfo, PersonPopupAssetRankingEvent, PopupPositionInfo } from "@/tenant-context/visualisation-people/types/people.types";

import { getCurrentTravelItenerary } from "../api/people";

export type MapboxHoverEvent = mapboxgl.MapMouseEvent & { features?: mapboxgl.MapboxGeoJSONFeature[] | undefined; }
& mapboxgl.EventData;

export type PeopleContextType = {
  setIsIndividualPersonPopupShown: React.Dispatch<React.SetStateAction<boolean>>,
  individualPersonPopupCoordinates: PopupCoordinates | undefined,
  setIndividualPersonPopupCoordinates: React.Dispatch<React.SetStateAction<PopupCoordinates | undefined>>,
  handlePersonLocationHover: (evt: MapboxHoverEvent) => void,
  handlePersonLocationLeave: (evt: MapboxHoverEvent) => void,
  hoveredOnPerson: PersonPopupAssetRankingEvent | undefined,
  enablePopupForRankingEvent: (event: AssetRankingEvent) => void,
  handleCurrentTravelInfo: (segmentId: string) => void,
  individualPersonPopupRef: RefObject<HTMLDivElement>
  handleMouseEnterPopup: () => void,
  handleMouseLeavePopup:() => void,
  handlePersonLocationClick:(evt: MapboxHoverEvent) => void,
  handleClickOutside:() => void,
  calculatePopupOffset:() => void,
  lineEndCoordinates?: PopupCoordinates | undefined,
  clickedPersonEvent?: MapboxHoverEvent,
  personCurrentTravelInfo: PersonCurrentTravelInfo | undefined,
  tempProfiles?: Array<MiniProfileDatails>,
  popupPosition?: PopupPositionInfo | undefined
};

const INDOORS_VISIBILITY_MIN_ZOOM = 15;

export const PeopleContext = createContext<PeopleContextType>({} as PeopleContextType);
export const PeopleContextProvider: FC = ({
  children
}) => {
  const allCountries = useSelector((state: RootState) => state.commonData.allCountries);
  const [lineEndCoordinates, setLineEndCoordinates] = useState<PopupCoordinates | undefined>();
  const [hoveredOnPerson, setHoveredOnPerson] = useState<PersonPopupAssetRankingEvent>();
  const [personCurrentTravelInfo, setPersonCurrentTravelInfo]
    = useState<PersonCurrentTravelInfo | undefined>(undefined);
  const [clickedPersonEvent, setClickedPersonEvent] = useState<MapboxHoverEvent | undefined>();
  const [popupPosition, setPopupPosition] = useState<PopupPositionInfo | undefined>(undefined);

  const loggedUser = useSelector((state: RootState) => state.profile.loggedUser);

  const {
    peopleLocations: {
      subscribeToPeopleLocationData
    },
    globalPeople: {
      getMiniProfileById
    },
    reverseGeocoding: {
      getReverseGeoLocation
    },
    peopleBreadcrumbs: {
      SET_CURRENT_PERSON: SET_CURRENT_BREADCRUMBS_PERSON,
      safeToggleLayerVisibility
    },
    locationGraph: {
      enableGraphForPerson,disableGraph
    },
    userProfile:{
      SET_CURRENT_USER_ID,
      RESET_ITINERARY_DATA,
      SET_TRAVEL_CORRELATION_ID
    },
    drawer: {
      openRightDrawer
    },
    connectedSitesDashboard:{
      loadListUserSiteAndPolicies
    }
  } = useDispatch<Dispatch>();
  const popupOpenTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const popupCloseTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const popupDelayInMilliseconds = 1000;
  const { current: map } = useMap();

  const isPeopleRankingPermissionAvailable = usePermission(PeoplePolicies.PEOPLE_RANKING_POLICY);

  useEffect(() => {
    if (!allCountries || !allCountries.length || !isPeopleRankingPermissionAvailable) {
      return;
    }

    subscribeToPeopleLocationData();
  }, [ subscribeToPeopleLocationData, allCountries, isPeopleRankingPermissionAvailable ]);
  
  useEffect(()=>{
    loadListUserSiteAndPolicies({ gridParams: { page: 0, size: 9999 } });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser?.assignedTenantUserTid]);

  const {
    setIsPopupShown: setIsIndividualPersonPopupShown,
    popupCoordinates: individualPersonPopupCoordinates,
    setPopupCoordinates: setIndividualPersonPopupCoordinates,
    popupRef: individualPersonPopupRef
  } = useMapPopup("PersonLocation");

  const enablePopupForRankingEvent = useCallback(
    async (event: AssetRankingEvent) => {
      setHoveredOnPerson(undefined);
      const { location: { point: { lat, lon } } } = event;
      setIndividualPersonPopupCoordinates({
        latitude: lat,
        longitude: lon
      });
      setIsIndividualPersonPopupShown(true);
      // use address if found
      try {
        if (event.adapterSource !== 'travel-book-adapter-service' && event.assetId) {

          const { place_name } = await getReverseGeoLocation({
            lon: event.location.point.lon,
            lat: event.location.point.lat
          });
          
          (event as PersonPopupAssetRankingEvent).location.address = place_name ?? 'We currently have no location data for this individual';
        } else {
          (event as PersonPopupAssetRankingEvent).location.address = 'We currently have no location data for this individual';
        }
        if (event.assetId) {
          const {
            nationality,
            personType,
            countryOfResidenceRef,
            jobTitle,
            company,
            profilePictureThumbnailUrl,
            firstName,
            lastName
          }
            = await getMiniProfileById(event.assetId);

          (event as PersonPopupAssetRankingEvent).jobTitle = jobTitle ?? 'N/A';
          (event as PersonPopupAssetRankingEvent).nationality = nationality ?? 'N/A';
          (event as PersonPopupAssetRankingEvent).personType = personType ?? 'N/A';
          (event as PersonPopupAssetRankingEvent).countryOfResidenceRef = countryOfResidenceRef ?? 'N/A';
          (event as PersonPopupAssetRankingEvent).company = company ?? 'N/A';
          (event as PersonPopupAssetRankingEvent).firstName = firstName ?? undefined;
          (event as PersonPopupAssetRankingEvent).lastName = lastName ?? undefined;
          (event as PersonPopupAssetRankingEvent).profilePictureThumbnailUrl = profilePictureThumbnailUrl ?? '';
        }

      // use N/A if not
      } catch (err) {
        (event as PersonPopupAssetRankingEvent).location.address = 'We currently have no location data for this individual';
        (event as PersonPopupAssetRankingEvent).jobTitle = 'N/A';
        (event as PersonPopupAssetRankingEvent).nationality = 'N/A';
        (event as PersonPopupAssetRankingEvent).personType = 'N/A';
        (event as PersonPopupAssetRankingEvent).countryOfResidenceRef = 'N/A';
        (event as PersonPopupAssetRankingEvent).company = 'N/A';
        (event as PersonPopupAssetRankingEvent).firstName = undefined;
        (event as PersonPopupAssetRankingEvent).lastName = undefined;
      }
      setHoveredOnPerson(event as PersonPopupAssetRankingEvent);

    },
    [getMiniProfileById, getReverseGeoLocation, setIndividualPersonPopupCoordinates, setIsIndividualPersonPopupShown]
  );

  const calculatePopupOffset = useCallback(() => {
    // Todo - Temorarily disabled due to the requirement of disabling lines in people markers
    return;

    // if(hoveredOnPerson) {
    //   const mappedLngLat = map?.unproject(new mapboxgl.Point(
    //     window.screen.width - RIGHT_PANEL_OFFSET_IN_PIXELS,
    //     window.screen.height/2
    //   ));
    //   const popupPosition = getPopupOrDrawerPosition(
    //     map?.getBounds(),
    //     {
    //       type: 'Point',
    //       coordinates: [hoveredOnPerson.location.point.lon, hoveredOnPerson.location.point.lat]
    //     },
    //     mappedLngLat,
    //     true
    //   );
    //
    //   setLineEndCoordinates({
    //     latitude: popupPosition[1],
    //     longitude:popupPosition[0]
    //   });
    // }
  },[]);

  const handlePersonLocationLeave = useCallback(() => {
    if(map){
      map.getCanvas().style.cursor = 'default';
    }

    popupOpenTimer.current =  setTimeout(() =>{
      setIsIndividualPersonPopupShown(false);
    },popupDelayInMilliseconds);
  },[map, setIsIndividualPersonPopupShown]);

  const handleMouseEnterPopup = useCallback(() => {
    if(popupOpenTimer && popupOpenTimer.current){
      clearTimeout(popupOpenTimer.current);
    }
  },[]);

  const handleClickOutside = useCallback(() => {
    safeToggleLayerVisibility(false);
    disableGraph();
    setLineEndCoordinates(undefined);
  },[disableGraph, safeToggleLayerVisibility]);

  const handleMouseLeavePopup = useCallback(() => {
    popupCloseTimer.current = setTimeout(() => {
      setPopupPosition(undefined);
      setIsIndividualPersonPopupShown(false);
    }, popupDelayInMilliseconds);
  },[setIsIndividualPersonPopupShown]);

  const handlePersonLocationHover = useCallback(
    (evt: mapboxgl.MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData) => {
      const popUpDimensions = individualPersonPopupRef?.current?.getBoundingClientRect();
      setPopupPosition({
        clientX: evt.originalEvent.clientX,
        clientY: evt.originalEvent.clientY,
        popupMaxHeight: popUpDimensions?.height || 650,
        popupMaxWidth: popUpDimensions?.width || 400
      });
      if(map){
        map.getCanvas().style.cursor = 'pointer';
      }

      if(popupOpenTimer && popupOpenTimer.current){
        clearTimeout(popupOpenTimer.current);
      }

      if(popupCloseTimer && popupCloseTimer.current){
        clearTimeout(popupCloseTimer.current);
      }
      setIsIndividualPersonPopupShown(false);
      const { layerFeatures } = getFeaturesInteractedWith(
        evt,
        ['r__peopleLocationSnapshotLayer', 'r__peopleLocationAlarmSnapshotLayer','r__possible-locations-secondary', 'r__indoorPeopleLocationSnapshotLayer']
      );

      if (!layerFeatures.length) {
        return;
      }
      const feature = layerFeatures[0];
      if (feature.layer.id === 'r__peopleLocationSnapshotLayer' || feature.layer.id === 'r__peopleLocationAlarmSnapshotLayer') {
        const { topRank } = ensureDeserialized(
          feature.properties as AssetRankingSummary,
          ['topRank']
        );

        enablePopupForRankingEvent(topRank);
      } else if (feature.layer.id === 'r__indoorPeopleLocationSnapshotLayer') {
        const { people, calculatedGeoLocation } = ensureDeserialized(
          feature.properties as IndoorPersonLocationProperties,
          ['people', 'calculatedGeoLocation']
        );
        const { topRank } = people as IndoorPersonAssetSummary;
        // Setting the calculated location point as the popup coordinates since the location in the topRank and the
        // location in the indoor map due to zonal placement are not the same.
        const topRankWithCalculatedLocation: AssetRankingEvent = {
          ...topRank,
          location: {
            ...topRank.location,
            point: {
              lat: calculatedGeoLocation.lat,
              lon: calculatedGeoLocation.lon
            }
          }
        };

        enablePopupForRankingEvent(topRankWithCalculatedLocation);
      } else {
        const rankingEvent = ensureDeserialized(
          feature.properties as AssetRankingEvent,
          ['location']
        );

        enablePopupForRankingEvent(rankingEvent);
      }
    },
    [individualPersonPopupRef, map, setIsIndividualPersonPopupShown, enablePopupForRankingEvent]
  );

  const handlePersonLocationClick = useCallback(
    (evt: mapboxgl.MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData) => {
      RESET_ITINERARY_DATA();
      setClickedPersonEvent(undefined);
      const { layerFeatures: [{ properties }] } = getClickedOnFeatures(evt, [
        'r__peopleLocationSnapshotLayer', 'r__peopleLocationAlarmSnapshotLayer' 
      ]); 
      const { personId } = properties as AssetRankingSummary;
      SET_CURRENT_BREADCRUMBS_PERSON(personId);
      SET_CURRENT_USER_ID(personId);
      const rankProps = JSON.parse(properties?.topRank);
      if (rankProps.adapterSource === 'travel-book-adapter-service') {
        SET_TRAVEL_CORRELATION_ID(rankProps?.correlationId);
      } else {
        SET_TRAVEL_CORRELATION_ID(undefined);
      }
      enableGraphForPerson(personId);
      openRightDrawer('mini-profile-control');
      safeToggleLayerVisibility(true);
      setClickedPersonEvent(rankProps);
      calculatePopupOffset();
      
      const { layerFeatures } = getClickedOnFeatures(evt, [
        'r__peopleLocationSnapshotLayer', 'r__peopleLocationAlarmSnapshotLayer'
      ]);

      if (!layerFeatures.length || !map) {
        return;
      }

      const feature = layerFeatures[0];
      const { coordinates } = (feature.geometry as Point);

      if (map.getZoom() < INDOORS_VISIBILITY_MIN_ZOOM) {
        map.flyTo({
          center: [coordinates[0], coordinates[1]],
          zoom: INDOORS_VISIBILITY_MIN_ZOOM
        });
      }

    },
    [
      map,
      SET_CURRENT_BREADCRUMBS_PERSON,
      SET_CURRENT_USER_ID,
      calculatePopupOffset,
      enableGraphForPerson,
      openRightDrawer,
      safeToggleLayerVisibility,
      setClickedPersonEvent,
      SET_TRAVEL_CORRELATION_ID,
      RESET_ITINERARY_DATA
    ]
  );

  const handleCurrentTravelInfo = useCallback(async (segmentId: string) => { 
    const res = await getCurrentTravelItenerary(segmentId);
    setPersonCurrentTravelInfo(res);
  }, []);

  return (
    <PeopleContext.Provider value={ useContextValue({
      setIsIndividualPersonPopupShown,
      individualPersonPopupCoordinates,
      setIndividualPersonPopupCoordinates,
      handlePersonLocationHover,
      handlePersonLocationLeave,
      hoveredOnPerson,
      enablePopupForRankingEvent,
      individualPersonPopupRef,
      handleMouseEnterPopup,
      handleMouseLeavePopup,
      handlePersonLocationClick,
      calculatePopupOffset,
      lineEndCoordinates,
      handleClickOutside,
      clickedPersonEvent,
      handleCurrentTravelInfo,
      personCurrentTravelInfo,
      popupPosition
    }) }>
      { children }
    </PeopleContext.Provider>
  );
};
