import { ActionIcon, Badge, Box, Flex, Group, Progress, SimpleGrid, Skeleton, Stack, Text } from '@mantine/core';
import { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from "@/common/icons/Actions/close.svg";
import { ReactComponent as Contract } from "@/common/icons/Actions/contract.svg";
import { ReactComponent as ExpandIcon } from "@/common/icons/Actions/expand.svg";
import { ReactComponent as Calendar } from "@/common/icons/date.svg";
import { ReactComponent as TrendingDown } from "@/common/icons/Indicators/trending-down.svg";
import { ReactComponent as TrendingUp } from "@/common/icons/Indicators/trending-up.svg";
import { ReactComponent as MaxSecurity } from "@/common/icons/RiskProviders/max-security.svg";
import { RootStateWithLoading } from '@/core/store';
import CountryFlag from '@/tenant-context/common/components/CountryFlag';
import {
  useDetailedCountryReportStyles
} from '@/tenant-context/control-reports/components/DetailedCountryReport/DetailedCountryReport.styles';
import ReportSectionComponent
  from '@/tenant-context/control-reports/components/DetailedCountryReport/ReportSection.component';
import { CountryRiskData } from '@/tenant-context/control-reports/types/country-risk-reports';
import {
  getRiskColorByRiskLevel, getRiskLevelValueByRiskLevel, kebabCaseToTitleCase,
  snakeCaseToTitleCase
} from '@/tenant-context/control-reports/util/country-risk-reports';
import {
  getIndicatorIcon,
  getInfoIcon,
  getSafetyCategoryIcon, getSubSectionIcon,
  getTravelSectionIcon
} from '@/tenant-context/control-reports/util/icons-maps';

type Props = {
  countryRiskData: CountryRiskData;
  onClose: () => void;
  onExpand: () => void;
  isExpanded: boolean;
};

const DetailedCountryReportComponent: FC<Props> = ({
  countryRiskData,
  onClose: handleClose,
  onExpand: handleExpand,
  isExpanded
}) => {
  const getColorByLevel = (level: string) => {
    return getRiskColorByRiskLevel(level);
  };
  const { classes, cx } = useDetailedCountryReportStyles( {
    riskLevelColor: getColorByLevel(countryRiskData.riskLevel.toLowerCase())
  } );

  const isCountryDataLoading = useSelector(
    (state: RootStateWithLoading) => state.loading.effects.countryRiskReports.loadSelectedCountyReport
  );

  const getInfoCategoryIcon = useCallback((infoKey: string) => {
    return getInfoIcon(infoKey);
  }, []);

  const indicatorIcon = useCallback((key: string) => {
    return getIndicatorIcon(key);
  }, []);

  const safetyCategoryIcon = useCallback((key: string) => {
    return getSafetyCategoryIcon(key);
  }, []);

  const travelIcon = useCallback((key: string) => {
    return getTravelSectionIcon(key);
  }, []);

  const subSectionIcon = useCallback((key: string) => {
    return getSubSectionIcon(key);
  }, []);

  const riskLevelValue = useMemo(() => {
    return getRiskLevelValueByRiskLevel(countryRiskData.riskLevel);
  }, [countryRiskData.riskLevel]);

  const providerName = useMemo(() => {
    return kebabCaseToTitleCase(countryRiskData.providerName);
  }, [countryRiskData.providerName]);

  const providerIcon = useMemo(() => {
    switch (countryRiskData.providerName?.toLowerCase()) {
    case 'max-security':
      return <MaxSecurity/>;
    default:
      return null;
    }
  }, [countryRiskData.providerName]);

  return (
    <Box className={ classes.root }>
      <Flex direction="column" className={ classes.header }>
        <Flex justify="space-between" align="center">
          <Flex gap={ 12 } direction="column">
            <Flex gap={ 12 } align="center">
              <Box className={ classes.flag }>
                <CountryFlag countryCode={ countryRiskData.countryISOCode } />
              </Box>
              <Text size={ 32 } weight={ 700 }>{ countryRiskData.countryName }</Text>
            </Flex>
            <Flex gap={ 4 }>
              <Text>Issued by:</Text>
              { providerIcon }
              <Text>{ providerName }</Text>
            </Flex>
          </Flex>

          <Flex>
            <ActionIcon className={ classes.actionIcon } onClick={ handleExpand }>
              { isExpanded ? <Contract/> : <ExpandIcon/> }
            </ActionIcon>
            <ActionIcon className={ classes.actionIcon } onClick={ handleClose }><CloseIcon/></ActionIcon>
          </Flex>
        </Flex>
      </Flex>

      { !isCountryDataLoading ? (
        <Flex direction="column" gap={ 32 } className={ cx({ [classes.body]: true, [classes.bodyExpanded]: isExpanded }) }>

          <Stack spacing="sm" className={ classes.riskLevelContainer }>
            <Flex justify="space-between">
              <Text weight={ 400 }>Risk Level</Text>
              <Text weight={ 400 } transform="capitalize">{ countryRiskData.riskLevel }</Text>
            </Flex>
            <Progress value={ riskLevelValue } color="red" size="lg" className={ classes.countryRiskLevel }/>
          </Stack>

          { countryRiskData.indicators && <Stack>
            <Text className={ classes.sectionHeading }>Indicators</Text>
            <SimpleGrid cols={ 4 } spacing={ 8 }>
              { Object.keys(countryRiskData.indicators).map((indicatorKey, index) => (
                <Badge
                  key={ index }
                  style={ { backgroundColor: getColorByLevel(countryRiskData.indicators ? countryRiskData.indicators[indicatorKey].level : '') } }
                  className={ classes.indicatorPill }
                >
                  <Flex align="center" gap={ 4 }>
                    { indicatorIcon(indicatorKey) }
                    { countryRiskData.indicators && countryRiskData.indicators[indicatorKey].forecast === 'deteriorating' && <TrendingUp /> }
                    { countryRiskData.indicators && countryRiskData.indicators[indicatorKey].forecast === 'improving' && <TrendingDown /> }
                  </Flex>
                  { snakeCaseToTitleCase(indicatorKey) }
                </Badge>
              )) }
            </SimpleGrid>
          </Stack> }

          { countryRiskData.generalInformation && <Stack>
            <Text className={ classes.sectionHeading }>General Information</Text>
            <Flex gap={ 8 } wrap="wrap">
              { Object.keys(countryRiskData.generalInformation).map((infoKey, index) => (
                <Group key={ index } spacing="sm" className={ classes.infoPill }>
                  { getInfoCategoryIcon(infoKey) }
                  <Text size="sm">
                    { countryRiskData.generalInformation && countryRiskData.generalInformation[infoKey].title }:
                  </Text>
                  <Text size="sm">{ countryRiskData.generalInformation && countryRiskData.generalInformation[infoKey].text }</Text>
                </Group>
              )) }
            </Flex>
          </Stack> }

          { countryRiskData.eventCalendar && <Stack>
            <Text className={ classes.sectionHeading }>Event Calendar</Text>
            <Stack spacing="xs">
              { countryRiskData.eventCalendar?.map((event, index) => (
                <Group key={ index } spacing="sm" className={ classes.eventPill }>
                  <Calendar/>
                  <Text size="sm">{ event.eventDetails }</Text>
                </Group>
              )) }
            </Stack>
          </Stack> }

          { countryRiskData.overview && <Stack>
            <Text className={ classes.sectionHeading }>Overview</Text>
            <div className={ classes.text } dangerouslySetInnerHTML={ { __html: countryRiskData.overview } } />
          </Stack> }

          <ReportSectionComponent
            title={ 'Safety and security' }
            reportData={ countryRiskData.safetySecurity }
            getSubsectionIcon={ safetyCategoryIcon }
          />

          <ReportSectionComponent
            title={ 'Travel' }
            reportData={ countryRiskData.travel }
            getSubsectionIcon={ travelIcon }
          />

          <ReportSectionComponent
            title={ 'Infrastructure' }
            reportData={ countryRiskData.infrastructure }
            getSubsectionIcon={ subSectionIcon }
            isLevelShownAsRating
          />

          <ReportSectionComponent
            title={ 'Local considerations' }
            reportData={ countryRiskData.localConsiderations }
            getSubsectionIcon={ subSectionIcon }
          />

          { countryRiskData.dosAndDont && <Stack spacing="md">
            <Text className={ classes.reportSectionHeading }>{ countryRiskData.dosAndDont?.title }</Text>
            <Flex gap="lg" wrap="wrap">
              <Stack spacing="md">
                <div
                  className={ classes.content }
                  dangerouslySetInnerHTML={ { __html: countryRiskData.dosAndDont.text } }
                />
              </Stack>
            </Flex>
          </Stack> }

          { countryRiskData.cities && <Stack spacing="md">
            <Text className={ classes.reportSectionHeading }>Cities</Text>
            <Flex gap="lg" wrap="wrap">
              { countryRiskData.cities?.map((city, index) => (
                <Stack key={ index } spacing="md">
                  <Text className={ classes.subSectionHeading }>{ city.name }</Text>
                  <Box className={ classes.content }>
                    { city.description }
                  </Box>
                </Stack>
              )) }
            </Flex>
          </Stack> }

          { countryRiskData.geographicalFeature && <Stack spacing="md">
            <Text className={ classes.reportSectionHeading }>Geographical Features</Text>
            <Flex gap="lg" wrap="wrap">
              <div
                className={ classes.content }
                dangerouslySetInnerHTML={ { __html: countryRiskData.geographicalFeature.text } }
              />
            </Flex>
          </Stack> }

        </Flex>
      ) : (
        <Stack spacing="md" className={ classes.loadingContainer }>
          { Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={ index } height={ 100 } radius="sm" opacity={ 0.1 }/>
          )) }
        </Stack>
      ) }
    </Box>
  );
};

export default DetailedCountryReportComponent;
