import { createStyles } from "@mantine/core";
type Params = {
  imgUrl?: string;
};
export const useMapStyleToggleStyles = createStyles((theme, params: Params ) => ({
  hiddenInput: {
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',
    height: 0,
    width: 0,
    '&:checked + span': {
      outline: `4px solid ${theme.colors.blue[6]}`,
      outlineOffset: '-4px'
    }
  },
  label: {
    width: 'max-content',
    display: 'inline-flex',
    justifyContent: 'start',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: theme.spacing.md,
    cursor: 'pointer'
  },
  mapPreview: {
    backgroundImage: params.imgUrl && `url("${params.imgUrl}")`,
    backgroundColor: theme.colors.neutral[7],
    width: '48px',
    height: '48px',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.neutral[6]}`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative'
  },
  listItemText: {
    display: 'block',
    width: '100%',
    fontSize: theme.fontSizes.sm,
    fontWeight: theme.other.fw.regular
  }
}));
