import { Divider } from "@mantine/core";
import { FC } from "react";

import LineClamp from '@/common/components/LineClamp';
import { ReactComponent as DataMinr } from "@/common/icons/dataminr.svg";
import { getTimeDurationFromCurrentDate } from "@/common/util/format/date";
import CountryFlag from "@/tenant-context/common/components/CountryFlag";
import RiskAlertBasicData from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertBasicData";
import { DataMinrExternalData } from "@/tenant-context/visualisation-risk-alerts/types/data-minr.types";
import { BSOCExternalData } from "@/tenant-context/visualisation-risk-alerts/types/risk-alert-response";
import {
  RiskAlert,
  RiskAlertEvent,
  RiskAlertEventMeta, RiskAlertExternalData,
  RiskAlertJsonMeta
} from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";

type Props = {
  classes: Record<string, string>,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
  meta: RiskAlertEventMeta,
  json: { meta: RiskAlertJsonMeta; alert: RiskAlert; externalData?: DataMinrExternalData },
  riskAlertData: RiskAlertEvent<DataMinrExternalData>,
  isImpacted: boolean
}

export const DataMinrPopupContent: FC<Props> = ({
  classes,
  onMouseEnter,
  onMouseLeave,
  meta,
  json,
  riskAlertData,
  isImpacted: _isImpacted
}) => {
  const mappedRiskAlertData: RiskAlertEvent<RiskAlertExternalData | BSOCExternalData | DataMinrExternalData> = {
    ...riskAlertData,
    json: {
      ...riskAlertData.json,
      alert: {
        ...riskAlertData.json.alert,
        start_date: new Date(riskAlertData?.json.alert.start_date).toLocaleDateString(),
        end_date: new Date(riskAlertData?.json.alert.end_date).toLocaleDateString()
      }
    }
  };

  return <>
    <div className={ classes.popupContainer }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
    >
      <div className={ classes.timesAgoText }>
        { (meta.createdTime > 0)
          ? getTimeDurationFromCurrentDate(new Date(meta.createdTime))
          : "" }
      </div>
      <div className={ classes.popupHeader }>
        { json.alert.countries[0].iso_code && (
          <CountryFlag
            width={ 65 }
            height={ 50 }
            countryCode={ json.alert.countries[0].iso_code }
          />
        ) }
        { json.externalData?.eventLocation?.name }
      </div>
      <div className={ classes.riskAlertTitle }>
        <LineClamp numberOfLines={ 2 }>
          { json.alert.title }
        </LineClamp>
      </div>
      <RiskAlertBasicData riskAlertData={ mappedRiskAlertData }/>

      <Divider
        className={ classes.divider }
      />
      <div className={ classes.riskAlertProvider }>
        <div className={ classes.issuedBy }>Issued by</div>
        <DataMinr className={ classes.riskAlerProviderIcon }/>
        <div className={ classes.riskAlertProviderName }> Dataminr</div>
      </div>
    </div>

    { /*{ isImpacted &&*/ }

    { /*  <div className={ classes.responseStatusContainer }>*/ }
    { /*    <div className={ classes.responseBox }>*/ }
    { /*      <div className={ classes.responseProgress }> Response in progress</div>*/ }
    { /*      <div className={ classes.responseMeta }>*/ }
    { /*        <div>*/ }
    { /*          <div className={ classes.responseMetaTxt }>Last Modified :</div>*/ }
    { /*          <div className={ classes.responseMetaValue }>*/ }
    { /*            { meta.updatedTime !== undefined && meta.updatedTime > 0*/ }
    { /*              ? formatDate(new Date(meta.updatedTime))*/ }
    { /*              : formatDate(new Date()) }*/ }
    { /*          </div>*/ }
    { /*        </div>*/ }
    { /*        <div>*/ }
    { /*          <div className={ classes.responseMetaTxt }>Case ID</div>*/ }
    { /*          <div className={ classes.responseMetaValue }>*/ }
    { /*            uiJ23bnXC*/ }
    { /*          </div>*/ }
    { /*        </div>*/ }
    { /*      </div>*/ }

    { /*    </div>*/ }
    { /*  </div> }*/ }
  </>;
};
