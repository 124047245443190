import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { createContext, FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import usePermission from "@/common/hooks/usePermission";
import { loadGlobalFontAwesomeIcons } from "@/common/icons/FontAwesome";
import { getURLParam } from "@/common/util/browser";
import { logger } from "@/common/util/ConsoleLogger";
import { CorePolicies } from "@/core/config/CorePolicies.config";
import { ENV, Environment } from '@/core/config/env';
import {
  REPLAYS_ON_ERROR_SAMPLE_RATE,
  REPLAYS_SESSION_SAMPLE_RATE,
  TRACE_PROPAGATION_TARGETS,
  TRACES_SAMPLE_RATE
} from '@/core/config/Sentry.config';
import { Dispatch, RootState } from "@/core/store";
import { useAuthContext } from "@/tenant-context/core/context/Auth.context";

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

if (SENTRY_DSN && ENV === Environment.Qa) {
  try {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: TRACE_PROPAGATION_TARGETS
        }),
        new Sentry.Replay(),
        new CaptureConsole()
      ],
      tracesSampleRate: TRACES_SAMPLE_RATE,
      replaysSessionSampleRate: REPLAYS_SESSION_SAMPLE_RATE,
      replaysOnErrorSampleRate: REPLAYS_ON_ERROR_SAMPLE_RATE
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Sentry && Sentry.getCurrentHub().getClient()?._dsn) {
      logger.log('Sentry has been initialized!');
    } else {
      logger.log('Sentry has not been initialized.');
    }

  } catch (e) {
    logger.error(e);
  }
} else {
  logger.log('Sentry has not been initialized.');
  if (!SENTRY_DSN) {
    logger.error('SENTRY_DSN not found');
  }
}

export const AppInitContext = createContext({});

export const AppInitContextProvider: FC = ({
  children
}) => {
  const {
    commonData: {
      loadAllCountries,
      loadAllRiskProviders,
      loadAllLocations,
      setTenant,
      loadLegacyModeCheck,
      loadTenantSubscribedProducts
    }
  } = useDispatch<Dispatch>();

  const {
    isAuthenticated
  } = useAuthContext();

  const isConnectorPermissionAvailable = usePermission(CorePolicies.CONNECTOR_PROVIDER_POLICY);
  const isLocationPermissionAvailable = usePermission(CorePolicies.GLOBAL_LOCATION_PERMISSIONS);
  const userInfo = useSelector((state: RootState) => state.profile.loggedUser);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    // initializeDataDogLogs(); // Disabled temporarily due to a request 
    const tenantIdParam = getURLParam('tenantId');

    if (tenantIdParam) {
      setTenant({
        tenantId: tenantIdParam,
        refreshBrowser: false
      });
    }
    loadTenantSubscribedProducts();
    loadAllCountries();

    if (isLocationPermissionAvailable){
      loadAllLocations();
    }

    if (isConnectorPermissionAvailable) {
      loadAllRiskProviders();
      loadLegacyModeCheck();
    }
  }, [ isAuthenticated, loadAllCountries, loadAllRiskProviders, loadAllLocations, setTenant, loadLegacyModeCheck,
    isConnectorPermissionAvailable, loadTenantSubscribedProducts, isLocationPermissionAvailable]);

  // Load global font awesome icons
  useEffect(() => {
    loadGlobalFontAwesomeIcons();
  }, []);

  useEffect(()=>{
    if(!isAuthenticated || !userInfo){
      return;
    }

    const { assignedTenantUserTid } = userInfo;

    if(assignedTenantUserTid){
      Sentry.setUser({
        id: assignedTenantUserTid
      });
    }
  }, [isAuthenticated, userInfo]);

  return (
    <AppInitContext.Provider value={ 1337 }>
      { children }
    </AppInitContext.Provider>
  );
};
