import { FC, useCallback, useContext } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { Dispatch, RootState } from "@/core/store";
import EditFloorFormComponent
  from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/components/EditFloorForm/EditFloorForm.component";
import {
  LocationInfoContext
} from "@/tenant-context/control-location-configuration/context/LocationInfo.context";
import {
  CreateFloorRequest
} from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

type Props = {
  setIsExpanded: (isExpanded: boolean) => void
}

const EditFloorForm: FC<Props> = ({
  setIsExpanded
}) => {

  const { manageLocations: {
    postCreateFloor,
    SET_ACTIVE_FLOOR
  } } = useDispatch<Dispatch>();

  const { isAuthorizedToEdit } = useContext(LocationInfoContext);

  const activeFloor = useSelector((state: RootState) => state.manageLocations?.activeFloor);

  const formControls = useForm({ mode: 'onChange' });

  const handleFormSubmit = useCallback((data: FieldValues) => {
    postCreateFloor({
      floor: data as CreateFloorRequest,
      floorId: activeFloor?.floorTid || ''
    });
  }, [activeFloor, postCreateFloor]);


  const handleFormClear = useCallback(() => {
    formControls.reset();
    setIsExpanded(false);
    SET_ACTIVE_FLOOR(undefined);
  }, [SET_ACTIVE_FLOOR, formControls, setIsExpanded]);

  return (
    <EditFloorFormComponent
      onSubmit={ handleFormSubmit }
      formControls={ formControls }
      onFormClear={ handleFormClear }
      activeFloor={ activeFloor }
      isAuthorizedToEdit={ isAuthorizedToEdit }
    />
  );
};

export default EditFloorForm;
