import bbox from "@turf/bbox";
import { lineString } from "@turf/helpers";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FieldValues, useForm, useWatch } from "react-hook-form";
import { useMap } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { showNotification } from "@/common/util/notification";
import { Dispatch, RootState } from "@/core/store";
import { LocationManagementContext } from "@/tenant-context/control-location-configuration/context/LocationManagement.context";
import { AccessControlMode, GeoPoint, LocationZone, LocationZoneFormOptionalFields, MusterZonePositions, ReleaseBehaviour, ZoneTypes } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

import { LocationInfoContext } from "../../../../context/LocationInfo.context";
import LocationZoneFormComponent from "./LocationZoneForm.component";

interface Props {
  toggleLocationZoneListView: () => void
}

const LocationZoneForm: FC<Props> = ({ toggleLocationZoneListView }) => {

  const [fieldsVisibility, setFieldsVisibility] = useState<LocationZoneFormOptionalFields>({
    Floor: false,
    MaxCapacity: false,
    MusterZonePosition: false,
    NormalZone: false,
    ReleaseBehaviour: false
  });

  const [mappedNormalZones, setMappedNormalZones] = useState<string[]>([]);
  const [excludedPeopleGroups, setExcludedPeopleGroups] = useState<string[]>([]);

  const { register, control, handleSubmit, formState: { errors }, setValue, setError, getValues } = useForm();

  const buildingTid = useWatch({
    control,
    name: 'buildingTid'
  });
  const floorTid = useWatch({
    control,
    name: 'floorTid'
  });
  const zoneType = useWatch({
    control,
    name: 'zoneType'
  });
  const maxCapacityEnabled = useWatch({
    control,
    name: 'maxCapacityEnabled'
  });
  const musterPosition = useWatch({
    control,
    name: 'musterPosition'
  });

  const {
    locationCategory,
    isAuthorizedToEdit
  } = useContext(LocationInfoContext);

  const {
    setMarkerLocationExternally
  } = useContext(LocationManagementContext);

  const {
    manageLocations: {
      initializeAddLocationZone,
      saveLocationZone,
      CLEAR_SELECTED_LOCATION_ZONE,
      SET_CURRENT_SITE_PLAN_GEO_JSON,
      getFloorsList,
      getFloor,
      SET_ACTIVE_BUILDING,
      SET_ACTIVE_FLOOR
    }
  } = useDispatch<Dispatch>();

  const { enteredLocationDetails, locationZoneReferenceData, selectedLocationZone,
    locationZonesData, profileGroupsData, locationBuildingData, floorListData, activeMarkerLocation,
    sitePlanList, activeFloor, activeBuilding }
   =  useSelector((state: RootState) => state.manageLocations);

  const { AddLocationMap: map } = useMap();

  const handleLocationZoneSubmit = useCallback(async (formValues: FieldValues)=>{

    if(!isAuthorizedToEdit){
      return;
    }

    const clonedValues = { ...formValues };

    if(selectedLocationZone){
      clonedValues.id = selectedLocationZone.id;
    }

    clonedValues.subCategoryName = locationCategory;
    clonedValues.locationId = enteredLocationDetails.tid;
    clonedValues.mappedNormalZones = mappedNormalZones;
    clonedValues.peopleGroups = excludedPeopleGroups;

    if(!clonedValues.maxCapacityEnabled){
      clonedValues.maxCapacity = "";
    }

    if(clonedValues.zoneType === ZoneTypes.NormalZone){
      clonedValues.releaseBehavior = "";
      clonedValues.musterPosition = "";
    }

    if(clonedValues.musterPosition === MusterZonePositions.Outside){
      clonedValues.musterPlacedZone = "";
      clonedValues.releaseBehavior = "";
    }

    if(!clonedValues.buildingTid){
      clonedValues.floorTid = "";
    }

    //validate if building has floors
    if(clonedValues.buildingTid){
      const isFloorAvailableForBuilding = floorListData?.items
        .some(floor => floor.buildingId === clonedValues.buildingId);

      if(!isFloorAvailableForBuilding){
        setValue("floorTid", "");
        setError("floorTid", {
          message: "Floor is required"
        });

        return;
      }
    }
    
    const success = await saveLocationZone(clonedValues as LocationZone);

    if(success){
      showNotification({
        message: `Record ${selectedLocationZone ? "updated" : "added"} successfully`,
        color: 'success',
        title: "Success"
      });
      toggleLocationZoneListView();
    }
  }, [enteredLocationDetails.tid, excludedPeopleGroups,
    floorListData?.items, isAuthorizedToEdit, locationCategory,
    mappedNormalZones, saveLocationZone, selectedLocationZone,
    setError, setValue, toggleLocationZoneListView]);

  const handleFormFieldsVisibility = useCallback((type: keyof LocationZoneFormOptionalFields, value: boolean)=>{
    setFieldsVisibility((fields) => ({
      ...fields,
      [type]: value
    }));
  }, []);

  const handleMappedNormalZones = useCallback((value: string)=>{
    setMappedNormalZones(zones => [...zones, value]);
  }, []);

  const handleExcludedPeopleGroups = useCallback((value: string)=>{
    setExcludedPeopleGroups(groups => [...groups, value]);
  }, []);

  const handleMappedNormalZoneRemoval = useCallback((removedItem: number) => {
    setMappedNormalZones(zones => zones.filter((_, index) => index !== removedItem));
  }, []);

  const handleExPeopleGroupRemoval = useCallback((removedItem: number) => {
    setExcludedPeopleGroups(groups => groups.filter((_, index) => index !== removedItem));
  }, []);

  const handleMapPlotOfLocation = useCallback(async ()=>{
    // eslint-disable-next-line fp/no-let
    let plottingGeoJson = "";
    if(activeFloor && activeFloor.geoJson){
      plottingGeoJson = activeFloor.geoJson.geoJson;
    }

    if(!plottingGeoJson && sitePlanList.length > 0){
      plottingGeoJson = sitePlanList[0]?.geoJson;
    }

    if(plottingGeoJson){
      const parsedGeoJson = JSON.parse(plottingGeoJson);
      const line = lineString(parsedGeoJson?.geometry?.coordinates[0]);
      const bbox_ = bbox(line);
      if (map && bbox_) {
        map?.fitBounds(bbox_ as [number, number, number, number], { padding: 100 });
      }
      SET_CURRENT_SITE_PLAN_GEO_JSON(parsedGeoJson);

    }
  }, [SET_CURRENT_SITE_PLAN_GEO_JSON, activeFloor, map, sitePlanList]);

  const handleLatitudeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isNaN(parseFloat(event.currentTarget.value))) {
        setMarkerLocationExternally(Number(event.currentTarget.value), Number(getValues("lon")));
      }
    },
    [getValues, setMarkerLocationExternally]
  );

  const handleLongitudeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isNaN(parseFloat(event.currentTarget.value))) {
        setMarkerLocationExternally(Number(getValues("lat")), Number(event.currentTarget.value));
      }
    },
    [getValues, setMarkerLocationExternally]
  );

  const allNormalZones = useMemo(()=>{
    return (locationZonesData?.items || []).map((zone) => ({
      label: zone.name,
      value: zone.tid
    }));
  }, [locationZonesData?.items]);

  const allProfileGroups = useMemo(()=>{
    return (profileGroupsData || []).map((group) => ({
      label: group.name,
      value: group._id
    }));
  }, [profileGroupsData]);

  const filteredProfileGroups = useMemo(()=>{
    return (allProfileGroups.filter((group) => !excludedPeopleGroups.includes(group.value)));
  }, [allProfileGroups, excludedPeopleGroups]);

  const allBuildingData = useMemo(()=>{
    return (locationBuildingData?.items || []).map((building) => ({
      label: building.name,
      value: building.buildingTid
    }));
  }, [locationBuildingData]);

  const allBuildingFloorsData = useMemo(()=>{
    return (floorListData?.items || []).map((floor) => ({
      label: floor.name,
      value: floor.floorTid
    }));
  }, [floorListData?.items]);

  const selectedMappedNormalZones = useMemo(()=> {
    return allNormalZones.filter(zone => mappedNormalZones.includes(zone.value));
  }, [allNormalZones, mappedNormalZones]);

  const selectedExcludedPeopleGroups = useMemo(()=> {
    return allProfileGroups.filter(group => excludedPeopleGroups.includes(group.value));
  }, [allProfileGroups, excludedPeopleGroups]);

  useEffect(()=>{
    initializeAddLocationZone();

    if(selectedLocationZone){
      const { lat, lon } = (selectedLocationZone.geoPoint as GeoPoint);
      setMappedNormalZones(selectedLocationZone.mappedNormalZones as string[] || []);
      setExcludedPeopleGroups(selectedLocationZone.peopleGroups as string[] || []);

      setMarkerLocationExternally(lat, lon);

      if (map) {
        map?.flyTo({ center: [lon, lat], zoom: 15 });
      }

      setValue("lat", lat);
      setValue("lon", lon);
      setValue("buildingTid", selectedLocationZone.buildingTid);
      setValue("floorTid", selectedLocationZone.floorTid);

      setValue("name", selectedLocationZone.name);
      setValue("code", selectedLocationZone.code);
      setValue("zoneType", selectedLocationZone.zoneType);
      setValue("maxCapacityEnabled", selectedLocationZone.maxCapacityEnabled);
      setValue("maxCapacity", selectedLocationZone.maxCapacity);
      setValue("accessControlMode", selectedLocationZone.accessControlMode);
      setValue("musterPosition", selectedLocationZone.musterPosition || MusterZonePositions.Inside);
      setValue("musterPlacedZone", selectedLocationZone.musterPlacedZone);
      setValue("releaseBehavior", selectedLocationZone.releaseBehavior || ReleaseBehaviour.Retain);

    } else{
    //default vals
      setValue("maxCapacityEnabled", false);
      setValue("zoneType", ZoneTypes.NormalZone);
      setValue("musterPosition",  MusterZonePositions.Inside);
      setValue("releaseBehavior", ReleaseBehaviour.Retain);
      setValue("accessControlMode", AccessControlMode.Permitted);
    }

    return(()=>{
      CLEAR_SELECTED_LOCATION_ZONE();
      SET_ACTIVE_BUILDING(undefined);
      SET_ACTIVE_FLOOR(undefined);
      setMarkerLocationExternally(0, 0);
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{

    const isMusterZoneType = zoneType === ZoneTypes.MusterZone;

    handleFormFieldsVisibility("Floor", !!buildingTid);
    handleFormFieldsVisibility("MaxCapacity", maxCapacityEnabled);
    handleFormFieldsVisibility("MusterZonePosition", isMusterZoneType);
    handleFormFieldsVisibility("NormalZone", (musterPosition === MusterZonePositions.Inside) && isMusterZoneType);
    handleFormFieldsVisibility("ReleaseBehaviour", (musterPosition === MusterZonePositions.Inside) && isMusterZoneType);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingTid, zoneType, maxCapacityEnabled, musterPosition]);

  useEffect(()=>{
    if(activeMarkerLocation && isAuthorizedToEdit){
      setValue("lat", (activeMarkerLocation.lat));
      setValue("lon", (activeMarkerLocation.lng));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMarkerLocation?.lat, activeMarkerLocation?.lng, isAuthorizedToEdit]);

  useEffect(()=>{
    handleMapPlotOfLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitePlanList, activeFloor]);

  useEffect(()=>{
    if(buildingTid){
      const selectedBuilding = locationBuildingData?.items?.find(building => building.buildingTid === buildingTid);
      if(selectedBuilding){
        SET_ACTIVE_BUILDING(selectedBuilding);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingTid, locationBuildingData]);

  useEffect(()=>{
    if(activeBuilding){
      getFloorsList();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBuilding]);

  useEffect(()=>{
    if(floorTid){
      getFloor(floorTid);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorTid]);

  return <LocationZoneFormComponent
    locationZoneReferenceData={ locationZoneReferenceData }
    register = { register }
    handleSubmit = { handleSubmit }
    errors = { errors }
    control={ control }
    onLocationZoneSubmit = { handleLocationZoneSubmit }
    fieldsVisibility = { fieldsVisibility }
    allNormalZones={ allNormalZones }
    allProfileGroups = { filteredProfileGroups }
    allBuildingData = { allBuildingData }
    allBuildingFloorsData = { allBuildingFloorsData }
    mappedNormalZones = { selectedMappedNormalZones }
    onMappedNormalZones = { handleMappedNormalZones }
    excludedPeopleGroups ={ selectedExcludedPeopleGroups }
    onExcludedPeopleGroups = { handleExcludedPeopleGroups }
    onMappedNormalZoneRemoval={ handleMappedNormalZoneRemoval }
    onExPeopleGroupRemoval={ handleExPeopleGroupRemoval }
    isAuthorizedToEdit = { isAuthorizedToEdit }
    selectedLocationZone = { selectedLocationZone }
    onLatitudeChange= { handleLatitudeChange }
    onLongitudeChange={ handleLongitudeChange }
  />;
};

export default LocationZoneForm;
