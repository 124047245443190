import { callApi } from '@/common/util/api';
import { CountryRiskData, CountryRiskProvider } from '@/tenant-context/control-reports/types/country-risk-reports';

export const getCountryRiskReports = (provider: string, country?: string) => callApi<CountryRiskData[]>(
  `/report-service/v1/country-risk-content?providerName=${provider.toUpperCase()}&pageSize=10000&pageNum=0&mergeNonTenant=false${country ? `&query=json.country='${country}'`: ''}&orderBy=json.country:ASC`,
  {
    method: 'get'
  }
);
export const getCountryRiskReportsSummery = (provider: string) => callApi<CountryRiskData[]>(
  `/report-service/v1/country-risk-content/summery?providerName=${provider.toUpperCase()}&pageSize=10000&pageNum=0&mergeNonTenant=false&orderBy=json.country:ASC`,
  {
    method: 'get'
  }
);
export const getCountryRiskProvidersList = () => callApi<CountryRiskProvider[]>(
  `/report-service/v1/country-risk-content/provider?pageSize=0&pageNum=0&mergeNonTenant=false&contentProviderType=risk`,
  {
    method: 'get'
  }
);
