import { FC } from "react";

import { ReactComponent as ChevronDownIcon } from '@/common/icons/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from '@/common/icons/chevron-up.svg';
import { ReactComponent as TrashIcon } from "@/common/icons/delete-bin.svg";
import {
  useBuildingsAndFloorsStyles
} from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/BuildingsAndFloorsTab.styles";
import EditFloor
  from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/components/EditFloor";
import { FloorListItem } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

type Props = {
  floor: FloorListItem
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
  handleDeleteFloor: (e: React.MouseEvent, floor: FloorListItem) => void
  handleFloorClick: () => void
}

const FloorCellComponent: FC<Props> = ({
  floor,
  isExpanded,
  setIsExpanded,
  handleDeleteFloor,
  handleFloorClick
}) => {

  const { classes } = useBuildingsAndFloorsStyles();

  return (
    <div className={ classes.floorCellContainer }>
      <div className={ classes.floorCellHeader }>
        <button onClick={ handleFloorClick } className={ classes.floorCellHeaderButton }>
          <div className={ classes.floorCellInnerContainer }>
            <div className={ classes.floorName }>{ floor.name }</div>
            <div className={ classes.floorName }>{ floor.code }</div>
            <div className={ classes.floorName }>{ floor.zones }</div>
            <div className={ classes.actionButtonContainer }>
              { floor.floorTid &&
                // eslint-disable-next-line react/jsx-no-bind
                <TrashIcon onClick={ (e) => handleDeleteFloor(e, floor) } className={ classes.trash }/> }
              { isExpanded ? <ChevronUpIcon/> : <ChevronDownIcon/> }
            </div>
          </div>
        </button>
      </div>
      { isExpanded && <EditFloor setIsExpanded={ setIsExpanded }/> }
    </div>
  );
};

export default FloorCellComponent;
