import { Flex } from '@mantine/core';
import { FC } from 'react';

import { usePageStyles } from '@/tenant-context/common/components/Page/Page.styles';

type Props = {
  gap?: number;
}

const Page: FC<Props> = ({ children, gap }) => {

  const { classes } = usePageStyles();

  return (
    <Flex direction="column" className={ classes.bodyRoot } gap={ gap }>
      { children }
    </Flex>
  );
};

export default Page;
