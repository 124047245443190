import { createModel } from '@rematch/core';

import { logger } from '@/common/util/ConsoleLogger';
import { Dispatch, RootModel, RootState } from '@/core/store';
import {
  getCountryRiskProvidersList,
  getCountryRiskReports,
  getCountryRiskReportsSummery
} from '@/tenant-context/control-reports/api/country-risk-reports';
import { CountryRiskData, CountryRiskProvider } from '@/tenant-context/control-reports/types/country-risk-reports';


export type CountryRiskReports = {
  countryRiskDataReports: CountryRiskData[];
  countriesList: string[];
  selectedRiskProvider?: CountryRiskProvider;
  selectedDetailedCountryReport?: CountryRiskData;
  countryRiskProvidersList: CountryRiskProvider[];
}

const countryRiskReportsDefaultState = {
  countryRiskDataReports: [],
  countriesList: [],
  selectedRiskProvider: undefined,
  selectedDetailedCountryReport: undefined,
  countryRiskProvidersList: []
} as CountryRiskReports;

const countryRiskReportsState = {
  name: 'countryRiskReports',
  state: countryRiskReportsDefaultState,
  reducers: {
    SET_COUNTRY_RISK_DATA_REPORTS(state: CountryRiskReports, countryRiskDataReports: CountryRiskData[]) {
      return {
        ...state,
        countryRiskDataReports
      };
    },
    SET_COUNTRIES_LIST(state: CountryRiskReports, countriesList: string[]) {
      return {
        ...state,
        countriesList
      };
    },
    SET_SELECTED_RISK_PROVIDER(state: CountryRiskReports, selectedRiskProvider?: CountryRiskProvider) {
      return {
        ...state,
        selectedRiskProvider
      };
    },
    SET_SELECTED_DETAILED_COUNTRY_REPORT(state: CountryRiskReports, selectedDetailedCountryReport?: CountryRiskData) {
      return {
        ...state,
        selectedDetailedCountryReport
      };
    },
    SET_COUNTRY_RISK_PROVIDERS_LIST(state: CountryRiskReports, countryRiskProvidersList: CountryRiskProvider[]) {
      return {
        ...state,
        countryRiskProvidersList
      };
    },
    RESET_COUNTRY_RISK_REPORTS(_: CountryRiskReports) {
      return countryRiskReportsDefaultState;
    }
  },
  effects: (dispatch: Dispatch) => ({
    async fetchCountryRiskProvidersList(_: void, _state: RootState): Promise<void> {
      try {
        const countryRiskProvidersList = await getCountryRiskProvidersList();
        dispatch.countryRiskReports.SET_COUNTRY_RISK_PROVIDERS_LIST(countryRiskProvidersList);
      } catch (error) {
        logger.error('Error fetching country risk providers list', error);
        dispatch.countryRiskReports.SET_COUNTRY_RISK_PROVIDERS_LIST([]);
      }
    },
    async fetchCountryRiskDataReports(riskProvider: CountryRiskProvider, state: RootState): Promise<void> {
      const { selectedRiskProvider } = state.countryRiskReports;

      if (!(selectedRiskProvider || riskProvider)) {
        return;
      }

      try {
        const riskProviderForQuery = riskProvider || selectedRiskProvider;
        const countryRiskDataReports = await getCountryRiskReportsSummery(riskProviderForQuery.providerName);
        const extractedCountryList = countryRiskDataReports.map(
          (countryRiskData) => {
            return countryRiskData.countryName;
          }
        );

        dispatch.countryRiskReports.SET_COUNTRY_RISK_DATA_REPORTS(countryRiskDataReports);
        dispatch.countryRiskReports.SET_COUNTRIES_LIST(extractedCountryList);
      } catch (error) {
        logger.error('Error fetching country risk data reports', error);
        dispatch.countryRiskReports.SET_COUNTRY_RISK_DATA_REPORTS([]);
      }
    },
    async loadSelectedCountyReport(countrySummery: CountryRiskData, state: RootState): Promise<void> {
      const { selectedRiskProvider } = state.countryRiskReports;
      dispatch.countryRiskReports.SET_SELECTED_DETAILED_COUNTRY_REPORT(countrySummery);

      if (!selectedRiskProvider) {
        return;
      }

      try {
        const countryRiskDataReports = await getCountryRiskReports(
          selectedRiskProvider.providerName,
          countrySummery.countryName
        );

        if (!countryRiskDataReports.length || !countryRiskDataReports[0]) {
          return;
        }
        dispatch.countryRiskReports.SET_SELECTED_DETAILED_COUNTRY_REPORT({
          ...countryRiskDataReports[0],
          providerName: selectedRiskProvider.providerName
        });
      } catch (error) {
        logger.error('Error fetching country risk data reports', error);
        dispatch.countryRiskReports.SET_SELECTED_DETAILED_COUNTRY_REPORT(undefined);
      }
    }
  })
};

export const countryRiskReports = createModel<RootModel>()(
  countryRiskReportsState
);
