import { Box, Flex, Progress, Text } from '@mantine/core';
import { FC, useCallback, useMemo } from 'react';

import CountryFlag from '@/tenant-context/common/components/CountryFlag';
import {
  useCountryRiskStatusCardStyles
} from '@/tenant-context/control-reports/components/CountryRiskStatusCard/CountryRiskStatusCard.styles';
import { CountryRiskData } from '@/tenant-context/control-reports/types/country-risk-reports';
import {
  getRiskColorByRiskLevel,
  getRiskLevelValueByRiskLevel
} from '@/tenant-context/control-reports/util/country-risk-reports';

type Props = {
  countryRiskData: CountryRiskData;
  onClick?: (countryRiskData: CountryRiskData) => void;
  currentTimestamp?: number;
}

const {
  REACT_APP_MAPBOX_ACCESS_TOKEN
} = process.env as {
  REACT_APP_MAPBOX_ACCESS_TOKEN?: string
};
const CountryRiskStatusCardComponent: FC<Props> = ({
  countryRiskData,
  onClick,
  currentTimestamp
}) => {
  const getProgressColor = useMemo(() => {
    return getRiskColorByRiskLevel(countryRiskData.riskLevel);
  }, [countryRiskData.riskLevel]);

  const timeZone = useMemo(() => {
    if (!countryRiskData.localTime || (countryRiskData.localTime === "Not Available")) {
      return undefined;
    }
    const timeZoneRegex = /\b[A-Z]{3,4}\b$/;
    const match = countryRiskData.localTime.match(timeZoneRegex);

    return match ? match[0] : undefined;
  }, [countryRiskData.localTime]);

  const time = useMemo(() => {
    if (!currentTimestamp || !timeZone) {
      return undefined;
    }

    const date = new Date(currentTimestamp);
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      hour: '2-digit',
      minute: '2-digit',
      hour12: false  // 24-hour format
    };

    try {
      return new Intl.DateTimeFormat('en-US', options).format(date);
    } catch (error) {
      return undefined;
    }
  }, [currentTimestamp, timeZone]);

  const { classes } = useCountryRiskStatusCardStyles({ progressColor: getProgressColor });

  const handleCardClick = useCallback(() => onClick && onClick(countryRiskData), [ onClick, countryRiskData ]);

  const mapImageLink = useMemo(() => {
    const bbox = (countryRiskData.coordinates && countryRiskData.coordinates.length === 4) ?
      `[${ countryRiskData.coordinates.join(',') }]`: '[-180,-85,180,85]';

    const style = 'smehmood/cl4feh10w000114pyksvp6mu9';
    return `https://api.mapbox.com/styles/v1/${ style }/static/${ bbox }/800x300?` +
      `padding=50,10,20&access_token=${ REACT_APP_MAPBOX_ACCESS_TOKEN }`;
  }, [countryRiskData.coordinates]);

  const riskLevel = useMemo(() => {
    return getRiskLevelValueByRiskLevel(countryRiskData.riskLevel);
  }, [countryRiskData.riskLevel]);

  return (
    <Flex gap={ 12 } className={ classes.cardRoot } direction="column" onClick={ handleCardClick }>
      <Flex justify="space-between" align="center">
        <Flex gap={ 12 }>
          <Box className={ classes.flag }>
            <CountryFlag
              countryCode={ countryRiskData.countryISOCode }
            />
          </Box>
          <Text size={ 14 } weight={ 700 }>{ countryRiskData.countryName }</Text>
        </Flex>
        { time && <Text size={ 12 } weight={ 400 }>Local time: { time }</Text> }
      </Flex>
      <img src={ mapImageLink } alt="Country flag" className={ classes.mapImage }/>
      <Flex className={ classes.progressBarContainer } direction="column">
        <Text size={ 12 } transform="capitalize">{ countryRiskData.riskLevel }</Text>
        <Progress value={ riskLevel } className={ classes.progressBar }/>
      </Flex>
      <Flex wrap="wrap" gap={ 4 }>
        <Flex className={ classes.countryStatusBadge } gap="xs">
          <Text className={ classes.dimText }>Total Population:</Text>
          <Text className={ classes.lightText }>{ countryRiskData.totalPopulation }</Text>
        </Flex>
        <Flex className={ classes.countryStatusBadge } gap="xs">
          <Text className={ classes.dimText }>Official Language:</Text>
          <Text className={ classes.lightText }>{ countryRiskData.officialLanguages }</Text>
        </Flex>
        <Flex className={ classes.countryStatusBadge } gap="xs">
          <Text className={ classes.dimText }>Currency:</Text>
          <Text className={ classes.lightText }>{ countryRiskData.currency?.currencyCode }</Text>
        </Flex>
        <Flex className={ classes.countryStatusBadge } gap="xs">
          <Text className={ classes.dimText }>Exchange Rage:</Text>
          <Text className={ classes.lightText }>{ countryRiskData.currency?.currencyRate }</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CountryRiskStatusCardComponent;
