import { createStyles, keyframes } from "@mantine/core";

import { CSS_KEY } from "@/core/styles/mantine/constants";

type Args = {
  isExpanded: boolean;
  isDisabled: boolean | undefined;
  isWithTooltip: boolean;
  isOpenClose: boolean;
  isHoverable: boolean;
}
const LogoText = keyframes({
  '0%': { transform: 'translateX(-100%)' },
  '100%': { transform: 'translateX(0)' }
});

export const useNavigationItemStyles = createStyles((theme, {
  isDisabled,
  isOpenClose
}: Args) => ({
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    height: isOpenClose ? 32 : 56,
    overflow: "clip",
    overflowClipMargin: '1px',

    fontSize: theme.fontSizes.xs,
    textTransform: "uppercase",
    // cursor: isDisabled ? 'not-allowed' : 'pointer',

    color: theme.colors.neutral[isDisabled ? 6 : 5],

    '& > *:not(:first-child)': {
      width: "100%",
      flex: 1
    }
  },

  expandedItemContainer: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    paddingRight: 16
  },

  sidebarItemContainer: {
    '& *': {
      cursor: isDisabled ? 'not-allowed !important' : 'pointer'
    },
    position: 'relative',
    backgroundColor: isOpenClose ? theme.colors.primary[4] : 'inherit',
    borderBlock: isOpenClose ? `1px solid ${theme.colors.neutral[6]}` : 'none',
    border: "0.5px solid transparent",
    borderRight: 0,
    
    '&:hover':{
      backgroundColor: theme.colors.primary[4],
      border: `0.5px solid ${theme.colors.neutral[6]}`,
      borderRight: 0
    }
  },

  itemIcon: {
    minWidth: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  tooltipIcon: {
    padding: '6px 0'
  },

  logo: {
    height: 65
  },

  navLink:{
    width: "100%",
    height: "100%",
    backgroundColor: 'transparent'
  },

  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inherit',
    animationName: LogoText,
    animationDuration: '1s',
    animationTimingFunction: 'linear',
    animationFillMode: 'forwards'
  },

  tooltipContainer: {
    padding: 8
  },

  tooltipLabel: {
    color: theme.colors.neutral[0],
    textTransform: 'uppercase'
  },

  tooltipDescription: {
    fontSize: 10,
    fontWeight: 500,
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: '100%'
  },
  navSelected: {
    backgroundColor: theme.colors.primary[4],
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    borderRight: 0
  },
  dropDownContent: {
    background: theme.colors.primary[4],
    padding: 14,
    border: 'none',
    maxHeight: "100vh",
    overflowY: "auto",
    [`.${CSS_KEY}-Menu-arrow`]: {
      border: `0.5px solid ${theme.colors.neutral[6]}`
    },
    [`.${CSS_KEY}-Text-root`]: {
      color: theme.colors.neutral[5]
    },
    [`.${CSS_KEY}-Menu-item`]: {
      fontSize: theme.fontSizes.xs,
      '&:hover': {
        backgroundColor: theme.colors.neutral[8]
      }
    },
    [`.${CSS_KEY}-Menu-divider`]: {
      margin: '5px',
      borderTop: `0.5px solid ${theme.colors.neutral[0]}`,
      opacity: 0.3
    },
    [`.${CSS_KEY}-Menu-label`]: {
      fontSize: theme.fontSizes.xs
    }
  },
  dropDownBackground: {
    height: '100%',
    top: '0px !important',
    zIndex: 0,
    background: theme.colors.primary[4],
    border: 'none'
  },
  menuItemActive: {
    backgroundColor: theme.colors.primary[4],
    border: `0.5px solid ${theme.colors.neutral[6]}`,
    borderRight: 0
  },
  menuSlideIcon: {
    svg: {
      height: '15px',
      width: '15px'
    }
  },
  subMenuDisabled: {
    '&:hover': {
      cursor: 'not-allowed !important'
    }
  }
}));
