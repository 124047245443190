import { SelectItem } from "@mantine/core";
import { createModel } from "@rematch/core";
import { FeatureCollection, GeoJsonProperties, Geometry } from "geojson";

import { GridParams } from "@/common/types/ag-grid";
import { PaginatedResult } from "@/common/types/reference-data";
import { logger } from "@/common/util/ConsoleLogger";
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { getConnectorIntegrations } from "@/tenant-context/control-integrations-mapping/api/integration-mapping";
import { LocationCategoryTypes } from "@/tenant-context/control-integrations-mapping/types/IntegrationMapping.types";
import { getPOBPeopleList, getSiteGeoJson, getSitePOBDetails } from "@/tenant-context/control-site/api/site";

import { AdapterPeopleLocationRequest, POBDisplayDetails, POBListFilters, POBPeopleListRequest, POBPeopleListResponse } from "../../types/site";
import { handleShortAdapterSourceDisplay } from "../../util/util";

type SiteState = {
  geoJson?: FeatureCollection,
  sitePOBDetails?: POBDisplayDetails,
  adapterIntegrations?: SelectItem[],
  integrationFilters?: POBListFilters
}

const siteDataModel = {
  name: 'site',
  state: {
    geoJson: undefined,
    sitePOBDetails: undefined,
    adapterIntegrations: undefined,
    integrationFilters: undefined
  } as SiteState,
  reducers: {
    SET_GEO_JSON: (state: SiteState, geoJson: SiteState['geoJson']) => ({
      ...state,
      geoJson
    }),
    SET_POB_DETAILS: (state: SiteState, sitePOBDetails?: POBDisplayDetails) => ({
      ...state,
      sitePOBDetails
    }),
    SET_ADAPTER_INTEGRATIONS: (state: SiteState, adapterIntegrations?: SelectItem[]) => ({
      ...state,
      adapterIntegrations
    }),
    SET_ADAPTER_FILTERS: (state: SiteState, integrationFilters?: POBListFilters) => ({
      ...state,
      integrationFilters
    }),
    CLEAR_ADAPTER_DETAILS: (state: SiteState) => ({
      ...state,
      sitePOBDetails: undefined,
      adapterIntegrations: undefined,
      integrationFilters: undefined
    })
  },
  effects: (dispatch: Dispatch) =>({
    async setSiteGeoJson(siteId: string): Promise<void> {
      dispatch.site.SET_GEO_JSON(undefined);
      const res = await getSiteGeoJson(siteId);
      if(res && res.length > 0) {
        try {
          const geoJson = JSON.parse(res[0].geoJson);
          dispatch.site.SET_GEO_JSON(geoJson);
        } catch (err) {
          logger.error(err);
          showNotification({
            title: 'Error',
            message: 'The GeoJson received is invalid. Please check the format.',
            color: 'error'
          });
        }

      } else {
        return;
      }


    },
    async getPOBDetails(
      siteDetails: { category: LocationCategoryTypes, siteId: string }, 
      state: RootState
    ): Promise<void> {
      const {
        site: {
          geoJson
        }
      } = state;

      const processedGeoJson = geoJson?.features ? geoJson : { type: 'FeatureCollection', features: [{ ...geoJson }] };
      if (geoJson && siteDetails.siteId){
        const pobDetails = await getSitePOBDetails(
          siteDetails.category, 
          siteDetails.siteId, 
          processedGeoJson as FeatureCollection<Geometry, GeoJsonProperties>
        );
        const displayCounts = Object.entries(pobDetails.countByAdapterMap).map(([adapterName, counts]) => ({
          adapterName: handleShortAdapterSourceDisplay(adapterName),
          ...counts
        }));

        const POBDetails: POBDisplayDetails = {
          totalPob: pobDetails.totalPob,
          locationSubCategoryId: pobDetails.locationSubCategoryId,
          locationSubCategoryType: pobDetails.locationSubCategoryType,
          countByAdapterMap: displayCounts,
          tenantId: pobDetails.tenantId,
          pobSet: pobDetails.pobSet,
          personAdapterLastKnownMap: pobDetails.personAdapterLastKnownMap
        };

        dispatch.site.SET_POB_DETAILS(POBDetails);
      }

    },
    async getPeopleListData(payload: {
      gridParams: GridParams,
    }, state: RootState): Promise<PaginatedResult<POBPeopleListResponse>> {
      const {
        site: {
          sitePOBDetails,
          integrationFilters
        }
      } = state;

      const processedLocationCounts: {
        [key: string]: AdapterPeopleLocationRequest
      } = Object.fromEntries(
        Object.entries(sitePOBDetails?.personAdapterLastKnownMap || {})?.map(([key, value]) => [
          key,
          { ...value, isLastKnownLocation: value.lastKnownLocation, lastKnownLocation: undefined }
        ])
      );

      const listRequest: POBPeopleListRequest = {
        orderBy: '',
        pageNum: payload.gridParams.page,
        pageSize: payload.gridParams.size,
        profileIdList: sitePOBDetails?.pobSet as Array<string>,
        personAdapterLastKnownMap: processedLocationCounts,
        ...(integrationFilters?.filterByAdapterSource ? integrationFilters : {}),
        ...(payload?.gridParams?.sort ? { orderBy: `${payload?.gridParams?.sort?.colId}:${payload?.gridParams?.sort?.sort}` } : {}),
        ...(payload?.gridParams?.searchQuery ? { query: `profileId like '${payload?.gridParams?.searchQuery}%' OR firstName like '${payload?.gridParams?.searchQuery}%' OR lastName like '${payload?.gridParams?.searchQuery}%'` } : {})
      };
      const listResponse = await getPOBPeopleList(
        sitePOBDetails?.locationSubCategoryType as LocationCategoryTypes, 
        sitePOBDetails?.locationSubCategoryId as string,
        listRequest
      );

      return listResponse;
    },
    async getPOBListMetaData(): Promise<void> {
      try {
        const connectorIntegrations = await getConnectorIntegrations();
        if (connectorIntegrations) {
          dispatch.site.SET_ADAPTER_INTEGRATIONS(
            connectorIntegrations.locationConnectorServices.map(connector =>
              ({ value: connector.adapterName, label: handleShortAdapterSourceDisplay(connector.adapterName) }))
          );
        }

      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            message: e?.message ?? 'An error occurred while loading integration list',
            color: 'error'
          });
        }
      }
    }
  })
};

export const site = createModel<RootModel>()(siteDataModel);
