import { SelectItem } from "@mantine/core";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DataGrid } from "@/common/types/ag-grid";
import { Dispatch, RootState } from "@/core/store";

import { peopleListColumnDefs } from "../../config/POBPeopleList.config";
import { POBListFilters } from "../../types/site";
import POBDisplayComponent from "./POBPeopleList.component";
import POBPeopleListFilter from "./POBPeopleListFilter.component";

type Props = {
  isPeopleListOpened: boolean,
  onPeopleModalClose: () => void
}
const POBPeopleList: FC<Props> = ({ isPeopleListOpened, onPeopleModalClose }) => {
  const [currentFilters, setCurrentFilters] = useState<POBListFilters>({
    filterByAdapterSource: ''
  });

  const [_resetCount, setResetCount] = useState<number>(0);
  const ref = useRef<DataGrid>(null);

  const { adapterIntegrations } =
    useSelector((state: RootState) => state.site);

  const {
    site: {
      getPeopleListData,
      getPOBListMetaData,
      SET_ADAPTER_FILTERS
    }
  } = useDispatch<Dispatch>();

  const handleFiltersChange = useCallback((type: string, value: string) => {
    setCurrentFilters(filters => ({
      ...filters,
      [type]: value
    }));
    SET_ADAPTER_FILTERS({ filterByAdapterSource: value });
  }, [SET_ADAPTER_FILTERS]);

  const resetFilters = useCallback(() => {
    setCurrentFilters({
      filterByAdapterSource: ''
    });
    SET_ADAPTER_FILTERS(undefined);
    setResetCount(count => count + 1);
  }, [SET_ADAPTER_FILTERS]);

  const submitFilters = useCallback(() => {
    ref.current?.refreshGrid();
  }, []);

  const processedAdapters = useMemo(() => {
    if (adapterIntegrations && adapterIntegrations?.length > 0){
      const adapters = [...adapterIntegrations as SelectItem[]];
      adapters.push({ label: 'Work Location', value: 'person-profile-service' });
      return adapters;
    } else {
      return [];
    }
  }, [adapterIntegrations]);

  useEffect(() => {
    if (isPeopleListOpened){
      resetFilters();
      getPOBListMetaData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPeopleListOpened]);

  const renderHeaderFilters = useCallback(() => <POBPeopleListFilter
    currentFilters={ currentFilters }
    handlePOBFilterChange={ handleFiltersChange }
    handlePOBFilterReset={ resetFilters }
    handlePOBFilterSubmit={ submitFilters }
    filterData={ { adapterIntegrations: processedAdapters || [] } }
  />, [currentFilters, handleFiltersChange, processedAdapters, resetFilters, submitFilters]);



  return (
    <POBDisplayComponent 
      tableRef={ ref }
      isPeopleListOpened={ isPeopleListOpened } 
      onPeopleModalClose={ onPeopleModalClose } 
      additionalGridParams={ currentFilters }
      columnDefs={ peopleListColumnDefs() }
      getData={ getPeopleListData }
      renderHeaderFilters={ renderHeaderFilters }
    />
  );
};

export default POBPeopleList;
