import { createModel } from "@rematch/core";

import { logger } from "@/common/util/ConsoleLogger";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { getMiniProfileDetails, getPersonTravelItenerary } from "@/tenant-context/control-profile/api/mini-profile";
import { getProfileList } from "@/tenant-context/control-profile/api/profile-list";
import { profileListQueryString } from "@/tenant-context/control-profile/store/profile/profile-list-query";
import { MiniProfileDatails } from "@/tenant-context/control-profile/types/mini-profile";
import {
  PaginatedResult,
  ProfileListFilterData, ProfileListItem,
  ProfileListMetaData } from "@/tenant-context/control-profile/types/profile-list";
import { PersonTravelItenerary } from "@/tenant-context/visualisation-people/types/people.types";

// ! This state is intended to be merged with the profile.model.ts
// ! Do not modify it otherwise than merging with profile.model.ts
// ! This is legacy, we don't need 2 states for these 2 entities

export type UserProfileState = {
  profileList?: PaginatedResult<ProfileListItem>,
  currentUserProfile?: MiniProfileDatails,
  lwStatus?: string,
  id?: string,
  profileTid?: string,
  email?: string,
  profileListFilterData?: ProfileListFilterData,
  userTravelItenerary?: PersonTravelItenerary,
  isTravelItineraryShownOnMap?: boolean,
  siteContactProfile?: MiniProfileDatails,
  travelCorrelationId?: string | undefined,
  isProfileListLoading: boolean
}

const userProfileModel = ({
  name: 'userProfile',
  state: {
    profileList: undefined,
    currentMiniProfile: undefined,
    id: undefined,
    profileTid: undefined,
    email: undefined,
    lwStatus: undefined,
    profileListFilterData: {},
    userTravelItenerary: undefined,
    isTravelItineraryShownOnMap: false,
    siteContactProfile: undefined,
    travelCorrelationId: undefined,
    isProfileListLoading: false

  } as UserProfileState,

  reducers: {
    SET_CURRENT_USER_LW_STATUS(
      state: UserProfileState,
      status: string | undefined
    ): UserProfileState {
      return {
        ...state,
        lwStatus: status
      };
    },

    SET_CURRENT_USER_MINI_PROFILE(
      state: UserProfileState,
      selectedUserProfile: MiniProfileDatails
    ): UserProfileState {
      return {
        ...state,
        currentUserProfile: selectedUserProfile
      };
    },

    SET_USER_TRAVEL_ITENERARY(
      state: UserProfileState,
      userTravelItenerary: PersonTravelItenerary
    ): UserProfileState {
      return {
        ...state,
        userTravelItenerary: userTravelItenerary
      };
    },

    SET_SITE_CONTACT_PROFILE(
      state: UserProfileState,
      selectedUserProfile: MiniProfileDatails
    ): UserProfileState {
      return {
        ...state,
        siteContactProfile: selectedUserProfile
      };
    },

    SET_CURRENT_USER_ID(
      state: UserProfileState,
      id: string
    ): UserProfileState {
      return {
        ...state,
        id
      };
    },
    SET_CURRENT_USER_PROFILE_ID(
      state: UserProfileState,
      profileTid: string
    ): UserProfileState {
      return {
        ...state,
        profileTid
      };
    },

    SET_CURRENT_USER_EMAIL(
      state: UserProfileState,
      email: string
    ): UserProfileState {
      return {
        ...state,
        email
      };
    },

    RESET_CURRENT_USER_MINI_PROFILE(
      state: UserProfileState
    ): UserProfileState {
      return {
        ...state,
        lwStatus: undefined,
        currentUserProfile: undefined,
        id: '',
        userTravelItenerary: undefined,
        isTravelItineraryShownOnMap: false,
        travelCorrelationId: undefined
      };
    },

    SET_PROFILE_LIST_FILTER_DATA(
      state: UserProfileState,
      profileListFilterData: ProfileListFilterData
    ): UserProfileState {
      return {
        ...state,
        profileListFilterData: profileListFilterData
      };
    },

    RESET_ITINERARY_DATA(
      state: UserProfileState
    ): UserProfileState {
      return {
        ...state,
        userTravelItenerary: undefined
      };
    },

    SET_USER_PROFILE(state: UserProfileState, profileList: PaginatedResult<ProfileListItem>): UserProfileState {
      return {
        ...state,
        profileList: profileList
      };
    },

    SET_ITINERARY_SHOWN_ON_MAP(
      state: UserProfileState,
      isShown: boolean
    ): UserProfileState {
      return {
        ...state,
        isTravelItineraryShownOnMap: isShown
      };
    },

    SET_TRAVEL_CORRELATION_ID(
      state: UserProfileState,
      travelCorrelationId: string | undefined
    ): UserProfileState {
      return {
        ...state,
        travelCorrelationId: travelCorrelationId
      };
    },

    SET_IS_PROFILE_LIST_LOADING: (
      state: UserProfileState,
      isProfileListLoading: UserProfileState['isProfileListLoading']
    ) => ({
      ...state,
      isProfileListLoading
    })
  },
  effects: (dispatch: Dispatch) => ({
    async reloadMiniProfileData(_: void, state: RootState): Promise<void> {
      if (!state.userProfile.id) {
        return;
      }
      const personData = await getMiniProfileDetails(state.userProfile.id);
      dispatch.userProfile.SET_CURRENT_USER_MINI_PROFILE(personData);
    },

    async refreshUserProfileLwStatus(_: void, state: RootState): Promise<string | undefined> {
      const {
        userProfile: {
          id
        }
      } = state;

      if (!id) {
        return;
      }

      const personTopRank = dispatch.peopleLocations.getPersonById(id);

      if (personTopRank && personTopRank.loneWorker) {
        dispatch.userProfile.SET_CURRENT_USER_LW_STATUS(
          personTopRank.loneWorker.status
        );

        return personTopRank.loneWorker.status;
      } else {
        logger.warn('Unable to find person top rank', id);
      }
    },

    async getMiniProfileData(personId: string): Promise<void> {
      dispatch.userProfile.SET_CURRENT_USER_LW_STATUS(
        undefined
      );
      const personData = await dispatch.globalPeople.getMiniProfileById(personId);
      dispatch.userProfile.refreshUserProfileLwStatus();
      dispatch.userProfile.SET_CURRENT_USER_MINI_PROFILE(personData);
    },

    async getUserTravelItenerary(correlationId: string): Promise<void> {
      const personItinerary = await getPersonTravelItenerary(correlationId);
      const ItineraryWithCorrectedEpochFormat = personItinerary.map((itinerary) => {
        return {
          ...itinerary,
          start_date: Number(itinerary.start_date.toString().padEnd(13, '0')),
          end_date: Number(itinerary.end_date.toString().padEnd(13, '0'))
        };
      }).sort((a, b) => a.start_date - b.start_date);

      dispatch.userProfile.SET_USER_TRAVEL_ITENERARY(ItineraryWithCorrectedEpochFormat);
    },

    async getSiteContactProfileData(personId: string): Promise<void> {
      const personData = await dispatch.globalPeople.getMiniProfileById(personId);
      dispatch.userProfile.SET_SITE_CONTACT_PROFILE(personData);

    },

    async getProfileList(
      meta: ProfileListMetaData,
      state: RootState
    ): Promise<PaginatedResult<ProfileListItem> | undefined> {
      const profileTagsFilter = state?.userProfile?.profileListFilterData?.profileTags?.map((tagName) => {
        return state.profile?.profileTagsList?.find((tag) => tag.name === tagName)?.id;
      })?.toString();

      const profileList = await getProfileList(
        meta?.page,
        meta?.size,
        meta?.sort,
        profileListQueryString(state?.userProfile?.profileListFilterData),
        profileTagsFilter
      );

      dispatch.userProfile.SET_USER_PROFILE(profileList);
      dispatch.userProfile.SET_IS_PROFILE_LIST_LOADING(false);
      return profileList;
    }
  })
});

export const userProfile = createModel<RootModel>()(
  userProfileModel
);
