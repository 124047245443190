import { Divider } from "@mantine/core";
import { FC } from "react";

import LineClamp from '@/common/components/LineClamp';
import { ReactComponent as MaxSecurityIcon } from "@/common/icons/RiskProviders/max-security.svg";
import { getTimeDurationFromCurrentDate } from "@/common/util/format/date";
import CountryFlag from "@/tenant-context/common/components/CountryFlag";
import RiskAlertBasicData from "@/tenant-context/visualisation-risk-alerts/components/RiskAlertBasicData";
import { MaxSecurityExternalData } from '@/tenant-context/visualisation-risk-alerts/types/max-security';
import {
  RiskAlert,
  RiskAlertEvent,
  RiskAlertEventMeta,
  RiskAlertJsonMeta
} from "@/tenant-context/visualisation-risk-alerts/types/risk-alerts";
import { RiskConnectorExternalData } from '@/tenant-context/visualisation-risk-alerts/types/risk-alerts-generic';

type Props = {
  classes: Record<string, string>,
  onMouseEnter: () => void,
  onMouseLeave: () => void,
  meta: RiskAlertEventMeta,
  json: { meta: RiskAlertJsonMeta; alert: RiskAlert; externalData?: MaxSecurityExternalData },
  riskAlertData: RiskAlertEvent<MaxSecurityExternalData>,
  isImpacted: boolean
}

export const MaxSecurityPopupContent: FC<Props> = ({
  classes,
  onMouseEnter,
  onMouseLeave,
  meta,
  json,
  riskAlertData,
  isImpacted: _isImpacted
}) => {
  const mappedRiskAlertData: RiskAlertEvent<RiskConnectorExternalData> = {
    ...riskAlertData,
    json: {
      ...riskAlertData.json,
      alert: {
        ...riskAlertData.json.alert,
        start_date: new Date(riskAlertData?.json.alert.start_date).toLocaleDateString(),
        end_date: new Date(riskAlertData?.json.alert.end_date).toLocaleDateString()
      }
    }
  };

  return (
    <div className={ classes.popupContainer }
      onMouseEnter={ onMouseEnter }
      onMouseLeave={ onMouseLeave }
    >
      <div className={ classes.timesAgoText }>
        { (meta.createdTime > 0)
          ? getTimeDurationFromCurrentDate(new Date(meta.createdTime))
          : "" }
      </div>
      <div className={ classes.popupHeader }>
        { json.alert.countries[0].iso_code && (
          <CountryFlag
            width={ 65 }
            height={ 50 }
            countryCode={ json.alert.countries[0].iso_code }
          />
        ) }
        { json.externalData?.affected_area || json.alert.location_explanation }
      </div>
      <div className={ classes.riskAlertTitle }>
        <LineClamp numberOfLines={ 2 }>
          { json.alert.title }
        </LineClamp>
      </div>
      <RiskAlertBasicData riskAlertData={ mappedRiskAlertData }/>

      <Divider
        className={ classes.divider }
      />
      <div className={ classes.riskAlertProvider }>
        <div className={ classes.issuedBy }>Issued by</div>
        <MaxSecurityIcon className={ classes.riskAlerProviderIcon }/>
        <div className={ classes.riskAlertProviderName }> Max Security</div>
      </div>
    </div>
  );
};
