import { createModel } from "@rematch/core";

import { getTenantIdFromToken } from "@/common/util/api";
import { TENANT_TOKEN_KEY } from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import { Dispatch, RootModel, RootState } from "@/core/store";
import { Location } from "@/tenant-context/common/types/location";
import { RiskProviderItem } from "@/tenant-context/control-risk-config/types/risk";
import { getSiteConnectors } from "@/tenant-context/control-site/api/site";
import { ISiteDto, SessionSite,SITE_SESSION_KEY } from "@/tenant-context/control-visitor-management/types/visitorManagement";
import { getAllCountries } from "@/tenant-context/core/api/countries";
import { getAllLocationHierarchy } from "@/tenant-context/core/api/locations";
import { getAllRiskAlertProviders } from "@/tenant-context/core/api/riskProviders";
import { getAllConfigs } from '@/tenant-context/core/api/session';
import { MILLISECONDS_IN_MINUTE } from '@/tenant-context/core/config/consts';
import { getFormattedTenantSubscribedProducts } from "@/tenant-context/navigation/api/navigation";
import { SubscribedProducts } from "@/tenant-context/navigation/types/user";
import { WidgetSite } from "@/tenant-context/widget-overview/types/site.types";

import { excludedProvidernames } from "../../components/utlis/common-utils";


type CommonData = {
  allCountries: Location[],
  selectedCountry?: Location,
  riskProviders: RiskProviderItem[],
  allLocations: Location[],
  selectedSite?: WidgetSite,
  tenantId: string,
  showLoaderScreen: boolean,
  isNavigationExpanded: boolean,
  isLegacyModeOn: undefined | boolean,
  tenantSubscribedProducts: SubscribedProducts[],
  loadTenantSubscribedProducts: () => void,
  isSubscribedProductsLoaded: boolean,
  shouldRefreshSitesFlag: boolean,
  currentSite?: ISiteDto,
  sessionTimeout?: number
};

const commonDataModel = {
  name: 'commonData',
  state: {
    allCountries: [] as Location[],
    allLocations: [] as Location[],
    selectedCountry: undefined,
    selectedSite: undefined,
    tenantId: browserDatabase.getItem('TENANT'),
    showLoaderScreen: false,
    isNavigationExpanded: false,
    isLegacyModeOn: undefined,
    isSubscribedProductsLoaded: false,
    shouldRefreshSitesFlag: false,
    currentSite: undefined,
    sessionTimeout: 0
  } as CommonData,
  reducers: {
    SET_COUNTRIES: (state: CommonData, countries: Location[]) => ({
      ...state,
      allCountries: countries
    }),
    SET_SELECTED_COUNTRY: (state: CommonData, country: Location | undefined) => ({
      ...state,
      selectedCountry: country
    }),
    SET_SELECTED_SITE: (state: CommonData, site: WidgetSite | undefined) => ({
      ...state,
      selectedSite: site
    }),
    SET_TENANT_ID: (state: CommonData, tenantId: string) => ({
      ...state,
      tenantId
    }),
    SET_LEGACY_MODE: (state: CommonData, isLegacy: boolean) => ({
      ...state,
      isLegacyModeOn: isLegacy
    }),
    SET_RISK_PROVIDERS: (state: CommonData, riskProviders: RiskProviderItem[]) => ({
      ...state,
      riskProviders
    }),
    SET_ALL_LOCATIONS: (state: CommonData, locations: Location[]) => ({
      ...state,
      allLocations: locations
    }),
    SET_SHOW_LOADER_SCREEN: (state: CommonData, showLoaderScreen: boolean) => ({
      ...state,
      showLoaderScreen: showLoaderScreen
    }),
    SET_IS_NAVIGATION_EXPANDED: (state: CommonData, isNavigationExpanded: boolean) => ({
      ...state,
      isNavigationExpanded: isNavigationExpanded
    }),
    SET_TENANT_SUBSCRIBED_PRODUCTS: (state: CommonData, tenantSubscribedProducts: SubscribedProducts[]) => ({
      ...state,
      tenantSubscribedProducts: tenantSubscribedProducts
    }),
    SET_TENANT_SUBSCRIBED_PRODUCTS_LOADED: (state: CommonData, isLoaded: boolean) => ({
      ...state,
      isSubscribedProductsLoaded: isLoaded
    }),
    SET_SHOULD_REFRESH_SITES_FLAG: (state: CommonData, shouldRefreshSitesFlag: boolean) => ({
      ...state,
      shouldRefreshSitesFlag: shouldRefreshSitesFlag
    }),
    SET_CURRENT_SITE: (state: CommonData, currentSite: ISiteDto) => ({
      ...state,
      currentSite
    }),
    SET_SESSION_TIMEOUT: (state: CommonData, sessionTimeout: number | undefined) => ({
      ...state,
      sessionTimeout
    })
  },
  effects: (dispatch: Dispatch) => ({
    async loadAllCountries(): Promise<Location[]> {
      const countries = await getAllCountries();

      dispatch.commonData.SET_COUNTRIES(countries);

      return countries;
    },

    setTenant(params: { tenantId: string, refreshBrowser: boolean }): void {
      const tenantId = params.tenantId || getTenantIdFromToken();

      if (!tenantId) {
        throw new Error('Tenant ID is not defined');
      }

      browserDatabase.setItem('TENANT', tenantId);
      dispatch.commonData.SET_TENANT_ID(tenantId);

      if (params.refreshBrowser) {
        window.location.reload();
      }
    },

    async loadAllRiskProviders(): Promise<RiskProviderItem[]>{
      const riskProviders = await getAllRiskAlertProviders();
      const filteredRiskProviders = (riskProviders?.items as Array<RiskProviderItem>)
        ?.filter((provider, index, providerArr) => 
          index === providerArr.findIndex((item) => item.providerName === provider.providerName) 
        && !excludedProvidernames.includes(provider.providerName));

      dispatch.commonData.SET_RISK_PROVIDERS(filteredRiskProviders);

      if (riskProviders.items.find((provider) => (provider as RiskProviderItem).providerName === "dataminr") !== undefined) {
        dispatch.riskEventFilterDataOptions.getDataminrWatchlist();
      }

      return riskProviders.items;
    },

    async loadAllLocations(): Promise<Location[]> {
      const locations = await getAllLocationHierarchy();

      dispatch.commonData.SET_ALL_LOCATIONS(locations);

      return locations;
    },
    async loadLegacyModeCheck(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          isLegacyModeOn
        }
      } = state;

      if (isLegacyModeOn === undefined){
        const getConnectorMode = await getSiteConnectors();
        dispatch.commonData.SET_LEGACY_MODE(getConnectorMode.legacyEnabled);
      }
    },
    async startLoading(): Promise<void> {
      dispatch.commonData.SET_SHOW_LOADER_SCREEN(true);
    },

    async loadingComplete(): Promise<void> {
      dispatch.commonData.SET_SHOW_LOADER_SCREEN(false);
    },
    async loadTenantSubscribedProducts(_: void, state: RootState): Promise<void> {
      const {
        commonData: {
          tenantId
        }
      } = state;
      const accessToken = browserDatabase.getItem(TENANT_TOKEN_KEY);
      try {
        const subscribedProducts = await getFormattedTenantSubscribedProducts(accessToken as string, tenantId);
        dispatch.commonData.SET_TENANT_SUBSCRIBED_PRODUCTS(subscribedProducts);
        dispatch.commonData.SET_TENANT_SUBSCRIBED_PRODUCTS_LOADED(true);
      } catch {
        dispatch.commonData.SET_TENANT_SUBSCRIBED_PRODUCTS_LOADED(true);
      }
    },
    getCurrentSite(_:void, state: RootState): ISiteDto{
      // eslint-disable-next-line fp/no-let
      let { currentSite } = state.commonData;

      if(!currentSite){
        const sessionSite = browserDatabase.getItem(SITE_SESSION_KEY) as SessionSite;

        currentSite = {
          name: sessionSite?.siteName || "",
          tid: sessionSite?.siteId || ""
        };
      }

      return currentSite;
    },
    async loadSessionConfig(_:void, __: RootState): Promise<void> {
      try {
        const configs = await getAllConfigs();
        const sessionTimeout = configs.items
          .find((config) => config.configName === 'idleTimeout')?.configValue;
        const sessionTimeoutInt = parseInt(sessionTimeout as string, 10);

        dispatch.commonData.SET_SESSION_TIMEOUT(sessionTimeoutInt * MILLISECONDS_IN_MINUTE);
      } catch(error) {
        dispatch.commonData.SET_SESSION_TIMEOUT(undefined);
      }
    }
  })
};

export const commonData = createModel<RootModel>()(commonDataModel);
