import { PaginatedResult } from "@/common/types/reference-data";
import { callApi } from "@/common/util/api";

import { IntegrationListItem, IntegrationListQuery, IntegrationSubmitRequest, LocationSearch, LocationSubCategory, SubscribedConnectorList } from "../types/IntegrationMapping.types";

export const getLocationSubCategories = () => callApi<PaginatedResult<LocationSubCategory>>(
  `public/platform-service/v1/reference-data?type=Location-Sub-Type&size=1000`,
  {
    method: 'get'    
  }
);

export const getConnectorIntegrations = () => callApi<SubscribedConnectorList>(
  `connector-core-service/v1/connectors/location-connectors`,
  {
    method: 'get',
    withTenantId: true    
  }
);

export const getLocationSearchResults = (searchType: string, searchCriteria: string) => 
  callApi<PaginatedResult<LocationSearch>>(
    `location-management-service/v2/${searchType}?query=(name%20LIKE%20%27%25${searchCriteria}%25%27%20OR%20code%20LIKE%20%27%25${searchCriteria}%25%27)&pageSize=0&pageNum=0&mergeNonTenant=false`,
    {
      method: 'get',
      withTenantId: true
    }
  );

export const postIntegrationMapping = (
  integrationRequest: IntegrationSubmitRequest
) => callApi<void>(
  `location-management-service/v2/location-connector-integration`,
  {
    method: 'post',
    body: JSON.stringify({
      ...integrationRequest
    }),
    withTenantId: true
  }
);

export const getIntegrationList = (integrationRequest: IntegrationListQuery) =>
  callApi<PaginatedResult<IntegrationListItem>>(
    `location-management-service/v2/location-connector-integration`,
    {
      method: 'get',
      withTenantId: true,
      queryParams: integrationRequest
    }
  );

export const getLocationIntegrationById = (id: string) => callApi<IntegrationListItem>(
  `location-management-service/v2/location-connector-integration/${id}`,
  {
    method: 'get',
    withTenantId: true
  }
);

export const deleteLocationIntegrationById = (id: string) => callApi<IntegrationListItem>(
  `location-management-service/v2/location-connector-integration/${id}`,
  {
    method: 'delete',
    withTenantId: true
  }
);
