import { Box, Checkbox, Divider, Flex, Switch, Text } from '@mantine/core';
import React, { FC } from "react";

import TruncatedTextWithTooltip from '@/common/components/TruncatedTextWithTooltip';
import { ReactComponent as RiskArrow } from "@/common/icons/risk-arrow.svg";
import { ReactComponent as RiskInfoIcon } from "@/common/icons/riskInfoIcon-grey.svg";
import { capitalizeWord } from "@/common/util/format/string";
import { RiskProviderItem } from '@/tenant-context/control-risk-config/types/risk';

import { useRiskEventsProviderSelectionStyles } from './RiskEventsProviderSelection.styles';

type Props = {
  onRiskProviderSelection: (riskProviderId: string) => void,
  riskProviders: RiskProviderItem[],
  onRiskProviderSwitch: React.ChangeEventHandler<HTMLInputElement>,
  onImpactRiskEventsToggleChange: React.ChangeEventHandler<HTMLInputElement>,
  alertEnabledStatus: { [key: string]: boolean },
  isShowOnlyImpactedAlerts: boolean
};

const RiskEventsProviderSelection: FC<Props> = ({
  onRiskProviderSelection,
  riskProviders,
  onRiskProviderSwitch,
  onImpactRiskEventsToggleChange,
  alertEnabledStatus,
  isShowOnlyImpactedAlerts
}) => {
  const { classes } = useRiskEventsProviderSelectionStyles();

  const getCapitalizedProviderName = (providerName: string) => {
    if (providerName === 'riskline') {
      return capitalizeWord(providerName);
    }

    if (providerName === 'dataminr') {
      return capitalizeWord(providerName);
    }

    if (providerName === 'max-security') {
      return capitalizeWord(providerName).replace('-', ' ');
    }

    return providerName?.toUpperCase();
  };

  return (
    <Box py={ 'md' }>
      { /* Risk provider list */ }
      { riskProviders?.length > 0 ?
        <Flex direction={ 'column' } gap={ 'xl' }>
          { riskProviders.map(({
            activeNumber,
            tid,
            providerName,
            Icon
          }) => (
            <Flex
              justify={ 'space-between' }
              align={ 'center' }
              wrap={ 'nowrap' }
              key={ tid }
              role="none"
              className={ classes.riskProvider }
            >
              <Box
                className={ classes.riskProviderDetails }
                // eslint-disable-next-line react/jsx-no-bind
                onClick={ () => onRiskProviderSelection(providerName) }
              >
                { Icon && <Icon className={ classes.riskProviderIcon }/> }
                <Flex gap={ 'xs' } className={ classes.riskProviderName }>
                  <TruncatedTextWithTooltip
                    fullText={ getCapitalizedProviderName(providerName) }
                    width={ 180 }
                    isMultiline
                  />
                  <span className={ classes.riskAlertCount }>{ activeNumber ? `(${activeNumber})` : '' }</span>
                </Flex>
              </Box>
              <Flex gap={ 8 } align={ 'center' }>
                <Switch
                  className={ classes.riskProviderSwitch }
                  checked={ alertEnabledStatus[providerName] }
                  value={ providerName }
                  onChange={ onRiskProviderSwitch }
                  size={ 'md' }
                />
                <RiskArrow
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => onRiskProviderSelection(providerName) }
                />
              </Flex>
            </Flex>
          )) }
        </Flex> : <div className={ classes.noFeedText }> No risk data feed available </div> }

      <Divider  className={ classes.separator }/>

      <Checkbox
        className={ classes.impactCalculationChkBox }
        checked={ isShowOnlyImpactedAlerts }
        onChange={ onImpactRiskEventsToggleChange }
        label={  <>
          <Box w={ 32 } pt={ 4 }><RiskInfoIcon/></Box>
          <Text pt={ 4 }>Only show Risk Events impacting assets and people</Text>
        </> }
      />
      { /* Risk alert type list */ }
      { /* { !!riskProviders.length &&
        (<div>
          <ul>
            <li className={ classes.riskEventTypes }>
              <div className= { classes.riskEventTypeDetails }>
                <RiskLoneWorker className= { classes.riskEventTypeIcon } />
                <span className={ classes.riskAlertTypeItem }> Lone Worker Events </span>
              </div>
              <Switch/>
            </li>
            <li className={ classes.riskEventTypes }>
              <div className= { classes.riskEventTypeDetails }>
                <RiskIcm className= { classes.riskEventTypeIcon }  />
                <span className={ classes.riskAlertTypeItem }> ICM Events </span>
              </div>
              <Switch />
            </li>
            <li className={ classes.riskEventTypes }>
              <div className= { classes.riskEventTypeDetails }>
                <RiskLastMile className= { classes.riskEventTypeIcon } />
                <span className={ classes.riskAlertTypeItem }> Last Mile Events </span>
              </div>
              <Switch />
            </li>
          </ul>
          <Divider  className={ classes.separator }/>
        </div>) } */ }
    </Box>
  );
};

export default RiskEventsProviderSelection;
