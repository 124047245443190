import { FeatureCollection } from 'geojson';
import { createContext, FC, useCallback, useContext, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useMap } from 'react-map-gl';
import { useDispatch, useSelector } from 'react-redux';

import { showNotification } from "@/common/util/notification";
import { Dispatch,RootState } from '@/core/store';
import { EnteredLocationDetails } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

export type LocationDetailsFormType = EnteredLocationDetails & { timezones: string };

export type LocationManagementContextType = {
  currentlySelectedLocation?: FeatureCollection;
  externallySetCoordinates?: { lat: number, lng: number };
  setMarkerLocationExternally: (lat: number, lng: number) => void;
  locationDetailsForm: UseFormReturn<LocationDetailsFormType>;
};

export const LocationManagementContext =
createContext<LocationManagementContextType>({} as LocationManagementContextType);

export const useLocationManagementContext = () => useContext(LocationManagementContext);

export const LocationManagementContextProvider: FC = ({
  children
}) => {

  const [currentlySelectedLocation, setCurrentlySelectedLocation] = useState<FeatureCollection>();
  const [externallySetCoordinates, setExternallySetCoordinates] = useState<{lat: number, lng: number}>();

  const currentSearchedLocation = useSelector((state: RootState) => state.mapSearch?.activeLocation);

  const {
    siteLocations: {
      subscribeToConnectedSiteData
    }
  } = useDispatch<Dispatch>();

  const { AddLocationMap } = useMap();
  const locationDetailsForm = useForm<LocationDetailsFormType>({
    mode: 'onChange',
    defaultValues: {
      id: '',
      code: '',
      parentId: '',
      lat: '',
      lon: '',
      description: '',
      isoCountryCode: '',
      timezones: '',
      address01: '',
      address02: '',
      alias: [],
      city: '',
      countyRegion: '',
      locationCategory: '',
      postalCode: '',
      locationType: '',
      isCriticalLocation: false
    }
  });

  const setMarkerLocationExternally = useCallback((lat: number, lng: number) => {
    if (lat < -90 || lat > 90) {
      showNotification({
        message: 'Latitude value should be between -90 and 90',
        color: 'error'
      });

      return;
    }
    if (lng < -180 || lng > 180) {
      showNotification({
        message: 'Longitude value should be between -180 and 180',
        color: 'error'
      });

      return;
    }

    setExternallySetCoordinates({ lat: lat, lng: lng });
    if (!AddLocationMap) {
      return;
    }

    AddLocationMap?.flyTo({
      center: {
        lng: lng,
        lat: lat
      },
      zoom: 12
    });

  }, [AddLocationMap]);

  useEffect(() => {
    setCurrentlySelectedLocation(currentSearchedLocation);
  }, [currentSearchedLocation]);

  useEffect(() => {
    subscribeToConnectedSiteData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocationManagementContext.Provider value={ {
      currentlySelectedLocation,
      externallySetCoordinates,
      setMarkerLocationExternally,
      locationDetailsForm
    } }>
      { children }
    </LocationManagementContext.Provider>
  );
};
