import { Button, Textarea, TextInput } from "@mantine/core";
import { FC } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

import { addErrorMessages } from "@/common/components/FormError/FormError.component";
import {
  useBuildingsAndFloorsStyles
} from "@/tenant-context/control-location-configuration/components/AddLocations/BuildingsAndFloorsTab/BuildingsAndFloorsTab.styles";
import { FloorListItem } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

type Props = {
  formControls:   UseFormReturn<FieldValues, unknown>
  onSubmit: (data: FieldValues) => void
  activeFloor?: FloorListItem
  onFormClear: () => void
  isAuthorizedToEdit: boolean
}

const EditFloorFormComponent: FC<Props> = ({
  formControls,
  onSubmit,
  activeFloor,
  onFormClear,
  isAuthorizedToEdit
}) => {
  const { register, handleSubmit, formState: { errors } } = formControls;
  const { classes } = useBuildingsAndFloorsStyles();

  return (
    <form className={ classes.floorForm } onSubmit={ handleSubmit(onSubmit) }>
      <div className={ classes.formRow }>
        <TextInput
          disabled={ !isAuthorizedToEdit }
          className={ classes.formItem }
          defaultValue={ activeFloor?.name || '' }
          required={ true }
          label={ 'Name' }
          error={ errors?.name?.message }
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          { ...register('name', addErrorMessages('Name', {
            required: true,
            maxLength: 128
          })) }
        />
        <TextInput
          disabled={ !isAuthorizedToEdit }
          className={ classes.formItem }
          defaultValue={ activeFloor?.code || '' }
          required={ true }
          label={ 'Code' }
          error={ errors?.code?.message }
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          { ...register('code', addErrorMessages('Code', {
            required: true,
            maxLength: 16
          })) }
        />
      </div>
      <div className={ classes.formRow }>
        <Textarea
          disabled={ !isAuthorizedToEdit }
          className={ classes.formItem }
          defaultValue={ activeFloor?.note || '' }
          label={ 'Notes' }
          error={ errors?.note?.message }
          minRows={ 4 }
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          { ...register('note', addErrorMessages('Notes', {
            required: false,
            maxLength: 1024
          })) }
        />
      </div>
      <div className={ classes.buttonContainer }>
        <Button variant="outline" onClick={ onFormClear }>Cancel</Button>
        <Button
          type={ 'submit' }
          disabled={ !isAuthorizedToEdit }
        >
          Submit
        </Button>
      </div>
    </form>
  );
};

export default EditFloorFormComponent;
