import { Loader, Menu, TextInput } from "@mantine/core";
import { FC, useRef } from "react";

import { ReactComponent as CloseIcon } from '@/common/icons/close.svg';
import { ReactComponent as DefaultLocationCategoryIcon } from "@/common/icons/default-location-category.svg";
import { ReactComponent as PlusCircle } from "@/common/icons/plus-circle.svg";
import { genericColorPalette } from "@/core/styles/mantine/palettes";
import { CategoryDTO } from "@/tenant-context/control-location-configuration/types/ManageLocations.types";

import { useLocationsListStyles } from "../LocationsList.styles";
import LocationCategoryItem from "./LocationCategoryItem.component";

interface Props {
    categoryList: CategoryDTO[],
    inputLabel?: string,
    searchValue: string,
    onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onLocationCategoryDelete: (categoryItem: CategoryDTO) => void,
    onLocationCategoryEdit: (categoryItem: CategoryDTO) => void,
    onLocationSearchReset: () => void,
    onLocationCategorySelect: (value: string) => void,
    onLocationCategoryAdd: () => void,
    isMenuOpened: boolean,
    setIsMenuOpened: React.Dispatch<React.SetStateAction<boolean>>,
    isLoading: boolean,
    isAuthorized: boolean,
    disabled?: boolean
}

const LocationCategoryComponent: FC<Props> = ({
  categoryList,
  inputLabel,
  searchValue,
  onSearchChange,
  onLocationSearchReset,
  onLocationCategorySelect,
  onLocationCategoryDelete,
  onLocationCategoryEdit,
  onLocationCategoryAdd,
  isMenuOpened,
  setIsMenuOpened,
  isLoading,
  isAuthorized,
  disabled
}) => {

  const { classes } = useLocationsListStyles();
  const inputRef = useRef<HTMLInputElement>(null);

  const currentSelectedCategoryItem = categoryList.find((item) => item.label === searchValue);
  const currentCategoryIcon = (currentSelectedCategoryItem?.icon && currentSelectedCategoryItem.icon !== 'default category url')
    ? <img src={ currentSelectedCategoryItem.icon } alt={ currentSelectedCategoryItem.label.charAt(0) }/>
    : <DefaultLocationCategoryIcon />;

  return (
    <>
      <TextInput
        ref = { inputRef }
        label={ inputLabel || "Location Category" }
        className={ classes.searchInput }
        value={ searchValue }
        onChange={ onSearchChange }
        icon={ currentCategoryIcon }
        rightSection={ isLoading ?
          <Loader size={ "sm" } /> :
          <CloseIcon className={ classes.searchRight } onClick={ onLocationSearchReset } /> }
        disabled={ disabled }
        // eslint-disable-next-line react/jsx-no-bind
        onFocus={ () => {
          setIsMenuOpened(true);

          //required to keep the menu dropdown open
          setTimeout(()=>{
            inputRef.current?.focus();
          }, 50);
        } }
        // eslint-disable-next-line react/jsx-no-bind
        onBlur={ () => setIsMenuOpened(false) }
      />
      <div className={ classes.relative }>
        <Menu width="100%" position="bottom" opened={ isMenuOpened }>
          <Menu.Dropdown className={ classes.dropdown }>
            { categoryList?.length === 0 &&
            <Menu.Item className={ classes.menuItem }>No search results</Menu.Item> }
            <Menu.Item disabled={ !isAuthorized } className={ classes.menuItem }
              onClick={ isAuthorized ? onLocationCategoryAdd : undefined }>
              <div className={ classes.addCategory }>
                <PlusCircle fill={ genericColorPalette.neutral[0] } width={ 20 } height={ 20 }/>
                <span>
                  Add New Category
                </span>
              </div>
            </Menu.Item>
            { categoryList.map((item, index) => <LocationCategoryItem key={ "categoryItem-" + index }
              categoryItem={ item }
              isAuthorized = { isAuthorized }
              onLocationCategoryDelete={ onLocationCategoryDelete }
              onLocationCategoryEdit={ onLocationCategoryEdit }
              onLocationCategorySelect={ onLocationCategorySelect } />) }

          </Menu.Dropdown>
        </Menu>
      </div>
    </>
  );
};

export default LocationCategoryComponent;
