import { FC, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import { REGULAR_REFRESH_TOKEN_KEY, REGULAR_TOKEN_KEY } from "@/common/util/auth";
import browserDatabase from "@/common/util/browser-database";
import { handleError } from "@/common/util/common-functions";
import { logger } from "@/common/util/ConsoleLogger";
import LoaderScreen from '@/core/components/SSOIntegration/LoaderScreen';
import { useSSOIntegrationStyles } from "@/core/components/SSOIntegration/SSOIntegration.styles";
import { redirectWithinRestrataProductEcosystem } from "@/core/navigation/util/navigation";
import { Dispatch } from "@/core/store";
import { CONTEXT_TYPES, ContextTypes } from "@/core/store/shared-data/sharedData.types";
import {
  useAuthContext
} from "@/tenant-context/core/context/Auth.context";
import { validateProxyToken } from "@/tenant-context/navigation/api/navigation";
import { NavigationItemId, ProductArea } from "@/tenant-context/navigation/components/NavigationSidebar/NavigationSidebar.config";

const SSOIntegrationComponent: FC = () => {
  const { classes } = useSSOIntegrationStyles();

  const [error, setError] = useState<string | undefined>(undefined);
  const [cookies, _setCookie, removeCookie] = useCookies(['GLOBAL_KEY_COOKIE']);

  const [params] = useSearchParams(); 
  const navigateTo = params.get('navigateTo') || '';
  const navigatedFrom = (params.get('origin') || '') as ProductArea;
  const context = params.get('context') || 'tenant';

  const navigate = useNavigate();

  const {
    sharedData:{
      setContext
    }
  } = useDispatch<Dispatch>();

  const {
    isAuthenticated,
    setAccessToken,
    setTenantToken,
    setTenantRefreshToken
  } = useAuthContext();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleProxyToken = useCallback(async (authCookie: any)=>{
    const { proxyToken } = authCookie;
    if(!proxyToken){
      logger.error("No proxy token or access token found in cookies!");
      setError('Authentication error, please try again later!');
      return;
    }

    const accessToken = browserDatabase.getItem(REGULAR_TOKEN_KEY) as string;

    if(!accessToken){
      logger.error("Access token not found.");
      setError('Authentication error. Redirecting you back to Cross Tenant Portal. Just a moment, please.');

      const item = {
        area: ProductArea.UnifiedExperience,
        unifiedExperienceApplication: 'landing-page',
        title: 'Back to CrossTenant',
        path: '',
        itemId: NavigationItemId.CrossTenant
      };

      redirectWithinRestrataProductEcosystem(item, navigate);
      return;
    }

    const generatedTokens =
     await validateProxyToken(proxyToken, accessToken).catch(handleError);

    if(!generatedTokens){
      logger.error("Invalid tokens present in cookies.");
      setError('Authentication error, please try again later!');
      return;
    }

    const { platformAccessToken, tenantAccessToken, tenantRefreshToken } = generatedTokens;

    if (platformAccessToken) {
      setAccessToken(platformAccessToken);
    }

    if (tenantAccessToken) {
      setTenantToken(tenantAccessToken);
    }

    if (tenantRefreshToken) {
      browserDatabase.setItem(REGULAR_REFRESH_TOKEN_KEY, tenantRefreshToken);
      setTenantRefreshToken(tenantRefreshToken);
    }

    removeCookie('GLOBAL_KEY_COOKIE');

    const url = `${window.location.protocol}//${window.location.host.concat(navigateTo)}`;
    window.open(url, '_self');

  }, [navigate, navigateTo, removeCookie, setAccessToken, setTenantRefreshToken, setTenantToken]);

  useEffect(()=>{
    if(context === CONTEXT_TYPES.tenant || context === CONTEXT_TYPES.platform){
      setContext(context as ContextTypes);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!cookies || isAuthenticated) {
      return;
    }

    const authCookie = cookies['GLOBAL_KEY_COOKIE'];

    if (!authCookie) {
      logger.error("No cookie found!");
      setError('Authentication error, please try again later!');
      return;
    }

    //Handle token validation when navigated from Offline Proxy
    if(navigatedFrom === ProductArea.OfflineProxy){
      handleProxyToken(authCookie);
      return;
    }
    
    const accessToken = authCookie?.accessToken;
    const refreshToken = authCookie?.refreshToken;
    const tenantToken = authCookie?.tenantToken;
    const tenantRefreshToken = authCookie?.tenantRefreshToken;
    
    const isValid = accessToken && (context === CONTEXT_TYPES.tenant ? tenantToken : true);

    if (!isValid) {
      logger.error("No access token or tenant token found in cookies!");
      setError('Authentication error, please try again later!');
      return;
    }

    if (accessToken) {
      setAccessToken(accessToken);
    }

    if (tenantToken) {
      setTenantToken(tenantToken);
    }

    if (refreshToken) {
      browserDatabase.setItem(REGULAR_REFRESH_TOKEN_KEY, refreshToken);
    }

    if (tenantRefreshToken) {
      setTenantRefreshToken(tenantRefreshToken);
    }

    removeCookie('GLOBAL_KEY_COOKIE');

    const url = `${window.location.protocol}//${window.location.host.concat(navigateTo)}`;
    window.open(url, '_self');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ cookies ]);

  return (
    <div className={ classes.container }>
      <LoaderScreen isLoading={ true } error={ error }/>
    </div>
  );
};

export default SSOIntegrationComponent;
