import { FileWithPath } from '@mantine/dropzone';
import { createModel } from "@rematch/core";

import { GridParams } from "@/common/types/ag-grid";
import { PaginatedResult, ReferenceType } from '@/common/types/reference-data';
import { handleError } from '@/common/util/common-functions';
import { showNotification } from "@/common/util/notification";
import { Dispatch, RootModel, RootState, RootStateWithLoading } from "@/core/store";
import {
  ILocation
} from "@/tenant-context/control-location-configuration/types/ManageLocations.types";
import {
  assignAccessCard,
  createCompany,
  createDocumentForUser,
  createProfileDetails,
  createProfileTagList,
  deleteAddressForProfile,
  deleteCompany,
  deleteContactDetailsFromProfile,
  deleteDocumentForUser,
  deleteEmergencyContactForProfile,
  deleteFileFromDocumentForUser,
  getAccessCardListWithProfileAssigment, getAccessCardPrivilege,
  getBulkProfileUploadStatusCSV, getBulkProfileUploadStatusGrid,
  getCompanies,
  getCompany,
  getCompanyDetails,
  getCompanyLogs,
  getDistinguishingFeatures,
  getDocumentForUser,
  getGeneralConfig, getLocationsList,
  getMedicalDetails,
  getPersonalAttributes,
  getPersonalDetails,
  getProfileTags,
  getProfileTagsForPerson,
  listAddresses,
  listContactDetails,
  listDocuments, listDocumentsGrid,
  listEmergencyContacts,
  listGroups, listGroupsGrid,
  unAssignAccessCard,
  updateCompany,
  updateDocumentDataForUser,
  updateProfileDetails,
  updateProfilePictureForUser,
  updateProfileTag,
  uploadBulkUserProfilesFile,
  uploadBulkUserProfilesStatus,
  uploadFilesForDocument
} from "@/tenant-context/control-profile/api/profile";
import {
  DEFAULT_INIT_COLOR
} from '@/tenant-context/control-profile/components/ui/AddTagsPopover/AddTagsPopover.config';
import { bulkProfileListQueryString } from "@/tenant-context/control-profile/store/profile/bulk-profile-filter-query";
import { addParsedPhonesToContacts } from "@/tenant-context/control-profile/store/profile/parse-phones";
import { PROFILE_PICTURE } from '@/tenant-context/control-profile/store/profile/picture';
import {
  AccessCard,
  AssignAccessCard, BulkProfilesFileUploadStatus,
  BulkUpdateProfileStatus,
  Company, CompanyLog,
  CreateCompany,
  CreateContactDetail,
  CreateDocumentBody,
  CreateEmergencyContact,
  CreatePersonAddress,
  MutableDistinguishingFeatures,
  MutableProfileCompanyDetails,
  MutableProfileGeneral,
  MutableProfileMedicalDetails,
  MutableProfilePersonalAttributes,
  ProfileAddress,
  ProfileCompany,
  ProfileConfiguration,
  ProfileContact,
  ProfileDistinguishingFeatures,
  ProfileDocumentDetails,
  ProfileEmergencyContact,
  ProfileGeneral,
  ProfileGroup,
  ProfileMedical,
  ProfilePersonalAttributes, ProfileTag,
  ReferenceDataList,
  UpdateContactDetail,
  UpdateEmergencyContact,
  UpdatePersonAddress,
  UpdateProfileDocumentDetails, UploadedProfileStatusGrid
} from "@/tenant-context/control-profile/types/profile";
import { formatSortingParameters } from "@/tenant-context/control-tenant-admin/util/formatSortingParameters";

import { getBulkProfilesUploadStatus, getReferenceData } from '../../api/profile-list';

type ProfileState = {
  id?: string,
  isOpen: boolean,
  generalConfig?: ProfileConfiguration,
  general?: ProfileGeneral,
  contacts?: PaginatedResult<ProfileContact> | null,
  emergencyContacts?: PaginatedResult<ProfileEmergencyContact> | null,
  addresses?: PaginatedResult<ProfileAddress> | null,
  documents?: PaginatedResult<ProfileDocumentDetails> | null,
  groups?: PaginatedResult<ProfileGroup> | null,
  companyDetails?: ProfileCompany | null,
  medicalDetails?: ProfileMedical | null,
  personalAttributes?: ProfilePersonalAttributes | null,
  distinguishingFeatures?: ProfileDistinguishingFeatures | null,
  isAddNewProfile: boolean
  referenceData: ReferenceDataList
  companies?: PaginatedResult<Company> | null,
  loggedUser?: ProfileGeneral,
  profileBulkUploadPercentage?: number,
  bulkProfileFileUploadingStatus?: 'in-progress' | 'successful' | 'failed',
  uploadFailedMessage?: string,
  uploadSuccessMessage?: string,
  isAllProfilesChecked: boolean
  isPageProfiledChecked: boolean
  selectedProfileIds?: Array<string>
  deselectedProfileIds?: Array<string>
  accessCardListSearchResults?: AccessCard[]
  accessCardListActiveResults?: AccessCard[]
  isNewAccessCardAssigned: boolean,
  accessCardListSearchResultsLoading: boolean
  profileTagsList?: ProfileTag[]
  addedProfileTagsListOnPopover?: ProfileTag[]
  profileTagsListForProfile?: ProfileTag[]
  selectedTagToEdit?: ProfileTag
  profileTagsPopoverMode?: 'ADD' | 'CREATE' | 'EDIT'
  initTagColor?: string
  isCompanyLoading: boolean
  activeCompany?: Company,
  isCompanyLogsLoading: boolean
  isAccessCardPrivilege: boolean
  locationsList?: PaginatedResult<ILocation>
  bulkProfilesFileUploadStatus?: BulkProfilesFileUploadStatus
  uploadedProfilesStatusGrid?: UploadedProfileStatusGrid
  isLoading: boolean
  isPeopleDocumentsUpdated: boolean
};

const profileDataModel = {
  name: 'profile',
  state: {
    id: '',
    general: undefined,
    isOpen: false,
    isAddNewProfile: false,
    referenceData: {} as ReferenceDataList,
    selectedProfileIds: [],
    deselectedProfileIds: [],
    isAllProfilesChecked: false,
    isPageProfiledChecked: false,
    accessCardListSearchResults: undefined,
    accessCardListActiveResults: undefined,
    isNewAccessCardAssigned: false,
    accessCardListSearchResultsLoading: false,
    isCompanyLoading: false,
    isCompanyLogsLoading: false,
    isAccessCardPrivilege: false,
    isLoading: false,
    isPeopleDocumentsUpdated: false
  } as ProfileState,
  reducers: {
    SET_IS_OPEN: (state: ProfileState, isOpen: boolean) => ({
      ...state,
      isOpen
    }),

    // TODO reuse already loaded data if ID is the same
    SET_PROFILE_ID: (state: ProfileState, id?: string) => ({
      ...state,
      id
    }),

    SET_GENERAL: (state: ProfileState, general: ProfileState['general']) => ({
      ...state,
      general
    }),

    SET_GENERAL_CONFIG: (state: ProfileState, generalConfig: ProfileState['generalConfig']) => ({
      ...state,
      generalConfig
    }),

    SET_CONTACTS: (state: ProfileState, contacts: ProfileState['contacts']) => ({
      ...state,
      contacts: addParsedPhonesToContacts(contacts)
    }),

    SET_EMERGENCY_CONTACTS: (state: ProfileState, emergencyContacts: ProfileState['emergencyContacts']) => ({
      ...state,
      emergencyContacts: emergencyContacts
    }),

    SET_ADDRESSES: (state: ProfileState, addresses: ProfileState['addresses']) => ({
      ...state,
      addresses
    }),

    SET_DOCUMENTS: (state: ProfileState, documents: ProfileState['documents']) => ({
      ...state,
      documents
    }),

    SET_GROUPS: (state: ProfileState, groups: ProfileState['groups']) => ({
      ...state,
      groups
    }),

    SET_COMPANY_DETAILS: (state: ProfileState, companyDetails: ProfileState['companyDetails']) => ({
      ...state,
      companyDetails
    }),

    SET_MEDICAL_DETAILS: (state: ProfileState, medicalDetails: ProfileState['medicalDetails']) => ({
      ...state,
      medicalDetails
    }),

    SET_PERSONAL_ATTRIBUTES: (state: ProfileState, personalAttributes: ProfileState['personalAttributes']) => ({
      ...state,
      personalAttributes
    }),

    SET_DISTINGUISHING_FEATURES: (state: ProfileState, distinguishingFeatures: ProfileState['distinguishingFeatures']) => ({
      ...state,
      distinguishingFeatures
    }),

    SET_IS_ADD_NEW_PROFILE: (state: ProfileState, isAddNewProfile: ProfileState['isAddNewProfile']) => ({
      ...state,
      isAddNewProfile
    }),
    SET_PROFILE_REFERENCE_DATA: (state: ProfileState, referenceData: ProfileState['referenceData']) => ({
      ...state,
      referenceData: { ...referenceData, ...state.referenceData }
    }),
    SET_COMPANIES: (state: ProfileState, companies: ProfileState['companies']) => ({
      ...state,
      companies
    }),
    SET_LOGGED_USER: (state: ProfileState, loggedUser: ProfileState['loggedUser']) => ({
      ...state,
      loggedUser
    }),
    SET_PROFILE_BULK_UPLOAD_PERCENTAGE: (state: ProfileState, profileBulkUploadPercentage: ProfileState['profileBulkUploadPercentage']) => ({
      ...state,
      profileBulkUploadPercentage
    }),
    SET_BULK_PROFILE_FILEUPLOADING_STATUS: (state: ProfileState, bulkProfileFileUploadingStatus: ProfileState['bulkProfileFileUploadingStatus']) => ({
      ...state,
      bulkProfileFileUploadingStatus
    }),
    SET_UPLOAD_FAILED_MESSAGE: (state: ProfileState, uploadFailedMessage: ProfileState['uploadFailedMessage']) => ({
      ...state,
      uploadFailedMessage
    }),
    SET_UPLOAD_SUCCESS_MESSAGE: (state: ProfileState, uploadSuccessMessage: ProfileState['uploadSuccessMessage']) => ({
      ...state,
      uploadSuccessMessage
    }),
    SET_PROFILE_TAGS_LIST: (state: ProfileState, profileTagsList: ProfileState['profileTagsList']) => ({
      ...state,
      profileTagsList
    }),
    SET_ADDED_PROFILE_TAGS_LIST_ON_POPOVER: (state: ProfileState, addedProfileTagsListOnPopover: ProfileState['addedProfileTagsListOnPopover']) => ({
      ...state,
      addedProfileTagsListOnPopover
    }),
    SET_PROFILE_TAGS_LIST_FOR_PROFILE: (state: ProfileState, profileTagsListForProfile: ProfileState['profileTagsListForProfile']) => ({
      ...state,
      profileTagsListForProfile
    }),
    SET_SELECTED_TAG_TO_EDIT: (state: ProfileState, selectedTagToEdit: ProfileState['selectedTagToEdit']) => ({
      ...state,
      selectedTagToEdit
    }),
    SET_PROFILE_TAGS_POPOVER_MODE: (state: ProfileState, profileTagsPopoverMode: ProfileState['profileTagsPopoverMode']) => ({
      ...state,
      profileTagsPopoverMode
    }),
    SET_INIT_TAG_COLOR: (state: ProfileState, initTagColor: ProfileState['initTagColor']) => ({
      ...state,
      initTagColor
    }),
    SET_SELECTED_PROFILES_IDS: (state: ProfileState, selectedProfileIds: ProfileState['selectedProfileIds']) => ({
      ...state,
      selectedProfileIds
    }),
    SET_DESELECTED_PROFILES_IDS: (state: ProfileState, deselectedProfileIds: ProfileState['deselectedProfileIds']) => ({
      ...state,
      deselectedProfileIds
    }),
    SET_IS_ALL_PROFILES_CHECKED: (state: ProfileState, isAllProfilesChecked: ProfileState['isAllProfilesChecked']) => ({
      ...state,
      isAllProfilesChecked
    }),
    SET_IS_PAGE_PROFILES_CHECKED: (state: ProfileState, isPageProfiledChecked: ProfileState['isPageProfiledChecked']) => ({
      ...state,
      isPageProfiledChecked
    }),
    SET_ACCESS_CARD_LIST_SEARCH_RESULT: (state: ProfileState, accessCardListSearchResults: ProfileState['accessCardListSearchResults']) => ({
      ...state,
      accessCardListSearchResults
    }),
    SET_ACCESS_CARD_LIST_ACTIVE_RESULT: (state: ProfileState, accessCardListActiveResults: ProfileState['accessCardListActiveResults']) => ({
      ...state,
      accessCardListActiveResults
    }),
    SET_IS_NEW_ACCESS_CARD_ASSIGNED: (state: ProfileState, isNewAccessCardAssigned: ProfileState['isNewAccessCardAssigned']) => ({
      ...state,
      isNewAccessCardAssigned
    }),
    SET_ACCESS_CARD_LIST_SEARCH_RESULT_LOADING: (state: ProfileState, accessCardListSearchResultsLoading: ProfileState['accessCardListSearchResultsLoading']) => ({
      ...state,
      accessCardListSearchResultsLoading
    }),
    SET_IS_COMPANY_LOADING: (state: ProfileState, isCompanyLoading: ProfileState['isCompanyLoading']) => ({
      ...state,
      isCompanyLoading
    }),
    SET_ACTIVE_COMPANY: (state: ProfileState, activeCompany: ProfileState['activeCompany']) => ({
      ...state,
      activeCompany
    }),
    SET_IS_COMPANY_LOGS_LOADING: (state: ProfileState, isCompanyLogsLoading: ProfileState['isCompanyLogsLoading']) => ({
      ...state,
      isCompanyLogsLoading
    }),
    SET_IS_ACCESS_CARD_PRIVILEGE: (state: ProfileState, isAccessCardPrivilege: ProfileState['isAccessCardPrivilege']) => ({
      ...state,
      isAccessCardPrivilege
    }),
    SET_BULK_PROFILES_FILE_UPLOAD_STATUS: (
      state: ProfileState,
      bulkProfilesFileUploadStatus: ProfileState['bulkProfilesFileUploadStatus']
    ) => ({
      ...state,
      bulkProfilesFileUploadStatus
    }),
    SET_LOCATIONS_LIST: (state: ProfileState, locationsList: ProfileState['locationsList']) => ({
      ...state,
      locationsList
    }),
    SET_IS_LOADING: (
      state: ProfileState,
      isLoading: ProfileState["isLoading"]
    ) => ({
      ...state,
      isLoading
    }),
    SET_IS_PEOPLE_DOCUMENTS_UPDATED: (
      state: ProfileState,
      isPeopleDocumentsUpdated: ProfileState["isPeopleDocumentsUpdated"]
    ) => ({
      ...state,
      isPeopleDocumentsUpdated
    }),
    SET_UPLOADED_PROFILES_STATUS_GRID: (
      state: ProfileState,
      uploadedProfilesStatusGrid: ProfileState["uploadedProfilesStatusGrid"]
    ) => ({
      ...state,
      uploadedProfilesStatusGrid
    })
  },
  effects: (dispatch: Dispatch) => ({
    openBigProfile(id: string): void {
      dispatch.profile.SET_PROFILE_ID(id);
      dispatch.profile.SET_IS_OPEN(true);
    },

    hideBigProfile(_: void): void {
      dispatch.profile.SET_IS_OPEN(false);
    },

    closeBigProfile(_: void): void {
      dispatch.profile.SET_IS_OPEN(false);

      dispatch.profile.SET_PROFILE_ID(undefined);
      dispatch.profile.SET_GENERAL(undefined);
      dispatch.profile.SET_CONTACTS(undefined);
      dispatch.profile.SET_EMERGENCY_CONTACTS(undefined);
      dispatch.profile.SET_ADDRESSES(undefined);
      dispatch.profile.SET_DOCUMENTS(undefined);
      dispatch.profile.SET_GROUPS(undefined);
      dispatch.profile.SET_COMPANY_DETAILS(undefined);
      dispatch.profile.SET_MEDICAL_DETAILS(undefined);
      dispatch.profile.SET_PERSONAL_ATTRIBUTES(undefined);
      dispatch.profile.SET_DISTINGUISHING_FEATURES(undefined);
    },

    async loadGeneralForBigProfile(userId: string, _state: RootState): Promise<void> {

      const personalDetails = await getPersonalDetails(userId);

      dispatch.profile.SET_GENERAL(personalDetails);
    },

    async setLoggedUser(userId: string, _state: RootState): Promise<void> {
      const personalDetails = await getPersonalDetails(userId);

      dispatch.profile.SET_LOGGED_USER(personalDetails);
    },

    async loadGeneral(_: void, state: RootState): Promise<void> {
      const {
        profile: {
          id
        }
      } = state as unknown as RootStateWithLoading;

      if (!id) {
        return;
      }

      const personalDetails = await getPersonalDetails(id);

      dispatch.profile.SET_GENERAL(personalDetails);
    },

    async updateGeneral(
      formData: Partial<MutableProfileGeneral>,
      state: RootState
    ): Promise<boolean> {
      const {
        profile: {
          id,
          general
        }
      } = state;

      if (id && general) {
        const {
          profilePictureUrl: _0,
          profilePictureThumbnailUrl: _1,
          ...safeGeneral
        } = general;
  
        const success = await updateProfileDetails(id, {
          ...safeGeneral,
          ...formData,
          profilePictureUrl: PROFILE_PICTURE
        }, 'UpdatePersonalDetail').catch(handleError);

        if(!success){
          return false;
        } else {
          dispatch.globalPeople.invalidatePersonInfo(id);
        }
      }
  
      if (!id) {
        const createdProfile = await createProfileDetails({
          ...formData,
          source: "restrata.net",
          sourceProfileId: "restrata",
          profilePictureUrl: ''
        }, 'CreatePersonalDetail').catch(handleError);

        if(!createdProfile){
          return false;
        }
        
        if(createdProfile){
          const { _id } = createdProfile;
          dispatch.profile.SET_PROFILE_ID(_id);
        }
      }
  
      dispatch.profile.loadGeneral();
      return true;
      
    },

    async updateCompanyDetails(
      formData: MutableProfileCompanyDetails,
      state: RootState
    ): Promise<boolean> {
      const {
        profile: {
          id,
          companyDetails = {}
        }
      } = state;

      if (typeof id !== 'string' || typeof companyDetails === 'undefined') {
        return false;
      }

      const data: MutableProfileCompanyDetails = {
        ...companyDetails,
        ...formData
      };

      const success = await updateProfileDetails(id, data, 'UpdateCompanyDetail').catch(handleError);
      if(!success){
        return false;
      }
      dispatch.profile.loadCompanyDetails();
      return true;
     
    },

    async updateMedicalDetails(
      formData: MutableProfileMedicalDetails,
      state: RootState
    ): Promise<boolean> {
      const {
        profile: {
          id,
          medicalDetails = {}
        }
      } = state;
      
      if (typeof id !== 'string') {
        return false;
      }
  
      const success = await updateProfileDetails(id, {
        ...medicalDetails,
        ...formData
      }, 'UpdateMedicalDetail').catch(handleError);

      if(!success){
        return false;
      }

      dispatch.profile.loadMedicalDetails();
      return true;
    },

    async updatePersonalAttributes(
      formData: MutableProfilePersonalAttributes,
      state: RootState
    ): Promise<boolean> {
      const {
        profile: {
          id,
          personalAttributes = {}
        }
      } = state;

      if (typeof id !== 'string') {
        return false;
      }

      const success = await updateProfileDetails(id, {
        ...personalAttributes,
        ...formData
      }, 'UpdatePersonalAttribute').catch(handleError);

      if(!success){
        return false;
      }
  
      dispatch.profile.loadPersonalAttributes();
      return true;
   
    },

    async updateDistinguishingFeatures(
      formData: MutableDistinguishingFeatures,
      state: RootState
    ): Promise<boolean> {
      const {
        profile: {
          id,
          distinguishingFeatures = {}
        }
      } = state;

      if (typeof id !== 'string') {
        return false;
      }

      const success = await updateProfileDetails(id, {
        ...distinguishingFeatures,
        ...formData
      }, 'UpdateDistinguishingFeature').catch(handleError);

      if(!success){
        return false;
      }

      dispatch.profile.loadDistinguishingFeatures();
      return true;
    },

    async loadPersonalContacts(_: void, state: RootState): Promise<void> {
      const { profile: { id } } = state;
      if (typeof id !== 'string') {
        return;
      }

      const contacts = await listContactDetails(id);

      dispatch.profile.SET_CONTACTS(contacts);
    },

    async createPersonalContact(
      formData: (CreateContactDetail | UpdateContactDetail) & { mode: 'add' | 'edit' },
      state: RootState
    ): Promise<boolean> {
      const { profile: { id } } = state;
      if (typeof id !== 'string') {
        return false;
      }

      const {
        mode,
        ...safeFormData
      } = formData;

      const success = await updateProfileDetails(
        id,
        safeFormData,
        mode === 'add' ? 'CreateContactDetail' : 'UpdateContactDetail'
      ).catch(handleError);

      if(!success){
        return false;
      }

      await dispatch.profile.loadPersonalContacts();
      await dispatch.userProfile.reloadMiniProfileData();

      return true;
    
    },

    async modifyAddress(
      formData: (CreatePersonAddress | UpdatePersonAddress) & { mode: 'add' | 'edit' },
      state: RootState
    ): Promise<boolean> {
      const { profile: { id } } = state;
      if (typeof id !== 'string') {
        return false;
      }
      const {
        mode,
        ...cleanFormData
      } = formData;

  
      const success = await updateProfileDetails(
        id,
        cleanFormData,
        mode === 'add' ? 'CreatePersonAddress' : 'UpdatePersonAddress'
      ).catch(handleError);

      if(!success){
        return false;
      }

      await dispatch.profile.loadAddresses();

      return true;

    },

    async deleteAddress(
      addressId: string,
      state: RootState
    ): Promise<boolean> {
      const { profile: { id } } = state;
      if (typeof id !== 'string') {
        return false;
      }

      const success = await deleteAddressForProfile(
        id,
        addressId
      ).catch(handleError);

      if(!success){
        return false;
      }

      await dispatch.profile.loadAddresses();

      return true;
   
    },

    async deleteEmergencyContactForProfile(
      emergencyContactId: string,
      state: RootState
    ): Promise<boolean> {
      const { profile: { id } } = state;
      if (typeof id !== 'string') {
        return false;
      }

      const success = await deleteEmergencyContactForProfile(
        id,
        emergencyContactId
      ).catch(handleError);

      if(!success){
        return false;
      }

      await dispatch.profile.loadEmergencyContacts();

      return true;
    },

    async loadEmergencyContacts(_: void, state: RootState): Promise<void> {
      const { profile: { id } } = state;
      if (typeof id !== 'string') {
        return;
      }

      const emergencyContacts = await listEmergencyContacts(id);

      dispatch.profile.SET_EMERGENCY_CONTACTS(emergencyContacts);
    },

    async modifyEmergencyContact(
      formData: (CreateEmergencyContact | UpdateEmergencyContact) & { mode: 'add' | 'edit' },
      state: RootState
    ): Promise<boolean> {
      const { profile: { id } } = state;
      if (typeof id !== 'string') {
        return false;
      }

      const {
        mode,
        ...cleanFormData
      } = formData;

      
      const success = await updateProfileDetails(
        id,
        cleanFormData,
        mode === 'add' ? 'CreateEmergencyContact' : 'UpdateEmergencyContact'
      ).catch(handleError);

      if(!success){
        return false;
      }

      await dispatch.profile.loadEmergencyContacts();

      return true;
    

 
    },

    async loadAddresses(_: void, state: RootState): Promise<void> {
      const { profile: { id } } = state;
      if (typeof id !== 'string') {
        return;
      }

      const addresses = await listAddresses(id);

      dispatch.profile.SET_ADDRESSES(addresses);
    },

    async loadDocuments(_: void, state: RootState): Promise<void> {
      const { profile: { id } } = state;
      if (typeof id !== 'string') {
        return;
      }

      const documents = await listDocuments(id);

      dispatch.profile.SET_DOCUMENTS(documents);
    },

    async loadDocumentsGrid(payload: {
      gridParams: GridParams,
    }, state: RootState): Promise<PaginatedResult<ProfileDocumentDetails>> {
      const { profile: { id } } = state;
      if (typeof id !== 'string') {
        return {
          totalItems: 0,
          totalPages: 0,
          currentPage: 0,
          items: []
        };
      }

      return listDocumentsGrid(
        id,
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort
      );
    },

    async loadGroupsGrid (payload: {
      gridParams: GridParams,
    }, state: RootState): Promise<PaginatedResult<ProfileGroup>> {
      const { profile: { id } } = state;
      if (typeof id !== 'string') {
        return {
          totalItems: 0,
          totalPages: 0,
          currentPage: 0,
          items: []
        };
      }

      const groups = await listGroupsGrid(
        id,
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort
      );

      return groups;
    },

    async loadGroups(_: void, state: RootState): Promise<void> {
      const { profile: { id } } = state;
      if (typeof id !== 'string') {
        return;
      }

      const groups = await listGroups(id);

      dispatch.profile.SET_GROUPS(groups);
    },

    async getCompanyLogs(payload: {
      gridParams: GridParams,
    }, state: RootState): Promise<PaginatedResult<CompanyLog>> {
      const { profile: { activeCompany } } = state;
      if (!activeCompany?._id) {
        return (
          {
            totalItems: 0,
            totalPages: 0,
            currentPage: 0,
            items: []
          }
        );
      }

      dispatch.profile.SET_IS_COMPANY_LOGS_LOADING(true);
      const logs = await getCompanyLogs(
        activeCompany?._id,
        payload.gridParams.page,
        payload.gridParams.size
      ).finally(() => {
        dispatch.profile.SET_IS_COMPANY_LOGS_LOADING(false);
      });

      return logs;
    },

    async getCompany(id: string): Promise<void> {

      await getCompany(id).then((company) => dispatch.profile.SET_ACTIVE_COMPANY(company)).catch(handleError);

    },

    async loadCompanyDetails(_: void, state: RootState): Promise<void> {
      const { profile: { id, general } } = state;

      if (typeof id !== 'string') {
        return;
      }

      // eslint-disable-next-line fp/no-let
      let pid;

      if (general && general._id) {
        pid = general._id;
      } else {
        const personalDetails = await getPersonalDetails(id);
        pid = personalDetails._id;
      }

      if (typeof pid !== 'string') {
        return;
      }

      try {
        const companyDetails = await getCompanyDetails(pid);
        dispatch.profile.SET_COMPANY_DETAILS(companyDetails);

        return;
      } catch (err) {
        dispatch.profile.SET_COMPANY_DETAILS(null);
      }
    },

    async loadMedicalDetails(_: void, state: RootState): Promise<void> {
      const { profile: { id, general } } = state;

      if (typeof id !== 'string') {
        return;
      }

      // eslint-disable-next-line fp/no-let
      let pid;

      if (general && general._id) {
        pid = general._id;
      } else {
        const personalDetails = await getPersonalDetails(id);
        pid = personalDetails._id;
      }

      if (typeof pid !== 'string') {
        return;
      }

      try {
        const medicalDetails = await getMedicalDetails(pid);
        dispatch.profile.SET_MEDICAL_DETAILS(medicalDetails);
      } catch (err) {
        dispatch.profile.SET_MEDICAL_DETAILS(null);
      }
    },

    async loadPersonalAttributes(_: void, state: RootState): Promise<void> {
      const { profile: { id, general } } = state;

      if (typeof id !== 'string') {
        return;
      }

      // eslint-disable-next-line fp/no-let
      let pid;

      if (general && general._id) {
        pid = general._id;
      } else {
        const personalDetails = await getPersonalDetails(id);
        pid = personalDetails._id;
      }

      if (typeof pid !== 'string') {
        return;
      }

      try {
        const personalAttributes = await getPersonalAttributes(pid);
        dispatch.profile.SET_PERSONAL_ATTRIBUTES(personalAttributes);
      } catch (err) {
        dispatch.profile.SET_PERSONAL_ATTRIBUTES(null);
      }
    },

    async loadDistinguishingFeatures(_: void, state: RootState): Promise<void> {
      const { profile: { id, general } } = state;

      if (typeof id !== 'string') {
        return;
      }

      // eslint-disable-next-line fp/no-let
      let pid;

      if (general && general._id) {
        pid = general._id;
      } else {
        const personalDetails = await getPersonalDetails(id);
        pid = personalDetails._id;
      }

      if (typeof pid !== 'string') {
        return;
      }

      try {
        const distinguishingFeatures = await getDistinguishingFeatures(pid);
        dispatch.profile.SET_DISTINGUISHING_FEATURES(distinguishingFeatures);

      } catch (err) {
        dispatch.profile.SET_DISTINGUISHING_FEATURES(null);
      }
    },

    async getPidForPerson(_: void, state: RootState): Promise<string | undefined> {
      const { profile: { id, general } } = state;

      if (typeof id !== 'string') {
        return undefined;
      }

      // TODO refactor pid retrieval
      // eslint-disable-next-line fp/no-let
      let pid;

      if (general && general._id) {
        pid = general._id;
      } else {
        const personalDetails = await getPersonalDetails(id);
        pid = personalDetails._id;
      }

      if (typeof pid !== 'string') {
        return undefined;
      }

      return pid;
    },

    async createDocument(payload: {
      files: FileWithPath[],
      body: CreateDocumentBody
    }, state: RootState): Promise<boolean> {
      const { files, body } = payload;
      const { profile: { id } } = state;

      if (typeof id !== 'string') {
        return false;
      }

      const formData = new FormData();
      formData.append('body', JSON.stringify(body));

      files.forEach((file) => {
        formData.append('files', file, file.name);
      });

      const success = await createDocumentForUser(id, formData).catch(handleError);

      if(!success){
        return false;
      }

      dispatch.profile.loadDocuments();
      return true;

    },

    async uploadFilesToDocument(payload: {
      files: FileWithPath[],
      documentId: string
    }, state: RootState): Promise<boolean> {
      const { files, documentId } = payload;
      const { profile: { id } } = state;

      if (typeof id !== 'string') {
        return false;
      }

      const formData = new FormData();

      files.forEach((file) => {
        formData.append('files', file, file.name);
      });
    
      const success = await uploadFilesForDocument(id, documentId, formData).catch(handleError);
      if(!success){
        return false;
      }

      return true;
    },

    async updateDocumentDetails(payload: {
      data: UpdateProfileDocumentDetails,
      documentId: string
    }, state: RootState): Promise<boolean> {
      const { data, documentId } = payload;
      const { profile: { id } } = state;

      if (typeof id !== 'string') {
        return false;
      }
      const success = await updateDocumentDataForUser(id, documentId, data).catch(handleError);
      if(!success){
        return false;
      }

      return true;
  
    },

    async deleteDocument(documentId: string, state: RootState): Promise<boolean> {
      const {
        profile: { id }
      } = state;

      if (!id) {
        return false;
      }
      const success = await deleteDocumentForUser(id, documentId).catch(handleError);
      if(!success){
        return false;
      }
      dispatch.profile.loadDocuments();
      return true;

    },

    async getDocument(documentId: string, state: RootState): Promise<ProfileDocumentDetails> {
      const {
        profile: { id }
      } = state;

      if (!id) {
        throw new Error('No user id to fetch the document');
      }

      return getDocumentForUser(id, documentId);
    },

    async deleteFileFromDocument(payload: {
      documentId: string,
      fileId: string
    }, state: RootState): Promise<boolean> {
      const {
        documentId,
        fileId
      } = payload;

      const {
        profile: { id }
      } = state;

      if (!id) {
        throw new Error('Invalid id');
      }
      const success = await deleteFileFromDocumentForUser(id, documentId, fileId).catch(handleError);
      if(!success){
        return false;
      }

      return true;

    },

    async setProfilePicture(file: FileWithPath, state: RootState): Promise<unknown> {
      const { profile: { id, loggedUser } } = state;

      if (typeof id !== 'string') {
        return;
      }

      const base64EncodedImage: string = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result === 'string') {
            resolve(reader.result);
          } else {
            reject(new Error('Failed to load file'));
          }
        };
        reader.readAsDataURL(file);
      });

      // TODO profilePictureThumbnail to be generated on BE
      const body = {
        profilePictureThumbnail: base64EncodedImage,
        profilePicture: base64EncodedImage
      };

      await updateProfilePictureForUser(id, body);
      
      if(id === loggedUser?.profileId || id === loggedUser?._id){
        this.setLoggedUser(id, state);
      }

      return Promise.all([
        dispatch.profile.loadGeneral(),
        dispatch.userProfile.reloadMiniProfileData()
      ]);
    },

    async loadGeneralConfig(): Promise<void> {
      const generalConfig = await getGeneralConfig();

      dispatch.profile.SET_GENERAL_CONFIG(generalConfig);
    },

    async deletePersonalContact(contactDetailsId: string, state: RootState): Promise<boolean> {
      const {
        profile: { id }
      } = state;

      if (!id) {
        return false;
      }

      const success = await deleteContactDetailsFromProfile(id, contactDetailsId).catch(handleError);

      if(!success){
        return false;
      }
      
      await Promise.all([
        dispatch.profile.loadPersonalContacts(),
        dispatch.userProfile.reloadMiniProfileData()
      ]);

      return true;

    },

    async getProfileReferenceData(referenceDataType: ReferenceType, state: RootState): Promise<void> {
      const referenceData = await getReferenceData(referenceDataType);

      dispatch.profile.SET_PROFILE_REFERENCE_DATA(
        { [referenceDataType]: referenceData?.items, ...state.profile?.referenceData }
      );
    },

    async getProfileCompanies(payload?: {
      gridParams: GridParams,
    }): Promise<PaginatedResult<Company>> {
      dispatch.profile.SET_IS_COMPANY_LOADING(true);
      const query = payload?.gridParams.searchQuery;
      if (query) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = `activeStatus LIKE %27%25${query}%25%27 OR code LIKE %27%25${query}%25%27 OR name LIKE %27%25${query}%25%27`;
      }

      const companies = await getCompanies(
        payload?.gridParams.page,
        payload?.gridParams.size,
        payload?.gridParams.sort,
        payload?.gridParams.searchQuery
      );

      dispatch.profile.SET_COMPANIES(companies);
      dispatch.profile.SET_IS_COMPANY_LOADING(false);

      return companies;
    },

    resetBulkProfileFileUploadModal(): void {
      dispatch.profile.SET_PROFILE_BULK_UPLOAD_PERCENTAGE(undefined);
      dispatch.profile.SET_BULK_PROFILE_FILEUPLOADING_STATUS(undefined);
      dispatch.profile.SET_UPLOAD_FAILED_MESSAGE(undefined);
      dispatch.profile.SET_UPLOAD_SUCCESS_MESSAGE(undefined);
    },

    async getBulkProfilesUploadStatus(
      _: void,
      _state: RootState
    ): Promise<void> {
      try {
        const bulkDataId = localStorage.getItem("bulkProfilesUploadId");
        if (!bulkDataId) {
          return;
        }
        const status = await getBulkProfilesUploadStatus(bulkDataId);

        dispatch.profile.SET_BULK_PROFILES_FILE_UPLOAD_STATUS(status);

        if (status.status === "SUCCESS") {
          localStorage.removeItem("bulkProfilesUploadId");
          dispatch.profile.SET_BULK_PROFILES_FILE_UPLOAD_STATUS(
            undefined
          );
          dispatch.userProfile.getProfileList({});
        }
        if (status.status === 'ERROR') {
          dispatch.userProfile.getProfileList({});
        } else {
          setTimeout(
            () => dispatch.profile.getBulkProfilesUploadStatus(),
            5000
          );
        }
      } catch {
        return undefined;
      }
    },

    async uploadBulkUserProfilesFile(file: FileWithPath, _state: RootState): Promise<void> {
      const formData = new FormData();
      formData.append('files', file, file.name);
      formData.append("body", '{dataType:"PROFILE"}');

      dispatch.profile.SET_PROFILE_BULK_UPLOAD_PERCENTAGE(50);

      try {
        const upload = await uploadBulkUserProfilesFile(formData);

        if(upload){
          if(upload.summary.total === 0){
            dispatch.profile.SET_BULK_PROFILE_FILEUPLOADING_STATUS('failed');
            dispatch.profile.SET_UPLOAD_FAILED_MESSAGE(`An error occurred while uploading the file. No records were inserted or updated.`);
          } else{
            await localStorage.setItem(
              "bulkProfilesUploadId",
              upload.bulkDataId
            );
            dispatch.profile.getBulkProfilesUploadStatus();
            dispatch.profile.SET_BULK_PROFILE_FILEUPLOADING_STATUS('successful');
            dispatch.profile.SET_UPLOAD_SUCCESS_MESSAGE("Please note that it will take approximately 10 to 15 minutes to process the data and add locations for each record.");
          }
        }
      } catch (e) {
        dispatch.profile.SET_BULK_PROFILE_FILEUPLOADING_STATUS('failed');
        dispatch.profile.SET_UPLOAD_FAILED_MESSAGE('An error occurred while uploading the file.');
        if (e instanceof Error) {
          dispatch.profile.SET_UPLOAD_FAILED_MESSAGE(e?.message ?? 'An error occurred while uploading the file.');
        }
      }

      dispatch.profile.SET_PROFILE_BULK_UPLOAD_PERCENTAGE(100);
      dispatch.userProfile.SET_PROFILE_LIST_FILTER_DATA({});
    },

    openProfileTagsPopover(mode: 'ADD' | 'CREATE' | 'EDIT'): void {
      dispatch.profile.SET_PROFILE_TAGS_POPOVER_MODE(mode);
    },

    resetProfileTagsPopover(): void {
      dispatch.profile.SET_PROFILE_TAGS_POPOVER_MODE(undefined);
      dispatch.profile.SET_ADDED_PROFILE_TAGS_LIST_ON_POPOVER(undefined);
      dispatch.profile.SET_SELECTED_TAG_TO_EDIT(undefined);
    },

    async loadProfileTags(_: void, _state: RootState): Promise<void> {
      try {
        const tags = await getProfileTags({});
        dispatch.profile.SET_PROFILE_TAGS_LIST(tags.items);
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            title: 'Error',
            message: e.message,
            color: 'error'
          });
        }
      }
    },

    async searchProfileTags(searchPhrase: string, _state: RootState): Promise<void> {
      try {
        const tags = await getProfileTags({ search: `(name LIKE "${searchPhrase}")` });
        dispatch.profile.SET_PROFILE_TAGS_LIST(tags.items);
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            title: 'Error',
            message: e.message,
            color: 'error'
          });
        }
      }
    },

    async loadProfileTagsForProfile(profileId: string, _state: RootState): Promise<void> {

      try {
        if (profileId) {
          const tags = await getProfileTagsForPerson(profileId);
          dispatch.profile.SET_PROFILE_TAGS_LIST_FOR_PROFILE(tags.items);
        }
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            title: 'Error',
            message: e.message,
            color: 'error'
          });
        }
      }
    },

    async submitProfileTags(_: void, state: RootState): Promise<void> {
      try {
        if (state.profile?.addedProfileTagsListOnPopover) {
          await createProfileTagList(state.profile?.addedProfileTagsListOnPopover);
          showNotification({
            title: 'Success',
            message: 'Tags were added successfully!',
            color: 'success'
          });
          dispatch.profile.resetProfileTagsPopover();

          if (state.profile.general?.profileId) {
            dispatch.profile.loadProfileTagsForProfile(state.profile.general?.profileId);
          }
        } else {
          showNotification({
            title: 'Error',
            message: 'No tags are selected',
            color: 'error'
          });
        }
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            title: 'Error',
            message: e.message,
            color: 'error'
          });
        }
      }
    },

    async updateProfileTag(_: void, state: RootState): Promise<void> {
      const selectedTagToEdit = state.profile?.selectedTagToEdit;
      const profileId = state.profile?.general?.profileId;

      try {
        if (selectedTagToEdit && selectedTagToEdit.id && profileId) {

          const newTag: ProfileTag = {
            name: selectedTagToEdit.name,
            color: selectedTagToEdit.color,
            profileId: profileId,
            type: 'UPDATE_TAG'
          };

          await updateProfileTag(newTag, selectedTagToEdit.id);

          showNotification({
            title: 'Success',
            message: 'Tag was updated successfully!',
            color: 'success'
          });

          dispatch.profile.resetProfileTagsPopover();
          dispatch.profile.loadProfileTagsForProfile(profileId);
        } else {
          showNotification({
            title: 'Error',
            message: 'Invalid update request',
            color: 'error'
          });
        }
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            title: 'Error',
            message: e.message,
            color: 'error'
          });
        }
      }
    },

    async removeTagFromProfile(tag: ProfileTag, state: RootState): Promise<void> {
      const profileId = state.profile?.general?.profileId;

      try {
        if (profileId && tag.id) {

          const newTag: ProfileTag = {
            name: tag.name,
            color: tag.color,
            profileId: profileId,
            type: 'PROFILE_REMOVE'
          };

          await updateProfileTag(newTag, tag.id);

          showNotification({
            title: 'Success',
            message: 'Tag was removed from profile successfully!',
            color: 'success'
          });

          dispatch.profile.resetProfileTagsPopover();
          dispatch.profile.loadProfileTagsForProfile(profileId);
        } else {
          showNotification({
            title: 'Error',
            message: 'Invalid update request',
            color: 'error'
          });
        }
      } catch (e) {
        if (e instanceof Error) {
          showNotification({
            title: 'Error',
            message: e.message,
            color: 'error'
          });
        }
      }
    },

    async createTagsFromCsv(csv: string, state: RootState): Promise<void> {
      const trimmedCsv = csv.trim();
      const tagNames = trimmedCsv.split(/\s*,\s*/);

      const profileTags: ProfileTag[] = [];

      tagNames.forEach((name, index) => {
        const tempUID = (Date.now() + index).toString();
        const profileTag: ProfileTag = {
          id: tempUID,
          name: name,
          color: state.profile?.initTagColor || DEFAULT_INIT_COLOR,
          profileId: state.profile?.general?.profileId
        };

        profileTags.push(profileTag);
      });

      dispatch.profile.SET_ADDED_PROFILE_TAGS_LIST_ON_POPOVER(profileTags);
    },

    removeFromProfileTagsListForProfileOnPopover(indexToRemove: number, state: RootState): void {
      const updatedTagList = state.profile?.addedProfileTagsListOnPopover?.filter(
        (_, index) => index !== indexToRemove
      );

      dispatch.profile.SET_ADDED_PROFILE_TAGS_LIST_ON_POPOVER(updatedTagList);
    },

    addTagToListOnPopover(sourceTags: ProfileTag[], state: RootState): void {

      const preparedTagsList: ProfileTag[] = sourceTags.map(sourceTag => ({
        ...sourceTag,
        profileId: state.profile?.general?.profileId
      }));

      if (!state.profile?.addedProfileTagsListOnPopover) {
        dispatch.profile.SET_ADDED_PROFILE_TAGS_LIST_ON_POPOVER(preparedTagsList);
        return;
      }

      const updatedTagList = [...state.profile?.addedProfileTagsListOnPopover, ...preparedTagsList];
      dispatch.profile.SET_ADDED_PROFILE_TAGS_LIST_ON_POPOVER(updatedTagList);
    },

    changeColor(color: string, state: RootState): void {

      const isValidColorCode = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(color);

      if (!isValidColorCode) {
        showNotification({
          title: 'Error',
          message: 'Invalid color',
          color: 'error'
        });

        return;
      }

      if (state.profile?.profileTagsPopoverMode === 'CREATE' && !state.profile.selectedTagToEdit) {
        dispatch.profile.SET_INIT_TAG_COLOR(color);
        return;
      }

      const selectedTagsList = state.profile.addedProfileTagsListOnPopover;
      const selectedTagsToEdit = state.profile?.selectedTagToEdit;

      if (!(selectedTagsList && selectedTagsToEdit)) {
        return;
      }

      const index = selectedTagsList.findIndex(tag => tag.id === selectedTagsToEdit.id);
      selectedTagsToEdit.color = color;

      selectedTagsList[index] = selectedTagsToEdit;
      const selectedTagsListDuplicateArray = selectedTagsList.slice();
      dispatch.profile.SET_ADDED_PROFILE_TAGS_LIST_ON_POPOVER(selectedTagsListDuplicateArray);

      if (state.profile?.profileTagsPopoverMode !== 'EDIT') {
        dispatch.profile.SET_SELECTED_TAG_TO_EDIT(undefined);
      }
    },
    
    async bulkProfilesDeactivate(data: BulkUpdateProfileStatus, state: RootState): Promise<void> {
      const { profile: { isAllProfilesChecked } } = state;

      const query = isAllProfilesChecked && bulkProfileListQueryString(state?.userProfile?.profileListFilterData) ? `(${bulkProfileListQueryString(state?.userProfile?.profileListFilterData).substr(8)})` : '';
      uploadBulkUserProfilesStatus(data, query)
        .then(() => {
          dispatch.profile.SET_IS_ALL_PROFILES_CHECKED(false);
          dispatch.profile.SET_IS_PAGE_PROFILES_CHECKED(false);
          dispatch.profile.SET_SELECTED_PROFILES_IDS([]);
          dispatch.profile.SET_DESELECTED_PROFILES_IDS([]);
          dispatch.userProfile.getProfileList({});
        })
        .catch(handleError);
    },

    async assignAccessCardToProfile(cardId: AssignAccessCard, state: RootState): Promise<void> {
      const {
        profile: {
          general
        }
      } = state;

      if (!general?._id) {
        return;
      }

      await assignAccessCard(general?._id, cardId)
        .catch(handleError);
      dispatch.profile.SET_IS_NEW_ACCESS_CARD_ASSIGNED(true);
    },

    async unAssignAccessCardToProfile(
      cardId: AssignAccessCard,
      state: RootState,
      otherProfileId?: string
    ): Promise<void> {
      const {
        profile: {
          general
        }
      } = state;

      if (!general?._id) {
        return;
      }

      await unAssignAccessCard(otherProfileId ? otherProfileId : general?._id, cardId)
        .catch(handleError);
    },

    async getAccessCardListWithProfileAssigment(payload: {
      gridParams: GridParams,
    }, state: RootState, search = false): Promise<PaginatedResult<AccessCard>> {

      const {
        profile: {
          general
        }
      } = state;

      if (search) {
        dispatch.profile.SET_ACCESS_CARD_LIST_SEARCH_RESULT_LOADING(true);
      }

      const query = payload.gridParams.searchQuery;
      // eslint-disable-next-line no-param-reassign
      payload.gridParams.searchQuery = query
        ?
        `(manufactureId LIKE %27%25${query}%25%27 OR accessCardType LIKE %27%25${query}%25%27 OR friendlyName LIKE %27%25${query}%25%27 OR secondaryId LIKE %27%25${query}%25%27) AND (status='ACTIVE')`
        :
        'status=\'ACTIVE\'';
      if (!search && general?._id) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = query
          ?
          `assignedProfileTid LIKE %27%25${general?._id}%25%27 OR  manufactureId LIKE %27%25${query}%25%27 OR accessCardType LIKE %27%25${query}%25%27 OR friendlyName LIKE %27%25${query}%25%27 OR secondaryId LIKE %27%25${query}%25%27`
          :
          `assignedProfileTid LIKE %27%25${general?._id}%25%27`;
      }
      const getAccessCardListWithProfileAssigmentData = await getAccessCardListWithProfileAssigment(
        payload.gridParams.page,
        payload.gridParams.size,
        payload.gridParams.sort,
        payload.gridParams.searchQuery,
        search ? general?._id : ''
      );

      if (search) {
        dispatch.profile.SET_ACCESS_CARD_LIST_SEARCH_RESULT(getAccessCardListWithProfileAssigmentData.items);
        dispatch.profile.SET_ACCESS_CARD_LIST_SEARCH_RESULT_LOADING(false);
      } else {
        dispatch.profile.SET_ACCESS_CARD_LIST_ACTIVE_RESULT(getAccessCardListWithProfileAssigmentData.items);
      }


      return getAccessCardListWithProfileAssigmentData;
    },

    async createCompany(
      data: CreateCompany
    ): Promise<void> {

      if (!data) {
        return;
      }

      await createCompany(data);
    },

    async updateCompany(
      data: {
        id: string,
        data: CreateCompany
      }
    ): Promise<void> {

      if (!data.data || !data.id) {
        return;
      }

      await updateCompany(data.id, data.data);
    },

    async deleteCompany(
      id?: string
    ): Promise<void> {

      if (!id) {
        return;
      }
      dispatch.profile.SET_IS_COMPANY_LOADING(true);
      await deleteCompany(id).catch(handleError);
      dispatch.profile.SET_IS_COMPANY_LOADING(false);
    },

    isLoggedInProfileId(profileId: string | undefined, state: RootState): boolean {
      if (!profileId) {
        return false;
      }
      const { profile: { loggedUser } } = state;
      return profileId === loggedUser?._id;
    },

    async getIsAccessCardPrivilege(): Promise<void> {

      await getAccessCardPrivilege()
        .then((res) => dispatch.profile.SET_IS_ACCESS_CARD_PRIVILEGE(res.accessCardTabAccessible))
        .catch(handleError);
    },

    async getLocationsList(payload: {
      searchQuery?: string,
    }, _state: RootState): Promise<PaginatedResult<ILocation>> {

      if (payload?.searchQuery) {
        // eslint-disable-next-line no-param-reassign
        payload.searchQuery = `name LIKE %27%25${payload?.searchQuery}%25%27 OR code LIKE %27%25${payload?.searchQuery}%25%27 OR locationId LIKE %27%25${payload?.searchQuery}%25%27 OR alias LIKE %27%25${payload?.searchQuery}%25%27`;
      }

      const locationsList = await getLocationsList(
        payload.searchQuery
      );

      dispatch.profile.SET_LOCATIONS_LIST(locationsList);
      return locationsList;
    },

    async downloadBulkProfileUploadStatusCSV(): Promise<void> {
      try {
        const bulkDataId = localStorage.getItem("bulkProfilesUploadId");

        if (!bulkDataId) {
          return;
        }
        dispatch.profile.SET_IS_LOADING(true);
        const errorReportData = await getBulkProfileUploadStatusCSV(bulkDataId).catch(handleError);
        if (errorReportData) {
          const errorReportBlob = new Blob([errorReportData], { type: 'text/csv' });
          const url = URL.createObjectURL(errorReportBlob);
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.setAttribute('download', 'errorReport.csv');
          document.body.appendChild(downloadLink);
          downloadLink.click();
          URL.revokeObjectURL(url);
        }
      } catch {
        return undefined;
      } finally {
        dispatch.profile.SET_IS_LOADING(false);
      }
    },

    async getBulkProfileUploadStatusGrid(
      payload: {
          gridParams: GridParams;
        },
      _state: RootState
    ): Promise<UploadedProfileStatusGrid> {
      const { searchQuery, sort } = payload.gridParams;
      if(sort) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.sort = { ...sort,  colId: sort.colId === 'errorMessage' ? sort.colId : 'data.' + sort.colId };
      }
      if (searchQuery) {
        // eslint-disable-next-line no-param-reassign
        payload.gridParams.searchQuery = encodeURI(`data.First_Name LIKE '%${searchQuery}%' OR data.Last_Name LIKE '%${searchQuery}%' OR data.Work_Email
 LIKE '%${searchQuery}%' OR errorMsg LIKE '%${searchQuery}%'`);
      }

      try {
        const bulkDataId = localStorage.getItem("bulkProfilesUploadId");
        if (!bulkDataId) {
          return {
            currentPage: 0,
            items: [],
            totalPages: 0,
            totalItems: 0,
            filename: "",
            tenantId: "",
            bulkDataId: "",
            status: "",
            createdTime: 0
          };
        }
        dispatch.profile.SET_IS_LOADING(true);
        const data = await getBulkProfileUploadStatusGrid(
          bulkDataId,
          payload.gridParams.page,
          payload.gridParams.size,
          formatSortingParameters(payload.gridParams.sort),
          payload.gridParams.searchQuery
        );

        dispatch.profile.SET_UPLOADED_PROFILES_STATUS_GRID(data);

        return data;
      } catch {
        return {
          currentPage: 0,
          items: [],
          totalPages: 0,
          totalItems: 0,
          filename: "",
          tenantId: "",
          bulkDataId: "",
          status: "",
          createdTime: 0
        };
      } finally {
        dispatch.profile.SET_IS_LOADING(false);
      }
    }
    
  })
};



export const profile = createModel<RootModel>()(profileDataModel);
