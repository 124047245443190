import { createStyles } from "@mantine/core";

export const useBasemapLayerToggleControlStyles = createStyles((theme) => ({
  divider: {
  },
  itemIcon: {
    marginRight: 4
  },
  li: {
  },
  listItem: {
    minHeight: 48,
    width: '100%',
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    gap: 16
  },
  listItemText: {
    display: 'block',
    width: '100%',
    fontSize: theme.fontSizes.md,
    fontWeight: theme.other.fw.regular
  },
  titleUnderLine: {
    width: '56px',
    height: '2px',
    backgroundColor: theme.colors.blue[6]
  },
  textNeutral: {
    color: theme.colors.neutral[5]
  }
}));
